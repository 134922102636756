import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import NoteIcon from "@material-ui/icons/Note";
import SubjectIcon from "@material-ui/icons/Subject";

export const CACHED_DATA_TAG = "cachedData";

export const inlineStyles = [
  { label: "BOLD", icon: <FormatBoldIcon /> },
  { label: "ITALIC", icon: <FormatItalicIcon /> },
  { label: "UNDERLINE", icon: <FormatUnderlinedIcon /> },
];

export const alignmentStyles = [
  { value: "leftAlign", text: "Left" },
  { value: "centerAlign", text: "Center" },
  { value: "rightAlign", text: "Right" },
  { value: "justifyAlign", text: "Justify" },
];

export const blockTypes = [
  { value: "header-one", text: "H1" },
  { value: "header-two", text: "H2" },
  { value: "header-three", text: "H3" },
  { value: "unordered-list-item", icon: <FormatListBulletedIcon /> },
  { value: "ordered-list-item", icon: <FormatListNumberedIcon /> },
];

export const blockStyles = [
  { type: "blockQuote", style: "superFancyBlockquote" },
  { type: "leftAlign", style: "leftAlign" },
  { type: "centerAlign", style: "centerAlign" },
  { type: "rightAlign", style: "rightAlign" },
  { type: "justifyAlign", style: "justifyAlign" },
];

export const adminDocItems = [
  { type: "invoice", label: "invoice", icon: <InsertDriveFile /> },
  { type: "liability", label: "liability", icon: <InsertDriveFile /> },
  { type: "receipt", label: "receipt", icon: <InsertDriveFile /> },
  { type: "notice", label: "notice", icon: <InsertDriveFile /> },
  { type: "estimate", label: "estimate", icon: <NoteIcon /> },
  { type: "orderTicket", label: "orderTicket", icon: <NoteIcon /> },
  {
    type: "liability",
    label: "reminderEmailSignature",
    icon: <InsertDriveFile />,
  },
  {
    type: "generalConditions",
    label: "generalConditions",
    icon: <SubjectIcon />,
  },
  {
    type: "vatAttestation",
    label: "vatAttestation",
    icon: <SubjectIcon />,
  },
  { type: "companyContract", label: "contract", icon: <SubjectIcon /> },
  {
    type: "pvTemporarReception",
    label: "pvTemporarReception",
    icon: <SubjectIcon />,
  },
  {
    type: "pvDefinitiveReception",
    label: "pvDefinitiveReception",
    icon: <SubjectIcon />,
  },
  {
    type: "subcontractorContract",
    label: "subcontractorContract",
    icon: <SubjectIcon />,
  },
];

export const invoicingDocItems = [
  { type: "invoice", label: "invoice", icon: <InsertDriveFile /> },
  { type: "liability", label: "liability", icon: <InsertDriveFile /> },
  { type: "receipt", label: "receipt", icon: <InsertDriveFile /> },
  { type: "notice", label: "notice", icon: <InsertDriveFile /> },
  {
    type: "liability",
    label: "reminderEmailSignature",
    icon: <InsertDriveFile />,
  },
  {
    type: "generalConditions",
    label: "generalConditions",
    icon: <SubjectIcon />,
  },
  {
    type: "vatAttestation",
    label: "vatAttestation",
    icon: <SubjectIcon />,
  },
];

export const estimatesDocItems = [
  { type: "invoice", label: "invoice", icon: <InsertDriveFile /> },
  { type: "liability", label: "liability", icon: <InsertDriveFile /> },
  { type: "receipt", label: "receipt", icon: <InsertDriveFile /> },
  { type: "notice", label: "notice", icon: <InsertDriveFile /> },
  { type: "estimate", label: "estimate", icon: <NoteIcon /> },
  { type: "orderTicket", label: "orderTicket", icon: <NoteIcon /> },
  {
    type: "liability",
    label: "reminderEmailSignature",
    icon: <InsertDriveFile />,
  },
  {
    type: "generalConditions",
    label: "generalConditions",
    icon: <SubjectIcon />,
  },
  {
    type: "vatAttestation",
    label: "vatAttestation",
    icon: <SubjectIcon />,
  },
];

export const annualBudgetEstimate = [
  {
    month: 0,
    expenses: [],
  },
  {
    month: 1,
    expenses: [],
  },
  {
    month: 2,
    expenses: [],
  },
  {
    month: 3,
    expenses: [],
  },
  {
    month: 4,
    expenses: [],
  },
  {
    month: 5,
    expenses: [],
  },
  {
    month: 6,
    expenses: [],
  },
  {
    month: 7,
    expenses: [],
  },
  {
    month: 8,
    expenses: [],
  },
  {
    month: 9,
    expenses: [],
  },
  {
    month: 10,
    expenses: [],
  },
  {
    month: 11,
    expenses: [],
  },
];
