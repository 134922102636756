import React, { Fragment } from "react";
import Lang from "../../lang";
import {
  withStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import {
  ResolutionBreakPoints,
  LocalUrlEnum,
  get,
  DocTypes,
  getLiabilityTableRowColor,
  getLiabilityExpandedTableRowColor,
  isMobile,
  getLiabilityElapsedTime,
  post,
  StatusEnum,
  UrlEnum,
} from "../../Utils/Utils";
import moment from "moment";
import Config from "../../Utils/Config";
import Alert from "@material-ui/lab/Alert";
import { Invoice } from "../../Models/Models";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Euro } from "@material-ui/icons";
import { LiabilityLegend } from "./LiabilityLegend";
import DrawerLayout from "../DrawerLayout";
import TableComponent from "../../Components/TableComponent";
import { ExpandedTable } from "./ExpandedTable";
import { IconButton } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomOptions from "./CustomOptions";
import ReminderPreview from "./ReminderPreview";
import NotificationPreview from "./NotificationPreview";
import { Link } from "react-router-dom";
import theme from "../../Theme/Theme";

const lang: any = Lang.getInstance();
const docWidth = document.body.offsetWidth;
const isMobileView = isMobile();

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      display: "block",
      margin: "0 auto",
      marginTop: 50,
      outline: 0,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    options: {
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
    },
    totalsValue: {
      float: "right",
    },
    summary: {
      position: "fixed",
      bottom: 90,
      width: 250,
      "@media (max-height: 500px)": {
        display: "none",
      },
    },
    summaryTitle: {
      background: theme.palette.header?.main,
      padding: 5,
      color: "#fff",
      borderRadius: 2,
    },
    unpaidBtn: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.text.primary,
    },
    defaultColor: {
      backgroundColor: "#ffffff",
      color: theme.palette.text.primary,
    },
    warningColor: {
      backgroundColor: "#f8efc0",
      color: theme.palette.text.primary,
    },
    errorColor: {
      backgroundColor: "#e7c3c3",
      color: theme.palette.text.primary,
    },
    previewColor: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.text.primary,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 1,
      borderBottomRightRadius: 1,
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderLeft: "1px solid",
      borderColor: "#000000",
    },
    updateColor: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.text.primary,

      borderRadius: 1,
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderColor: "#000000",
    },
    notificationColor: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.text.primary,
      borderRadius: 1,
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderColor: "#000000",
    },
    defaulterColor: {
      backgroundColor: theme.palette.header?.main,
      color: theme.palette.text.primary,
      borderTopLeftRadius: 1,
      borderBottomLeftRadius: 1,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderRight: "1px solid",
      borderColor: "#000000",
    },
  });

type LiabilitysProps = {
  changeLoadingState: Function;
  showSmallMessage: Function;
  type: string;
  classes: any;
  history: any;
  match: any;
};

type LiabilitysState = {
  clients: Array<any>;
  totalNoVat: number;
  totalCredit: number;
  totalPaid: number;
  totalLeftToPay: number;
  errors: string;
  loading: boolean;
  openDeleteConfirm: boolean;
  unpaid: boolean;
  pagination: any;
  openColapsedRow: number;
  startDate: any;
  endDate: any;
  preview: boolean;
  previewNotification: boolean;
  previewModel: any;
  drawerOpen: boolean;
};

class LiabilityReportTable extends React.Component<
  LiabilitysProps,
  LiabilitysState
> {
  recurrent = false;
  crtDate = new Date();
  crtDateString = moment().format(Config.momentUSDateFormat);
  lang = Lang.getInstance();
  isMobile: boolean;
  deleteModel: Invoice | null;

  /**
   * constructor
   * @param {Object} props
   */
  constructor(props: LiabilitysProps) {
    super(props);
    this.isMobile = docWidth < ResolutionBreakPoints.md;
    this.state = {
      clients: [],
      totalNoVat: 0,
      totalCredit: 0,
      totalPaid: 0,
      totalLeftToPay: 0,
      errors: "",
      loading: true,
      openDeleteConfirm: false,
      unpaid: false,
      pagination: {
        total_count: 0,
        page: 1,
        perPage: 50,
      },
      openColapsedRow: 0,
      startDate: this.crtDate.getUTCFullYear() + "-01-01",
      endDate: this.crtDateString,
      preview: false,
      previewNotification: false,
      previewModel: {},
      drawerOpen: true,
    };

    this.deleteModel = null;
  }

  shouldComponentUpdate(
    nextProps: LiabilitysProps,
    nextState: LiabilitysState
  ) {
    if (nextProps.type !== this.props.type) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.fetchData(UrlEnum.liability);
  }

  columns = [
    {
      customRender: (model: any) => {
        return (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (this.state.openColapsedRow === model.id)
                this.setState({
                  openColapsedRow: 0,
                });
              else
                this.setState({
                  openColapsedRow: model.id,
                });
            }}
          >
            {this.state.openColapsedRow === model.id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        );
      },
    },
    {
      label: lang.get("active"),
      name: "selected",
      customRender: (model: any, currentObject: any, allClients: any) => {
        return (
          <Checkbox
            checked={model.selected ? true : false}
            style={{ color: theme.palette.header?.main }}
            onChange={() => {
              this.onSelectCheckbox(model.id, allClients);
            }}
          />
        );
      },
    },
    { label: lang.get("clientName"), name: "name" },
    { label: lang.get("vatNo"), name: "vatNo" },
    { label: lang.get("unpaid"), name: "totalToPay" },
  ];

  expandedColumns = [
    { label: lang.get("number"), name: "invoiceNo" },
    { label: lang.get("date"), name: "invoiceDate" },
    { label: lang.get("amount"), name: "totalWithVat" },
    { label: lang.get("paid"), name: "totalPaid" },
    { label: lang.get("reminder"), name: "reminderDate" },
    {
      label: lang.get("elapsedTime"),
      name: "elapsedTime",
      customRender: (model: any, currentObject: any, allModels: any) => {
        return (
          <InputLabel>{getLiabilityElapsedTime(model.invoiceDate)}</InputLabel>
        );
      },
    },
  ];

  /**
   * change crt url
   */
  changeUrl(
    startDate: string,
    endDate: string,
    search: string,
    page: number,
    perPage = 50
  ) {
    let displayUrlBase = LocalUrlEnum.invoices;
    switch (this.props.type) {
      case DocTypes.proforma:
        displayUrlBase = LocalUrlEnum.proformas;
        break;
      case DocTypes.creditNote:
        displayUrlBase = LocalUrlEnum.creditNotes;
        break;
    }
    const displayUrl = `${displayUrlBase}/${startDate}/${endDate}/${page}/${perPage}/${search}`;
    this.props.history.push(displayUrl);
  }

  /**
   * get the data from the server
   */
  async fetchData(url: string) {
    try {
      const data = await get(url);
      if (data.errors) {
        this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      } else {
     this.checkUnpaid(data);
        this.setState({
          clients: data.items ? data.items : data,
          pagination: {
            total_count: Number.isInteger(data.total_count)
              ? data.total_count
              : data.length,
          },
          loading: false,
        });
      }
    } catch (e) {
      this.setState({ errors: lang.get("error") });
    }
  }

  /**
   *
   * @param event
   */
  handleClickLiability = (event: any) => {
    //let displayUrlBase = this.props.history.location.pathname;
    //this.props.history.push(displayUrlBase);
  };

  /**
   *
   * @param model
   * @returns A class that will determin what style will have the row
   */
  getRowStyle(model: any) {
    let style = "defaultColor" as any;
    if (model.hasOwnProperty("invoices"))
      style = getLiabilityTableRowColor(model.invoices);
    if (model.hasOwnProperty("invoiceDate"))
      style = getLiabilityExpandedTableRowColor(model.invoiceDate);
    return this.props.classes[style];
  }

  /**
   *
   * @param selected
   */
  onSelectCheckbox(clientId: number, allClients: any) {
    let selectedModel = allClients.find((x: any) => x.id === clientId);
    let isSelected = !selectedModel.selected ? 1 : 0;
    post(`${UrlEnum.notice}/${clientId}/${isSelected}`, {}).then(response => {
      if (response.errors) {
        this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
        this.setState({ loading: false });
      } else {
        this.props.showSmallMessage(lang.get("saved"), StatusEnum.SUCCESS);
        selectedModel.selected = !selectedModel.selected;
        this.setState({ loading: false, clients: allClients });
      }
    });
  }

  /**
   *
   */
  closePreview() {
    this.setState({ preview: false, previewNotification: false });
  }

  /**
   * returns the details of an liability
   */
  hasLiability() {
    return (
      <ExpandedTable
        fullModel={this.state.previewModel}
        model={this.state.previewModel.invoices}
        openColapsedRow={this.state.openColapsedRow}
        expandedColumns={this.expandedColumns}
        rowClassCallback={this.getRowStyle.bind(this)}
      />
    );
  }

  /**
   *
   * @returns A Dialog with the preview for the reminder
   */
  renderPreview() {
    return (
      <ReminderPreview
        renderPreview={this.state.preview}
        model={this.state.previewModel}
        closePreview={this.closePreview.bind(this)}
        hasLiability={this.hasLiability.bind(this)}
      />
    );
  }

  /**
   *
   * @returns A Dialog with th preview for notification
   */
  renderPreviewNotification() {
    return (
      <NotificationPreview
        renderPreview={this.state.previewNotification}
        model={this.state.previewModel}
        closePreview={this.closePreview.bind(this)}
        hasLiability={this.hasLiability.bind(this)}
      />
    );
  }

  /**
   * Sets the visibility of the preview and it's model
   * @param model
   */
  handleEditClickPreview(model: any) {
    this.setState({ preview: true, previewModel: model });
  }
  /**
   *
   * @param model
   */
  handleEditClickNotificationPreview(model: any) {
    this.setState({ previewNotification: true, previewModel: model });
  }

  /**
   *
   * @param model
   */
  handleClickUpdateReminderDate(model: any) {
    post(UrlEnum.liability, {
      clientId: model.id,
      newDate: new Date(),
    }).then(response => {
      if (response.errors) {
        this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
        this.setState({ loading: false });
      } else {
        this.props.showSmallMessage(lang.get("saved"), StatusEnum.SUCCESS);
        let curentDate = moment(response).format("DD-MM-yyyy");
        let clientIndex = this.state.clients?.findIndex(
          client => client.id === model.id
        );
        model.invoices?.forEach((invoice: any, index: number) => {
          invoice.reminderDate = curentDate;
        });
        let newModel = this.state.clients.slice();
        newModel[clientIndex] = model;
        this.setState({ clients: newModel, loading: false });
      }
    });
  }

  /**
   *
   * @param model
   */
  handleClickSetBadPayer(model: any) {
    post(UrlEnum.setBadPayee + model.id + "/" + 1, {}).then(response => {
      if (response.errors) {
        this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
        this.setState({ loading: false });
      } else {
        this.props.showSmallMessage(lang.get("saved"), StatusEnum.SUCCESS);
        let clientIndex = this.state.clients?.findIndex(
          client => client.id === model.id
        );

        let newModel = this.state.clients.slice();
        newModel.splice(clientIndex, 1);
        this.setState({ clients: newModel, loading: false });
      }
    });
  }

  /**
   *
   */
  checkUnpaid(items: any) {
    // let copy: Array<Object> = [];
    items.forEach((element: any) => {
      let sum = 0;
      let total=0;
      // console.log(element.totalToPay);
      for (let i = 0; i < element.invoices.length; i++) {
        sum += element.invoices[i].totalPaid;
      }
       total= element.totalToPay - sum;
      // console.log( element.totalToPay);
       element.totalToPay=total.toFixed(2);
      //  console.log( element.totalToPay);
      // copy.push(element);
    });
  }
  /**
   * render method
   */
  render() {
    const classes = this.props.classes;
    if (!this.state.loading) {
      if (this.state.errors) {
        return <Alert severity="error">{this.state.errors}</Alert>;
      }
    }

    return (
      <div style={{ marginBottom: 40 }}>
        <DrawerLayout
          open={this.state.drawerOpen}
          drawerWidth={!isMobileView ? 300 : "100%"}
          handleDrawerClose={() =>
            this.setState({ drawerOpen: !this.state.drawerOpen })
          }
          drawerChildren={
            <Fragment>
              <List>
                <Link to="/invoices/report" className={classes.fullWidth}>
                  <ListItem button>
                    <ListItemIcon>
                      <AssessmentIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary={this.lang.get("report")} />
                  </ListItem>
                </Link>

                <Link to="/invoices/liability" className={classes.fullWidth}>
                  <ListItem
                    button
                    id={this.lang.get("liability")}
                    onClick={(event: any) => this.handleClickLiability(event)}
                  >
                    <ListItemIcon>
                      <Euro />{" "}
                    </ListItemIcon>
                    <ListItemText primary={this.lang.get("liability")} />
                  </ListItem>
                </Link>
              </List>
            </Fragment>
          }
        >
          {" "}
          <LiabilityLegend
            defaultColor={this.props.classes["defaultColor"]}
            warningColor={this.props.classes["warningColor"]}
            errorColor={this.props.classes["errorColor"]}
          />
          <TableComponent
            models={this.state.clients}
            pagination={false}
            columns={this.columns as any}
            searchTextPlaceHolder={lang.get("client")}
            url={"liabilities"}
            rowClassCallback={this.getRowStyle.bind(this)}
            options={{
              customRender: (model: any, context: any, fullModel: any) => {
                return (
                  <CustomOptions
                    model={model}
                    style={classes.options}
                    previewColor={this.props.classes["previewColor"]}
                    updateColor={this.props.classes["updateColor"]}
                    notificationColor={this.props.classes["notificationColor"]}
                    defaultColor={this.props.classes["defaulterColor"]}
                    handleEditClickPreview={this.handleEditClickPreview.bind(
                      this
                    )}
                    handleClickUpdateReminderDate={this.handleClickUpdateReminderDate.bind(
                      this
                    )}
                    handleClickSetBadPayer={this.handleClickSetBadPayer.bind(
                      this
                    )}
                    handleEditClickNotificationPreview={this.handleEditClickNotificationPreview.bind(
                      this
                    )}
                  />
                );
              },
            }}
            expadableRow={(expandedModel: any) => {
              return (
                <ExpandedTable
                  fullModel={expandedModel}
                  model={expandedModel.invoices}
                  openColapsedRow={this.state.openColapsedRow}
                  expandedColumns={this.expandedColumns}
                  rowClassCallback={this.getRowStyle.bind(this)}
                />
              );
            }}
          />
          {this.state.preview ? this.renderPreview() : null}
          {this.state.previewNotification
            ? this.renderPreviewNotification()
            : null}
        </DrawerLayout>
      </div>
    );
  }
}

export default withStyles(styles)(LiabilityReportTable);
