import React, { Fragment } from "react";
import {
  EditorState,
  Editor,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  AtomicBlockUtils,
  Modifier,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "../CSS/TextEditor.css";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PropTypes from "prop-types";
import Lang from "../lang";
import TagsTextEditor from "../DocumentTemplates/TagsTextEditor";
import {
  alignmentStyles,
  blockStyles,
  blockTypes,
  inlineStyles,
} from "../Utils/Constants";

const styles = () => ({
  editorWrapper: {
    border: "1px solid #ccc",
    padding: 5,
    height: 150,
    overflow: "scroll",
  },
  toolbar: {
    boxShadow: "0 0 3px #555",
    minHeight: 48,
  },
  blockTypes: {
    position: "relative",
    top: -7,
  },
  lineButtonStyle: {
    padding: "10px 0px 10px 0px",
    marginTop: -14,
    minWidth: 50,
  },
});

const lang = Lang.getInstance();

const ENTITY_TYPE = {
  HORIZONTAL_RULE: "hr",
};

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: props.initialState
        ? EditorState.createWithContent(convertFromRaw(props.initialState))
        : EditorState.createEmpty(),
      formats: [],
      blockTypes: [],
      initialState: props.initialState,
      docType: props.docType,
    };
    this.onChange = (editorState) => {
      this.setState({ editorState });
    };
    this.classes = props.classes;
  }

  /**
   *
   * @param {*} nextProps
   * @param {*} prevState
   * @returns
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialState !== prevState.initialState) {
      return {
        editorState: nextProps.initialState
          ? EditorState.createWithContent(
              convertFromRaw(nextProps.initialState)
            )
          : EditorState.createEmpty(),
        formats: [],
        blockTypes: [],
        initialState: nextProps.initialState,
      };
    }
    return null;
  }

  /**
   *
   * @param {*} command
   * @param {*} editorState
   * @returns
   */
  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }

  /**
   *
   * @param {*} event
   * @param {*} newFormats
   */
  handleFormat(event, newFormats) {
    const currentEditorState = this.state.editorState;
    newFormats.forEach((format) => {
      const isActive = currentEditorState.getCurrentInlineStyle().has(format);
      const newState = isActive
        ? RichUtils.toggleInlineStyle(currentEditorState, format)
        : RichUtils.toggleInlineStyle(currentEditorState, format);
      this.setState({ editorState: newState });
    });
  }

  /**
   *
   * @param {*} event
   * @param {*} newBlockTypes
   */
  handleBlockType(event, newBlockTypes) {
    const currentEditorState = this.state.editorState;
    newBlockTypes.forEach((blockType) => {
      const isActive =
        RichUtils.getCurrentBlockType(currentEditorState) === blockType;
      const newState = isActive
        ? RichUtils.toggleBlockType(currentEditorState, blockType)
        : RichUtils.toggleBlockType(currentEditorState, blockType);
      this.setState({ editorState: newState });
    });
  }

  /**
   *
   * @param {*} event
   */
  addHorizontalRule(event) {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      ENTITY_TYPE.HORIZONTAL_RULE,
      "IMMUTABLE",
      {}
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState({
      editorState: AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        " "
      ),
    });
  }

  /**
   *
   * @param {*} contentBlock
   * @returns
   */
  blockRenderer(contentBlock) {
    const type = contentBlock.getType();
    if (type === "atomic") {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);
      const entity = contentState.getEntity(entityKey);
      if (entity && entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
        return {
          component: DividerComponent,
          editable: false,
        };
      }
    }
  }

  /**
   *
   * @returns
   */
  getContent() {
    return convertToRaw(this.state.editorState.getCurrentContent());
  }

  /**
   *
   * @param {*} tags
   * @returns
   */
  addTags(tags) {
    if (!tags) return;
    const text = " ${" + tags + "} ";

    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();

    let newContentState = contentState.createEntity(
      "unstyled",
      "IMMUTABLE",
      text
    );

    const entityKey = contentState.getLastCreatedEntityKey();
    const selectionState = this.state.editorState.getSelection();
    newContentState = Modifier.insertText(
      newContentState,
      selectionState,
      text,
      "",
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    this.setState({ editorState: newEditorState });
  }

  /**
   *
   * @param {*} contentBlock
   * @returns
   */
  myBlockStyleFn(contentBlock) {
    const type = contentBlock.getType();
    const blockStyle = blockStyles.find((block) => block.type === type);
    return blockStyle ? blockStyle.style : null;
  }

  /**
   *
   * @returns
   */
  render() {
    return (
      <Fragment>
        <div>
          <ToggleButtonGroup
            value={this.state.formats}
            onChange={this.handleFormat.bind(this)}
          >
            {inlineStyles &&
              inlineStyles.map((style) => () => {
                return (
                  <ToggleButton key={style.label} value={style.label}>
                    {style.icon}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
          &nbsp;&nbsp;
          <ToggleButtonGroup
            className={this.classes.blockTypes}
            value={this.state.blockTypes}
            onChange={this.handleBlockType.bind(this)}
          >
            {blockTypes.map((blockType) => (
              <ToggleButton key={blockType.value} value={blockType.value}>
                {blockType.icon ? blockType.icon : blockType.text}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          &nbsp;&nbsp;
          <ToggleButtonGroup
            className={this.classes.blockTypes}
            value={this.state.blockTypes}
            onChange={this.handleBlockType.bind(this)}
          >
            {alignmentStyles.map((alignment) => (
              <ToggleButton key={alignment.value} value={alignment.value}>
                {alignment.text}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          &nbsp;&nbsp;
          <Tooltip title={lang.get("divider")}>
            <Button
              className={this.classes.lineButtonStyle}
              name="hr"
              variant="outlined"
              onClick={this.addHorizontalRule.bind(this)}
            >
              ---
            </Button>
          </Tooltip>
          <TagsTextEditor
            docType={this.state.docType}
            addTags={this.addTags.bind(this)}
          />
        </div>
        <div
          className={this.classes.editorWrapper}
          style={{
            height: this.props.height || "150px",
            fontSize: this.props.fontSize || "",
          }}
        >
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand.bind(this)}
            blockRendererFn={this.blockRenderer.bind(this)}
            blockStyleFn={this.myBlockStyleFn.bind(this)}
          />
        </div>
      </Fragment>
    );
  }
}

const DividerComponent = (props) => {
  return <hr />;
};

TextEditor.propTypes = {
  initialState: PropTypes.object,
  height: PropTypes.number,
  fontSize: PropTypes.string | PropTypes.number,
};

export default withStyles(styles, { withTheme: true })(TextEditor);
