import CachedDataSingleton from "../cachedDataSingleton";
import React, { ChangeEvent, Component, Fragment } from "react";
import {
  withStyles,
  InputLabel,
  Select,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete, {
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Lang from "../lang";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import Config from "../Utils/Config";
import moment from "moment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  UrlEnum,
  post,
  handleInputChange,
  isMobile,
  httpDelete,
  download,
  CostTypeEnum,
  get,
  StatusEnum,
} from "../Utils/Utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { Client, Cost, NomDocType, Project, Supplier } from "../Models/Models";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DropzoneArea } from "material-ui-dropzone";
import ConfirmDialog from "../Components/ConfirmDialog";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import TakePhotoDialog from "../Components/TakePhotoDialog";
import theme from "../Theme/Theme";
import { Alert } from "@material-ui/lab";
let lang = Lang.getInstance();

type EditCostsProps = {
  model: Cost;
  onClose: Function;
  classes: any;
  submitCallback: (model: Cost) => void;
  open: boolean;
  showSmallMessage: Function;
  hideClient?: true | false;
  isCostInProject?: true | false;
};

type EditCostsState = {
  checkPaid: boolean;
  model: Cost;
  selectedClient: Client | null;
  selectedSupplier: Supplier | null;
  selectedProject: Project | null;
  projects: Project[];
  loading: boolean;
  showNewButton: boolean;
  supplierToDelete: Supplier | null;
  takePhoto: true | false;
  photoFile: File | null;
  errors: string;
  success: string;
};

class EditCosts extends Component<EditCostsProps, EditCostsState> {
  cachedData: any;
  vatTypes: any;
  clients: any;
  suppliers: Supplier[];
  company: any;
  docTypes: NomDocType[];
  formRef: any;
  newSupplierName: string;

  constructor(props: EditCostsProps) {
    super(props);

    this.cachedData = CachedDataSingleton.getInstance();
    this.docTypes = this.cachedData.get("docTypes");
    this.vatTypes = this.cachedData.get("vatTypes");
    this.clients = this.cachedData.get("clients");
    this.suppliers = this.cachedData.get("suppliers");
    this.company = this.cachedData.get("company");

    this.state = {
      checkPaid: true,
      model: props.model,
      selectedClient:
        this.clients.find((el: Client) => el.id === props.model.fk_ClientId) ||
        null,
      selectedSupplier:
        this.suppliers.find(
          (el: Supplier) => el.id === props.model.fk_SupplierId
        ) || null,
      selectedProject:
        this?.state?.projects?.find(
          (el: Project) => el.id === props.model.fk_ProjectId
        ) || null,
      projects: [],
      loading: false,
      showNewButton: false,
      supplierToDelete: null,
      takePhoto: false,
      photoFile: null,
      errors: "",
      success: "",
    };

    this.formRef = React.createRef();
    this.newSupplierName = "";
  }

  componentDidMount() {
    let newCost = Object.assign({}, this.state.model);
    if (!newCost.vatRate) newCost.vatRate = 21;
    this.setState({ model: newCost });
    this.getProductsBasedOnClient(this.props.model.fk_ClientId);
  }

  /**
   * change paid
   */
  handleChangePaid() {
    let newCost = Object.assign({}, this.state.model);
    newCost.paid = newCost.paid > 0 ? 0 : 1;
    if (newCost.paid) {
      newCost.paymentDeadline = "";
    } else {
      newCost.paymentDeadline = moment(
        newCost.docDate,
        Config.momentUSDateFormat
      )
        .add(Config.costInvoiceDefaultPaymentDelay, "days")
        .format(Config.momentUSDateFormat);
    }
    this.setState({ model: newCost });
  }

  /**
   *
   * @param e change without vat
   */
  handleChangeWithoutVat(e: React.ChangeEvent<HTMLInputElement>) {
    let newCost = Object.assign({}, this.state.model);
    newCost.vatRate = e.currentTarget.checked ? 0 : Config.defaultVatRate;
    this.setState({ model: newCost });
  }

  /**
   * change is monthly
   */
  handleChangeIsMonthly() {
    let newCost = Object.assign({}, this.state.model);
    newCost.isMonthly = newCost.isMonthly > 0 ? 0 : 1;
    this.setState({ model: newCost });
  }

  /**
   * change docDate
   * @param md
   */
  onChangDate(md: MaterialUiPickersDate) {
    if (!md) return;
    if (md.isAfter(moment())) return false;
    let newCost = Object.assign({}, this.state.model);
    newCost.docDate = md.format(Config.momentUSDateFormat);
    this.setState({ model: newCost });
  }

  /**
   * change payment deadline
   * @param md
   */
  onChangePaymentDeadline(md: MaterialUiPickersDate) {
    if (!md) return;
    let newCost = Object.assign({}, this.state.model);
    newCost.paymentDeadline = md.format(Config.momentUSDateFormat);
    this.setState({ model: newCost });
    if (md.isAfter(moment())) return false;
  }

  /**
   *
   * @param event
   * @param value
   */
  changeDocType(event: any, value: any) {
    if (value === null) return;
    let newCost = Object.assign({}, this.state.model);
    newCost.fk_DocTypeId = event.target.value;
    this.setState({ model: newCost });
  }

  /**
   * change is cost type
   */
  handleChangeType(event: any) {
    let newCost = Object.assign({}, this.state.model);
    newCost.type = event.target.value;
    let sc = this.state.selectedClient;
    let ss = this.state.selectedSupplier;
    if (newCost.type === CostTypeEnum.ADMINISTRATIVE) {
      newCost.fk_ClientId = 0;
      sc = null;
    }
    if (newCost.type === CostTypeEnum.WORKFORCE) {
      newCost.fk_SupplierId = 0;
      ss = null;
    }
    this.setState({ model: newCost, selectedClient: sc, selectedSupplier: ss });
  }

  /**
   * add new supplier
   */
  newSupplierClick() {
    if (!this.newSupplierName) {
      this.setState({ errors: lang.get("insertSupplier") });
      return;
    }
    const data = {
      name: this.newSupplierName,
      id: 0,
    };
    this.setState({ errors: "" });
    post(UrlEnum.suppliers + "/0", data).then((response: any) => {
      if (!response.errors) {
        this.suppliers.push(response);
        const newCost = { ...this.state.model };
        newCost.fk_SupplierId = response.id;
        this.setState({
          model: newCost,
          showNewButton: false,
          selectedSupplier: response,
        });
      }
    });
  }

  /**
   * delete selected supplier
   */
  async onConfirmSupplierResult(confirm: boolean) {
    if (!confirm || !this.state.supplierToDelete) {
      this.setState({ supplierToDelete: null });
      return;
    }
    const result: any = await httpDelete(
      UrlEnum.suppliers + "/" + this.state.supplierToDelete.id
    );

    if (result.errors) return;
    const index = this.suppliers.findIndex(
      (el) => el.id === this.state.supplierToDelete?.id
    );
    if (index < 0) return;
    this.suppliers.splice(index, 1);

    // delete current active supplier
    if (this.state.model.fk_SupplierId === this.state.supplierToDelete.id) {
      if (this.suppliers.length > 0) {
        let newCost = { ...this.state.model };
        newCost.fk_SupplierId = this.suppliers[0].id;
        this.setState({
          model: newCost,
          supplierToDelete: null,
          selectedSupplier: this.suppliers[0],
        });
      }
    }

    this.setState({ supplierToDelete: null });
  }

  /**
   * remove the attached file
   */
  async onDeleteFile() {
    const response = await httpDelete(
      UrlEnum.folderCosts + "/deleteFile/" + this.state.model.id
    );
    if (!response.errors) {
      let newCost = { ...this.state.model };
      newCost.filePath = "";
      this.setState({ model: newCost });
    }
  }

  /**
   * filter suppliers override
   * @param options
   * @param state
   */
  filterOptions(options: Supplier[], state: FilterOptionsState<Supplier>) {
    const filterOption = options.filter(
      (el) => el.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0
    );

    if (
      filterOption.length === 0 &&
      state.inputValue.length > 0 &&
      this.state.showNewButton === false
    ) {
      this.setState({ showNewButton: true });
    }
    return filterOption;
  }

  handleInputChange(e: React.ChangeEvent<{}>) {
    const newCost = handleInputChange(e, this.state.model);
    this.setState({ model: newCost });
  }

  onFileDrop(file: any) {
    let newCost = Object.assign({}, this.state.model);
    newCost.filePath = file instanceof Array ? file[0] : file;
    this.setState({ model: newCost });

    //send file to server even if cost is not saved ...
  }

  /**
   *
   * @param e send data to server
   */
  async submit(e: any) {
    // let formRef = this.formRef.current;
    // formRef.isFormValid(false);

    this.setState({ errors: "", success: "" });
    const formData = new FormData();
    const resetAfterSave = this.state.model.id === 0;

    for (const key in this.state.model) {
      if (key === "docDate") {
        if (this.state.model[key] === "Invalid date") {
          // this.props.showSmallMessage(lang.get("invalidDate"), StatusEnum.ERROR);
          return false;
        }

        formData.append(
          key,
          moment(this.state.model[key], Config.momentUSDateFormat).format(
            Config.momentUSDateFormat
          )
        );
      } else {
        formData.append(key, this.state.model[key] || "");
      }
    }
    formData.append("file", this.state.model.filePath);
    this.setState({ loading: true });
    // sent data and wait for response
    const response = await post(UrlEnum.folderCosts, formData);
    if (response.errors) {
      if (response.errors === "clientDoesNotExist") {
        this.setState({ errors: lang.get("selectClient") });
        this.setState({ loading: false });
        return;
      } else {
        this.setState({ errors: response.errors });
        this.setState({ loading: false });
        return;
      }
    }

    // update all new fields with data from server
    let newCost = { ...this.state.model };
    for (const key in newCost) {
      if (
        response[key] &&
        newCost[key] !== response[key] &&
        key !== "docDate"
      ) {
        if (typeof newCost[key] === "number") {
          newCost[key] = parseFloat(response[key]);
        } else {
          newCost[key] = response[key];
        }
      }
    }
    newCost.amount = parseFloat(response.amount);

    this.setState({ model: newCost, loading: false }, () => {
      this.props.submitCallback(this.state.model);
      if (resetAfterSave) {
        let nc = new Cost();
        nc.fk_FolderId = this.state.model.fk_FolderId;
        this.setState({
          model: nc,
          selectedClient: null,
          selectedSupplier: null,
        });
      }
    });
    this.setState({ success: lang.get("success") });
  }

  /**
   * render cost type options
   */
  renderCostTypeOptions() {
    const cte: any = CostTypeEnum;
    let options = [];
    for (const value in cte) {
      options.push(
        <option key={cte[value]} value={cte[value]}>
          {lang.get(cte[value])}
        </option>
      );
    }
    return options;
  }

  /**
   *
   * @param clientId
   * @param otherState
   */
  async getProductsBasedOnClient(
    clientId: number,
    callback?: (projects: any) => void
  ) {
    get(
      `${UrlEnum.projects}/get/for/costs/${this.company.id}/${clientId}`
    ).then((response) => {
      if (response.errors) {
        this.props.showSmallMessage(response.errors, StatusEnum.ERROR);
      } else {
        if (callback) {
          callback(response);
        } else {
          const folderID = this.state.model.fk_FolderId
            ? this.state.model.fk_FolderId
            : this.state.model.folderId || null;
          //get selected project from the list
          const selectedProject = response.find(
            (el: Project) => el.id === folderID
          );
          this.setState({
            projects: response, // Update projects in the state
            selectedProject: selectedProject, // Reset selected project
          });
        }
      }
    });
  }

  /**
   *
   * @param event
   * @param value
   * @returns
   */
  handleClientChange = async (event: ChangeEvent<{}>, value: Client | null) => {
    if (value === null) return;
    const newModel = { ...this.state.model };
    newModel.fk_ClientId = value.id;
    newModel.clientName = value.name;
    await this.getProductsBasedOnClient(value.id, (projects: any) => {
      this.setState({
        model: newModel,
        selectedClient: value,
        projects: projects, // Update projects in the state
        selectedProject: null, // Reset selected project
      });
    });
  };

  /**
   * render
   */
  render() {
    const classes = this.props.classes;
    const docDate = moment(
      this.state.model.docDate,
      Config.momentUSDateFormat
    ).toDate();
    let fp = "";
    if (
      this.state.model.filePath &&
      typeof this.state.model.filePath == "string"
    ) {
      fp = this.state.model.filePath.substring(
        this.state.model.filePath.lastIndexOf("/") + 1
      );
    }

    if (this.state.photoFile) {
      fp = this.state.photoFile.name;
    }

    return (
      <Dialog
        fullScreen={isMobile()}
        onClose={() => this.props.onClose()}
        open={this.props.open}
      >
        <DialogTitle>
          {lang.get("cost")}{" "}
          <em>
            ({this.state.model.id === 0 ? lang.get("new") : lang.get("edit")})
          </em>
          <IconButton
            style={{ position: "absolute", right: 0, top: 0 }}
            color="inherit"
            id="addNew"
            onClick={() => this.props.onClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <ValidatorForm
              ref="form"
              instantValidate={true}
              onSubmit={this.submit.bind(this)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  {/** cost type */}
                  <FormControl
                    className={classes.formControl}
                    required={true}
                    fullWidth={true}
                  >
                    <InputLabel>{lang.get("type")}</InputLabel>
                    <Select
                      native
                      id="type"
                      value={this.state.model.type}
                      onChange={this.handleChangeType.bind(this)}
                    >
                      {this.renderCostTypeOptions()}
                    </Select>
                  </FormControl>

                  <TextValidator
                    fullWidth
                    id="description"
                    name="description"
                    label={lang.get("description")}
                    onChange={this.handleInputChange.bind(this)}
                    validators={["required"]}
                    value={this.state.model.description}
                    errorMessages={lang.get("fieldRequired")}
                  />
                  {!this.props.hideClient &&
                  this.state.model.type !== CostTypeEnum.ADMINISTRATIVE ? (
                    <Fragment>
                      <Autocomplete
                        className={`${classes.formControl}`}
                        fullWidth={true}
                        id="clientName"
                        options={this.clients}
                        value={this.state.selectedClient}
                        getOptionLabel={(client: Client) => client.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={lang.get("clientName")}
                          />
                        )}
                        onChange={(
                          event: ChangeEvent<{}>,
                          value: Client | null
                        ) => {
                          this.handleClientChange(event, value);
                        }}
                      />
                    </Fragment>
                  ) : null}
                  {this.state.selectedClient &&
                  this?.state?.projects?.length > 0 &&
                  !this.props.isCostInProject ? (
                    <Fragment>
                      <Autocomplete
                        className={`${classes.formControl}`}
                        fullWidth={true}
                        id="projectName"
                        options={this.state.projects}
                        value={this.state.selectedProject}
                        getOptionLabel={(project: Project) => project.title}
                        renderInput={(params) => (
                          <TextField {...params} label={lang.get("folder")} />
                        )}
                        onChange={(
                          event: ChangeEvent<{}>,
                          value: Project | null
                        ) => {
                          if (value === null) return;
                          const newModel = { ...this.state.model };
                          newModel.fk_FolderId = value.id;
                          this.setState({
                            model: newModel,
                            selectedProject: value,
                          });
                        }}
                      />
                    </Fragment>
                  ) : null}
                  {this.state.model.type !== CostTypeEnum.WORKFORCE ? (
                    <div className={`${classes.editButton}`}>
                      <Autocomplete
                        fullWidth={true}
                        id="supplierName"
                        options={this.suppliers}
                        value={this.state.selectedSupplier}
                        getOptionLabel={(supplier: Supplier) => supplier.name}
                        filterOptions={this.filterOptions.bind(this)}
                        renderInput={(params) => (
                          <TextField {...params} label={lang.get("supplier")} />
                        )}
                        onInputChange={(
                          event: ChangeEvent<{}>,
                          value: string
                        ) => {
                          if (event && event.type === "change") {
                            this.newSupplierName = value;
                          }
                        }}
                        onChange={(event: any, value: any) => {
                          if (value === null) return;
                          const newModel = { ...this.state.model };
                          newModel.fk_SupplierId = value.id;
                          newModel.supplierName = value.name;

                          this.setState({
                            model: newModel,
                            showNewButton: false,
                            selectedSupplier: value,
                            errors: "",
                          });
                        }}
                        renderOption={(
                          option: Supplier,
                          state: AutocompleteRenderOptionState
                        ) => (
                          <div style={{ width: "100%" }}>
                            <span>{option.name}</span>
                            <IconButton
                              style={{ float: "right" }}
                              className={classes.iconButton}
                              aria-label="add"
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({ supplierToDelete: option });
                                return false;
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        )}
                      />

                      <IconButton
                        className={classes.iconButton}
                        aria-label="add"
                        onClick={this.newSupplierClick.bind(this)}
                      >
                        <AddCircleIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ) : null}

                  <FormControl fullWidth>
                    <InputLabel>{lang.get("docType")}</InputLabel>
                    <Select
                      id="docTypeId"
                      value={this.state.model.fk_DocTypeId || 3}
                      onChange={this.changeDocType.bind(this)}
                    >
                      {this.docTypes.map((dt: NomDocType) => {
                        if (dt.id !== 3 && dt.id !== 5 && dt.id !== 25)
                          return null;
                        return (
                          <MenuItem key={dt.id} value={dt.id}>
                            {lang.get(dt.name)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <TextValidator
                    fullWidth
                    className={classes.formControl}
                    type="text"
                    id="docNumber"
                    name="docNumber"
                    label={lang.get("number")}
                    onChange={this.handleInputChange.bind(this)}
                    validators={["required"]}
                    value={this.state.model.docNumber}
                    errorMessages={lang.get("fieldRequired")}
                  />
                </Grid>

                <Grid className={classes.paper} item xs={12} sm={12} md={6}>
                  <br />
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    name="docDate"
                    label={lang.get("date") + "(" + lang.get("doc") + ")"}
                    value={docDate}
                    format={Config.momentEUDateFormat}
                    onChange={this.onChangDate.bind(this)}
                  />
                  <TextValidator
                    fullWidth
                    className={classes.formControl}
                    type="number"
                    id="amount"
                    name="amount"
                    label={lang.get("amount")}
                    onChange={this.handleInputChange.bind(this)}
                    validators={["required"]}
                    value={this.state.model.amount}
                    errorMessages={lang.get("fieldRequired")}
                  />

                  <FormControl
                    className={classes.formControl}
                    required={true}
                    fullWidth={true}
                  >
                    <InputLabel>{lang.get("vatRate")}</InputLabel>
                    <Select
                      disabled={true}
                      native
                      id="vatRate"
                      value={this.state.model.vatRate}
                      onChange={this.handleInputChange.bind(this)}
                    >
                      {this.vatTypes.map((vt: any, index: number) => (
                        <option key={index} value={vt.value}>
                          {vt.description}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.text}
                        style={{ color: theme.palette.header?.main }}
                        checked={this.state.model.isMonthly > 0}
                        onChange={this.handleChangeIsMonthly.bind(this)}
                      />
                    }
                    label={lang.get("monthly")}
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    value="withoutVat"
                    control={
                      <Checkbox
                        className={classes.text}
                        style={{ color: theme.palette.header?.main }}
                        checked={this.state.model.vatRate === 0}
                        onChange={this.handleChangeWithoutVat.bind(this)}
                      />
                    }
                    label={lang.get("withoutVat")}
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    value="paid"
                    control={
                      <Checkbox
                        className={classes.text}
                        style={{ color: theme.palette.header?.main }}
                        checked={this.state.model.paid > 0}
                        onChange={this.handleChangePaid.bind(this)}
                      />
                    }
                    label={lang.get("paid")}
                    labelPlacement="start"
                  />

                  {this.state.model.paid ? (
                    ""
                  ) : (
                    <DatePicker
                      className={classes.formControl}
                      fullWidth
                      disableToolbar
                      variant="inline"
                      name="paymentDeadline"
                      label={lang.get("paymentDeadline")}
                      value={this.state.model.paymentDeadline}
                      format={Config.momentEUDateFormat}
                      onChange={this.onChangePaymentDeadline.bind(this)}
                    />
                  )}
                </Grid>
              </Grid>
              <br />
              <InputLabel>{lang.get("attachedFiles")}</InputLabel>
              {fp ? (
                <Fragment>
                  {typeof this.state.model.filePath === "string" ? (
                    <a
                      href={this.state.model.filePath}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        download(this.state.model.filePath, fp);
                        return false;
                      }}
                    >
                      {fp}
                    </a>
                  ) : (
                    fp
                  )}
                  <IconButton onClick={this.onDeleteFile.bind(this)}>
                    <DeleteIcon />
                  </IconButton>
                </Fragment>
              ) : (
                <DropzoneArea
                  onChange={this.onFileDrop.bind(this)}
                  filesLimit={1}
                  acceptedFiles={["image/*", ".pdf"]}
                  dropzoneText={lang.get("dragFiles")}
                ></DropzoneArea>
              )}
              <p></p>

              {/** take a picture button */}
              {!fp || typeof this.state.model.filePath !== "string" ? (
                <Button
                  variant="contained"
                  onClick={() => this.setState({ takePhoto: true })}
                >
                  <CameraAltIcon />
                  {lang.get("takeAPhoto")}
                </Button>
              ) : null}

              {this.state.errors ? (
                <Alert severity="error" style={{ margin: "10px 0px" }}>
                  {this.state.errors}
                </Alert>
              ) : (
                ""
              )}
              {this.state.success ? (
                <Alert severity="success" style={{ margin: "10px 0px" }}>
                  {this.state.success}
                </Alert>
              ) : (
                ""
              )}
              <Button
                style={{
                  float: "right",
                  backgroundColor: theme.palette.header?.main,
                  color: theme.palette.textColorSecondary?.main,
                }}
                variant="contained"
                type="submit"
              >
                {lang.get("save")} &nbsp;
                {this.state.loading ? (
                  <CircularProgress
                    style={{ marginLeft: 10 }}
                    size="20px"
                    color="secondary"
                  />
                ) : (
                  ""
                )}
              </Button>
              <p></p>
            </ValidatorForm>
          </div>
          <ConfirmDialog
            open={this.state.supplierToDelete !== null}
            text={lang.get("deleteItem")}
            onResult={this.onConfirmSupplierResult.bind(this)}
          />
          {this.state.takePhoto ? (
            <TakePhotoDialog
              open={this.state.takePhoto}
              onClose={() => {
                this.setState({ takePhoto: false });
              }}
              onFileReady={(file) => {
                this.setState({ photoFile: file });
                this.onFileDrop([file]);
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = (theme: Theme) => ({
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 5,
  },
  formControl: {
    marginTop: 15,
  },
  button: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  displayNone: {
    display: "none",
  },
  iconButton: {
    marginTop: 13,
  },
});

export default withStyles(styles, { withTheme: true })(EditCosts);
