import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { menuOptionFilteredByRole } from "../../Utils/MenuOptionsFilteredByRole";
import clsx from "clsx";
import CachedDataSingleton from "../../cachedDataSingleton";


type MenuListProps = {
    color?: string
};
const useStyles = makeStyles((theme) => ({

    square: {
        height: 172,
        border: "1px solid #ddd",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& h6": {
            marginTop: 10
        },
    },
    halfSquareWrapperTitle: {
        width: "50%",
        height: 172,
    },
    halfSquareWrapper: {
        width: "50%",
        height: 172,
        "& h6": {
            marginLeft: 5
        }
    },
    halfSquare: {
        flexGrow: 1,
        height: 86,
        border: "1px solid #ddd",
        display: "flex",
        padding: 10,
        alignItems: "center"
    },
    title: {
        textTransform: "uppercase",
        color: "white",
        fontSize: 14,
    },
    disabled: {
        opacity: 0.2
    },
    relatedDocs: {
        display: "flex"
    },
    link: {
        padding: 10,
        borderBottom: "1px solid #ffffff88"
    },
    projects: {
        background: "linear-gradient(180deg, rgb(255 47 0) 0%, rgb(255 129 0) 100%)"
    },
    invoices: {
        background: "linear-gradient(180deg, rgba(32,137,227,1) 0%, rgba(72,188,231,1) 100%)"
    },
    estimates: {
        background: "linear-gradient(180deg, rgba(23,181,146,1) 0%, rgba(19,201,123,1) 100%)"
    },
    hr: {
        background: "linear-gradient(180deg, rgba(0,139,148,1) 0%, rgba(0,199,181,1) 100%)",
        "& div:last-child":{
            border:"none"
        }
    }

}));
export default function MenuIcons(props: MenuListProps) {
    const menuOptions: any = menuOptionFilteredByRole();
    const classes = useStyles();
    const cachedData = CachedDataSingleton.getInstance();
    const icid = cachedData.icid;
    // const upgradeUrl = `/getPackage/${icid}`;
    return (
        <Grid container spacing={2}>
            <Grid item lg={2} md={2} sm={6} xs={12}>
                <Link to={menuOptions.PROJECTS ? menuOptions.PROJECTS.link: "#"}>
                    <div className={clsx(classes.square, classes.projects, { [classes.disabled]: !menuOptions.PROJECTS })} style={{borderRadius:4}}>
                        {menuOptions.icons.PROJECTS.iconDashboard}
                        <Typography className={classes.title} variant="h6" component="h6">
                            {menuOptions.icons.PROJECTS.label}
                        </Typography>
                    </div>
                </Link>
            </Grid>

            {/* invoice related */}
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className={classes.relatedDocs}>
                    <div className={classes.halfSquareWrapperTitle}>
                        <Link to={menuOptions.DOCUMENTS ? menuOptions.DOCUMENTS["invoices"].link: "#"}>
                            <div className={clsx(classes.square, classes.invoices, { [classes.disabled]: !menuOptions.DOCUMENTS["invoices"] })}
                                style={{ borderRadius:"4px 0 0 4px" }}
                            >
                                {menuOptions.icons.DOCUMENTS["invoices"].iconDashboard}
                                <Typography className={classes.title} variant="h6" component="h6">
                                    {menuOptions.icons.DOCUMENTS["invoices"].label}
                                </Typography>
                            </div>
                        </Link>
                    </div>
                    <div className={classes.halfSquareWrapper}>
                        <Link to={menuOptions.DOCUMENTS ? menuOptions.DOCUMENTS["proformas"].link: "#"}>
                            <div className={clsx(classes.halfSquare, classes.invoices, { [classes.disabled]: !menuOptions.DOCUMENTS["invoices"] })}
                                style={{   borderRadius:"0 4px 0 0" }}
                            >
                                {menuOptions.icons.DOCUMENTS["proformas"].iconDashboard}
                                <Typography className={classes.title} variant="h6" component="h6">
                                    {menuOptions.icons.DOCUMENTS["proformas"].label}
                                </Typography>
                            </div>
                        </Link>
                        <Link to={menuOptions.DOCUMENTS ? menuOptions.DOCUMENTS["creditNotes"].link: "#"}>
                            <div className={clsx(classes.halfSquare, classes.invoices, { [classes.disabled]: !menuOptions.DOCUMENTS["invoices"] })}
                                style={{  borderRadius:"0 0 4px 0" }}
                            >
                                {menuOptions.icons.DOCUMENTS["creditNotes"].iconDashboard}
                                <Typography className={classes.title} variant="h6" component="h6">
                                    {menuOptions.icons.DOCUMENTS["creditNotes"].label}
                                </Typography>
                            </div>
                        </Link>
                    </div>
                </div>
            </Grid>

            {/* estimates related */}
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className={classes.relatedDocs}>
                <div className={classes.halfSquareWrapperTitle} >
                    <Link to={menuOptions.DOCUMENTS && menuOptions.DOCUMENTS["estimates"] ? menuOptions.DOCUMENTS["estimates"].link : "#"}>
                        <div className={clsx(classes.square, classes.estimates, { [classes.disabled]: !menuOptions.DOCUMENTS["estimates"] })}
                            style={{ borderRadius: "4px 0 0 4px"}}
                        >
                            {menuOptions.icons.DOCUMENTS["estimates"].iconDashboard}
                            <Typography className={classes.title} variant="h6" component="h6">
                                {menuOptions.icons.DOCUMENTS["estimates"].label}
                            </Typography>
                        </div>
                    </Link>
                    </div>
                    <div className={classes.halfSquareWrapper}>
                        <Link to={menuOptions.DOCUMENTS && menuOptions.DOCUMENTS["orderTickets"] ? menuOptions.DOCUMENTS["orderTickets"].link: "#"}>
                            <div className={clsx(classes.halfSquare, classes.estimates, { [classes.disabled]: !menuOptions.DOCUMENTS["estimates"] })}
                                style={{  borderRadius: "0 4px 0 0" }}
                            >
                                {menuOptions.icons.DOCUMENTS["orderTickets"].iconDashboard}
                                <Typography className={classes.title} variant="h6" component="h6">
                                    {menuOptions.icons.DOCUMENTS["orderTickets"].label}
                                </Typography>
                            </div>
                        </Link>
                        <Link to={menuOptions.DOCUMENTS && menuOptions.DOCUMENTS["subcontractorOrderTickets"] ? menuOptions.DOCUMENTS["subcontractorOrderTickets"].link: "#"}>
                            <div className={clsx(classes.halfSquare, classes.estimates, { [classes.disabled]: !menuOptions.DOCUMENTS["estimates"] })}
                                style={{ borderRadius: "0 0 4px 0" }}
                            >
                                {menuOptions.icons.DOCUMENTS["subcontractorOrderTickets"].iconDashboard}
                                <Typography className={classes.title} variant="h6" component="h6" style={{fontSize:12}}>
                                    {menuOptions.icons.DOCUMENTS["subcontractorOrderTickets"].label}
                                </Typography>
                            </div>
                        </Link>
                    </div>
                </div>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={12}>
                <div className={clsx(classes.square, classes.hr, { [classes.disabled]: !menuOptions.PEOPLE["clients"] })}
                    style={{borderRadius:4}}
                >
                    <div>
                        <div className={classes.link} >
                            <Link to={menuOptions.PEOPLE && menuOptions.PEOPLE["clients"] ? menuOptions.PEOPLE["clients"].link : "#"}>
                                <Typography className={classes.title} variant="h6" component="h6" style={{marginTop:-5, display:"flex"}}>
                                    {menuOptions.icons.PEOPLE["clients"].iconDashboard} &nbsp;&nbsp;&nbsp;{menuOptions.icons.PEOPLE["clients"].label}
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.link} >
                            <Link to={menuOptions.PEOPLE && menuOptions.PEOPLE["subcontractors"] ? menuOptions.PEOPLE["subcontractors"].link : "#"}>
                                <Typography className={classes.title} variant="h6" component="h6" style={{marginTop:-5, display:"flex"}}>
                                    {menuOptions.icons.PEOPLE["subcontractors"].iconDashboard} &nbsp;&nbsp;&nbsp;{menuOptions.icons.PEOPLE["subcontractors"].label}
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.link} >
                            <Link to={menuOptions.PEOPLE && menuOptions.PEOPLE["employees"] ? menuOptions.PEOPLE["employees"].link : "#"}>
                                <Typography className={classes.title} variant="h6" component="h6" style={{marginTop:-5, display:"flex"}}>
                                    {menuOptions.icons.PEOPLE["employees"].iconDashboard} &nbsp;&nbsp;&nbsp;{menuOptions.icons.PEOPLE["employees"].label}
                                </Typography>
                            </Link>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}