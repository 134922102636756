import { Grid } from "@material-ui/core";
import Lang from "../lang";
import PropTypes from "prop-types";
const lang = Lang.getInstance();

/** document styles **/
const style1 = {
  header: {
    marginRight: "-15px",
    marginLeft: "-15px",
    lineHeight: "1.5",
    marginBottom: "10px",
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "25% 30% 35%",
  },
  logo: {
    maxHeight: 100,
    maxWidth: "100%",
    marginRight: "5px",
  },
  invoiceText: {
    fontSize: "22px",
    margin: 0,
    textTransform: "uppercase",
  },
  companyName: {
    fontSize: "18pt",
    color: "#5f5f5f",
    lineHeight: "1.2",
    textAlign: "left",
    margin: 0,
  },
  companyInfo: {
    fontSize: "12pt",
    color: "#5f5f5f",
    lineHeight: "1.2",
    fontStyle: "italic",
    fontWeight: "200",
    textAlign: "left",
  },
  firstGrid: {
    padding: 0,
    // width: "25%",
    display: "inline-table",
    verticalAlign: "top",
    marginLeft: "20px",
  },
  middleGrid: {
    padding: 0,
    // width: "30%",
    display: "inline-table",
    verticalAlign: "top",
  },
  lastGrid: {
    // width: "35%",
    marginRight: "20px",
    display: "inline-table",
    verticalAlign: "top",
  },
  extraInfo: {
    color: "#5f5f5f",
  },
};

export default function PVReceptionHeader(props) {
  const company = props.company;
  let styles = style1;
  const protocol = window.location.href.split("/")[0];

  return (
    <div style={styles.header}>
      <div style={styles.firstGrid}>
        {company.logo && company.logo !== "undefined" ? (
          <img
            src={`${protocol}//${document.location.host}/${company.logo}`}
            alt="logo"
            style={styles.logo}
          />
        ) : (
          <p style={styles.invoiceText}>{company.name || ""}</p>
        )}
      </div>
      <div style={styles.middleGrid}>
        <h2 style={styles.companyName}>
          <strong>{"DAVANY SPRL"}</strong>
        </h2>
        <h6 style={styles.companyInfo}>
          <em> {"Entreprise générale de bâtiment"}</em>
        </h6>
        <br />
      </div>
      <div style={styles.lastGrid}>
        <p style={styles.extraInfo}>38 Rue VAN YSENDYCK 1030 Bruxelles</p>
        <p style={styles.extraInfo}>BE0892 901 232 - 30.10.10</p>
      </div>
    </div>
  );
}

PVReceptionHeader.propTypes = {
  company: PropTypes.object.isRequired,
  color: PropTypes.string,
  appearance: PropTypes.string | null | undefined,
};
