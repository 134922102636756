import PropTypes from 'prop-types';
import Lang from '../lang';
import { Address } from '../Models/Models';
import { DocTypes } from '../Utils/Utils';

const lang = Lang.getInstance();

/** document styles **/
const style1 = {
    headerWrapper: {
        marginRight: -20,
        fontSize: "9pt",
        marginBottom: 20
    },
    estimateText: {
        fontSize: "25px",
        margin: 0,
        textTransform: "uppercase"
    },
    logoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 10,
        verticalAlign: "top"
    },
    companyWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: 15,
        verticalAlign: "top",
        fontSize: '11pt',
    },
    docEstimateInfoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 5,
        verticalAlign: "top"
    },
    clientWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: -10,
        verticalAlign: "top",
        fontSize: '11pt',
    },
    logo: {
        maxHeight: 100
    },
    clear: {
        clear: "both",
        borderBottom: "10px solid #ccc",
        marginBottom: 20,
        marginTop: 20,
        marginRight: 20
    }
};

const style2 = {
    headerWrapper: {
        marginRight: -20,
        fontSize: "9pt",
        marginBottom: 20
    },
    estimateText: {
        fontSize: "16pt",
        margin: 0,
        color: "#000000",
    },
    logoWrapper: {
        width: "100%",
        flexGrow: 3,
        marginBottom: 20
    },
    companyWrapper: {
        width: "30%",
        marginLeft: 0,
        marginRight: 15,
        display: "inline-table",
        verticalAlign: "top",
        fontSize: '11pt',
    },
    docEstimateInfoWrapper: {
        width: "30%",
        border: "1px solid #000",
        display: "inline-table",
        padding: 10,
        marginRight: 20,
        marginBottom: 20,
        fontSize: 10,
        verticalAlign: "top"
    },
    clientWrapper: {
        width: "30%",
        display: "inline-table",
        marginLeft: 15,
        marginRight: -10,
        fontSize: '11pt',
        verticalAlign: "top"
    },
    logo: {
        maxHeight: 100,
        display: "block",
        margin: "0 auto"
    },
};

const style3 = {
    headerWrapper: {
        marginRight: -20,
        fontSize: "9pt",
        marginBottom: 20
    },
    estimateText: {
        fontSize: "25pt",
        margin: 0,
        color: "#000000",
        textTransform: "uppercase"
    },
    logoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 10,
        float: "right",
        verticalAlign: "top",
    },
    docEstimateInfoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 5,
        float: "left",
        verticalAlign: "top"
    },
    companyWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: 15,
        verticalAlign: "top",
        fontSize: '11pt',
    },
    clientWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: -10,
        verticalAlign: "top",
        fontSize: '11pt',
    },
    logo: {
        maxHeight: 100,
        width: "auto",
        marginRight: 40,
        marginLeft: "auto",
        display: "block"
    },
    clear: {
        clear: "both",
        borderBottom: "10px solid #ccc",
        marginBottom: 20,
        marginTop: 20,
        marginRight: 20
    }
};

const style4 = {
    col49: {
        display: "inline-block",
        width: "48%",
        marginLeft: 5,
        fontSize:'11pt',
    },
    box: {
        background: "#ddd",
        border: "1px solid #000",
        padding: 10,
        textAlign: "center"
    },
    logo: {
        maxWidth: 150,
        display: "block"
    },
    title: {
        textAlign: "center",
        fontSize: 30
    },
    titleWrapper: {
        textAlign: "center",
        margin: "20px 0 20px 0"
    }
};


export default function EstimateHeader (props) {
    const company = props.company;
    const client = props.client;
    let styles = style1;

    let docName = lang.get("offer");
    switch (props.docType) {
        case DocTypes.orderTicket:
            docName = lang.get("orderTicket");
            break;
        default:
            docName = lang.get("offer")
            break;
    }

    const renderStyle3Header = () => {
        return (
            <div>
                <div style={styles.col49}>
                    {
                         (company.logo && company.logo!=='undefined')?
                         <img src={company.logo} alt="logo" style={styles.logo} /> :
                         <p style={styles.estimateText}>{company.name}</p>
                    }
                    <small>{company.description !== 'null'?company.description:""}</small><br />
                </div>

                <div style={styles.col49}>
                    <strong>{company.name}</strong><br />
                    <em>{lang.get("address")}:<small> {Address.stringify(company.address)}</small></em><br />
                    <small><strong>{lang.get("bankName")}</strong></small>:<small>{company.bankName}</small><br />
                    <small><strong>{lang.get("bankAccount")}</strong></small>: <small>{company.bankAccount}</small><br />
                    <small><strong>{lang.get("vatNo")}</strong></small>: <small>{company.vatNo !== 'null' ?company.vatNo:""}</small><br />
                </div>

                <div style={styles.titleWrapper}>
                    <h1 style={{ ...styles.title, margin: 0, color:"#000" }}>{docName?docName:""}</h1>
                    <h2 style={{ margin: 3, textAlign: "center" }}>{props.title?props.title:""}</h2>
                    <h4 style={{ margin: 3, textAlign: "center" }}>{props.workSiteAddress?props.workSiteAddress:""}</h4>
                </div>

                <div style={{ border: "1px solid #ccc" }}>
                    <div style={styles.col49}>
                        {lang.get("series")}: {props.series || ""} {lang.get("number")}: {props.number || ""}<br />
                        {lang.get("date")}: {props.date || ""}
                        <br />
                        {props.description ?
                            <span>{lang.get("description")}: {props.description !=='null' ? props.description:""}</span> :
                            ""
                        }
                        <br />
                        {props.additionalInfo ?
                            <span>{lang.get("additionalInformation")}: {props.additionalInfo || ""}</span> :
                            ""
                        }
                    </div>
                    <div style={{ verticalAlign: "top", minHeight: 80, ...styles.col49, ...styles.box }}>
                    <strong>{client.title}</strong>{" "} <strong>{client.name}</strong><br />
                        {client.vatNo && client.vatNo.trim().length > 3 ?
                            <>
                                <em>{lang.get("vatNo")}: {client.vatNo !== 'null'?client.vatNo:""}</em>
                                <br />
                            </>
                            : ""
                        }
                        {lang.get("address")}: {Address.stringify(client.address)}
                    </div>
                </div>
            </div>
        );
    };

    if (props.appearance === 3) {
        styles = style4;
        return renderStyle3Header();
    }


    if (props.appearance) {
        switch (props.appearance) {
            case 0:
                styles = style1;
                break;
            case 1:
                styles = style2;
                break;
            case 2:
                styles = style3;
                break;
            default:
                styles = style1;
                break;
        }
    }

    return (
        <div style={styles.headerWrapper}>

            <div style={styles.logoWrapper}>
                {
                     (company.logo && company.logo!=='undefined')?
                     <img src={company.logo} alt="logo" style={styles.logo} /> :
                     <p style={styles.estimateText}>{company.name}</p>
                }
            </div>
            <div style={styles.docEstimateInfoWrapper}>
                <h1 style={{ margin: 0, color: "#000",fontSize:"11pt", ...styles.estimateText }}>{docName}</h1>
                <h4 style={{ margin: 0,fontSize:"11pt" }}>{props.title}</h4>
                <h4 style={{ margin: 0,fontSize:"11pt" }}>{props.workSiteAddress}</h4>
                <p style={{fontSize:"11pt"}}>{lang.get("series")}: {props.series || ""} {lang.get("number")}: {props.number || ""}<br />
                    {lang.get("date")}: {props.date || ""}
                </p>
            </div>
            {props.appearance !== 1 ? <div style={{ ...styles.clear, borderColor: props.color }}></div> : ""}
            <div style={styles.companyWrapper}>
                <h3 style={{ margin: 0 }} >{lang.get("supplier")}</h3>

                <p><strong>{company.name}</strong><br />
                    <strong>{lang.get("vatNo")}</strong>: {company.vatNo !== 'null' ? company.vatNo:""}<br />
                    <strong>{lang.get("companyRegNo")}</strong>: {company.companyRegNo}<br />
                    <strong>{lang.get("bankAccount")}</strong>: {company.bankAccount}<br />
                    <strong>{lang.get("address")}</strong>: {Address.stringify(company.address)}<br />
                    <strong>{lang.get("mobile")}</strong>: {company.mobile}<br />
                </p>
            </div>

            <div style={styles.clientWrapper}>
                <h3 style={{ margin: 0 }} >{lang.get("client")}</h3>
                <p><strong>{client.title}</strong>{" "}<strong>{client.name}</strong><br />
                    {client.vatNo ?
                        <>
                            <strong>{lang.get("vatNo")}</strong>: {client.vatNo !== 'null' ? client.vatNo:""}<br />
                        </> :
                        ""
                    }
                    <strong>{lang.get("address")}</strong>: {Address.stringify(client.address)}<br />
                </p>
            </div>
        </div>
    );
}

EstimateHeader.propTypes = {
    company: PropTypes.object.isRequired,
    series: PropTypes.string,
    number: PropTypes.string,
    date: PropTypes.string,
    workSiteAddress: PropTypes.string,
    client: PropTypes.object.isRequired,
    appearance: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    additionalInfo: PropTypes.string,
    docType: PropTypes.string
}