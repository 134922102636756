import { Button, ButtonGroup, makeStyles, Modal } from "@material-ui/core";
import { Fragment, useState } from "react";
import { get, UrlEnum } from "../Utils/Utils";
import Lang from "../lang";
import { Add, AddCircle, Save } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { annualBudgetEstimate } from "../Utils/Constants";

type BudgetEstimateYearsProps = {
  budgetEstimateData: {
    selectedYear: number | null;
    years: any[];
    estimateBudget: any[];
    actualBudget: any[];
    id: number | null;
  };
  setYearData: any;
  handleChangeModalState: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "40px",
  },
  buttonGroup: {
    width: "100%",
  },
  saveButton: {
    background: theme.palette.header?.main,
    color: "#fff",
  },
  addAnnualCostButton: {
    background: theme.palette.iconDefault?.main,
    color: "#fff",
    marginRight: 10,
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "0 25px",
  },
}));

export default function BudgetEstimateYears(props: BudgetEstimateYearsProps) {
  const { budgetEstimateData, setYearData, handleChangeModalState } = props;
  const lang = Lang.getInstance();
  const nextYear = moment();
  const [renderYearPiker, setRenderYearPiker] = useState(false);
  const classes = useStyles();
  /**
   *
   */
  const handleYearPiker = () => {
    setYearData({
      ...budgetEstimateData,
      selectedYear: null,
      estimateBudget: [],
      actualBudget: [],
    });
    setRenderYearPiker(!renderYearPiker);
  };

  /**
   *
   * @param {*} date
   */
  const handleChangeYearPicker = (date: any) => {
    const year: any = date?.year();
    //check if year is already in state.years
    if (year && budgetEstimateData?.years.indexOf(year) === -1) {
      let newYears = budgetEstimateData.years;
      newYears.push(year);
      setYearData({
        ...budgetEstimateData,
        years: newYears.sort(),
        selectedYear: year,
        estimateBudget: annualBudgetEstimate,
        id: null,
      });
    } else {
      let url = `${UrlEnum.budgetEstimates}/${year}`;
      get(url).then((response) => {
        if (!response.error) {
          const estimateBudget = JSON.parse(response.estimate_data);
          setYearData({
            ...budgetEstimateData,
            selectedYear: response.year,
            estimateBudget: estimateBudget,
            actualBudget: response.actual_budget,
            id: response.id,
          });
        }
      });
    }
    setRenderYearPiker(false);
  };

  /**
   *
   * @param selectedYear
   * @returns
   */
  async function getOverviewFromYear(selectedYear: any) {
    setRenderYearPiker(false);
    let year = selectedYear ? selectedYear : budgetEstimateData.selectedYear;
    let response = await get(`${UrlEnum.budgetEstimates}/${year}`);
    if (response.error) return;
    const estimateBudget = JSON.parse(response.estimate_data);
    setYearData({
      ...budgetEstimateData,
      selectedYear: response.year,
      estimateBudget: estimateBudget,
      actualBudget: response.actual_budget,
      id: response.id,
    });
  }

  return (
    <div className={classes.container}>
      <ButtonGroup
        size="small"
        aria-label="small outlined button group"
        className={classes.buttonGroup}
      >
        {budgetEstimateData.years &&
          budgetEstimateData.years
            .sort((a, b) => a - b)
            .map((value, index) => {
              return (
                <Button
                  type="button"
                  key={index}
                  style={{
                    backgroundColor:
                      value === budgetEstimateData.selectedYear
                        ? "#E0E0E0"
                        : "#fff",
                    height: "100%",
                    fontSize: 14,
                  }}
                  onClick={() => getOverviewFromYear(value)}
                >
                  {value}
                </Button>
              );
            })}
        <Button type="button" onClick={handleYearPiker} startIcon={<Add />}>
          {lang.get("addNewBudgetEstimate")}
        </Button>
      </ButtonGroup>
      {renderYearPiker ? (
        <DatePicker
          label={lang.get("addYear")}
          minDate={nextYear}
          openTo="year"
          value={
            budgetEstimateData.selectedYear
              ? moment().year(budgetEstimateData.selectedYear).month(0).date(1)
              : null
          }
          views={["year"]}
          onChange={handleChangeYearPicker}
        />
      ) : null}
      {budgetEstimateData.selectedYear ? (
        <Fragment>
          <Button
            startIcon={<AddCircle />}
            className={classes.addAnnualCostButton}
            variant="contained"
            onClick={handleChangeModalState}
          >
            {lang.get("addAnnualCost")}
          </Button>
          <Button
            type="submit"
            form="budget-estimate-form"
            className={classes.saveButton}
            variant="contained"
            startIcon={<Save />}
          >
            {lang.get("save")}
          </Button>
        </Fragment>
      ) : null}
    </div>
  );
}
