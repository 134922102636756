import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerLayout from "../Pages/DrawerLayout";
import { get, post, UrlEnum } from "../Utils/Utils";
import BudgetEstimateYears from "./BudgetEstimateYears";
import ShowExpenses from "./ShowExpenses";
import Lang from "../lang";
import BudgetEstimateReports from "./BudgetEstimateReports";
import { Alert } from "@material-ui/lab";

type BudgetEstimateProps = {
  key: string;
  showSmallMessage: Function;
  menuOptions: any;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    maxHeight: 810,
    overflowY: "auto",
  },
  summary: {
    marginTop: 20,
  },
  addButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  summaryTitle: {
    background: theme.palette.header?.main,
    padding: 5,
    color: "#fff",
    borderRadius: 2,
  },
  totalsValue: {
    float: "right",
  },
  loadingWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1500,
    background: "rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
  },
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    padding: "20px",
  },
}));

function BudgetEstimate(props: BudgetEstimateProps) {
  const [openModal, setOpenModal] = useState(false);
  const costMenu = props.menuOptions.MORE["costs"];
  const [state, setState] = useState({
    id: null,
    selectedYear: null,
    years: [],
    estimateBudget: [],
    actualBudget: [],
  });
  const [annualCostEstimate, setAnnualCostEstimate] = useState({
    expense: "",
    value: 0,
  });
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState("");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const smallMessageTimeout = 5000;
  const lang = Lang.getInstance();
  const months = [
    lang.get("january"),
    lang.get("february"),
    lang.get("march"),
    lang.get("april"),
    lang.get("may"),
    lang.get("june"),
    lang.get("july"),
    lang.get("august"),
    lang.get("september"),
    lang.get("october"),
    lang.get("november"),
    lang.get("december"),
  ];

  /**
   *
   * @param event
   */
  const handleOnChange = (event: any) => {
    let newData: any = Object.assign({}, annualCostEstimate);
    newData[event.target.name] = event.target.value;
    setAnnualCostEstimate(newData);
  };

  /**
   *
   * @param isSuccess
   */
  const showSmallMessage = (isSuccess: boolean) => {
    if (isSuccess) setSuccess(lang.get("success"));
    else setErrors(lang.get("error"));

    setTimeout(() => {
      setSuccess("");
      setErrors("");
    }, smallMessageTimeout);
  };

  /**
   * Get budget estimate years
   */
  useEffect(() => {
    if (!state.selectedYear)
      //get budget estimate years
      get(UrlEnum.budgetEstimates).then((response) => {
        if (!response.error) {
          //iterate over the response witch is an array of objects
          let years: any = [];
          response.forEach((element: any) => {
            years.push(element.year);
          });
          setState({
            ...state,
            years: years,
          });
        }
      });
  }, []);

  /**
   *
   * @param {*} monthId
   * @param {*} data
   */
  const saveData = (monthId: any, data: any) => {
    //replace expense object in specific month
    let newExpenses: any = state.estimateBudget;
    newExpenses[monthId] = data;
    setState({ ...state, estimateBudget: newExpenses });
  };

  /**
   *  Save expense to database
   */
  const saveExpenseToDatabase = () => {
    setLoading(true);
    let url = `${UrlEnum.budgetEstimates}${state.id ? "/" + state.id : ""}`;
    post(url, {
      year: state.selectedYear,
      estimate_data: state.estimateBudget,
    }).then((response) => {
      setLoading(false);
      if (!response.error) {
        //string to object estimate_data
        let newExpenses = JSON.parse(response.estimate_data);
        setState({
          ...state,
          estimateBudget: newExpenses,
          selectedYear: response.year,
          id: response.id,
        });
        showSmallMessage(true);
      } else {
        showSmallMessage(false);
      }
    });
  };

  /**
   *
   * @param cost
   * @param month
   */
  const copyExpenseToAnotherMonth = (
    cost: { expense: string; value: number },
    months: number[]
  ) => {
    //iterate over the months array and push the expense to the selected month
    let newExpenses: any = state.estimateBudget;
    months.forEach((month) => {
      newExpenses[month].expenses.push(cost);
    });
    setState({ ...state, estimateBudget: newExpenses });
  };

  /**
   *
   * @returns
   */
  const calculateYearTotal = () => {
    let total = 0;
    if (state.estimateBudget && state.estimateBudget.length !== 0) {
      state.estimateBudget.forEach((monthData: any) => {
        total += monthData.expenses.reduce((total: any, data: any) => {
          if (isNaN(data.value) || data.value === "") return total;
          return total + parseFloat(data.value);
        }, 0);
      });
    }
    return total;
  };

  /**
   *
   * @returns
   */
  const calculateActualYearTotal = () => {
    let total = 0;
    if (state.actualBudget && state.actualBudget.length !== 0) {
      state.actualBudget.forEach((monthData: any) => {
        total += monthData.total_cost ? parseFloat(monthData.total_cost) : 0;
      });
    }
    return total;
  };

  /**
   *
   * @returns
   */
  const renderYearSummary = () => {
    return state.selectedYear && state.estimateBudget.length > 0 ? (
      <div className={classes.summary}>
        <div className={classes.summaryTitle}>{`${lang.get("summary")}: ${
          state?.selectedYear
        }`}</div>
        <p>
          {lang.get("estimatedTotal")}:{" "}
          <span className={classes.totalsValue}>
            {calculateYearTotal().toFixed(2)}
          </span>
        </p>
        <p>
          {lang.get("actualTotal")}:{" "}
          <span className={classes.totalsValue}>
            {calculateActualYearTotal().toFixed(2)}
          </span>
        </p>
      </div>
    ) : null;
  };

  /**
   *
   * @param month
   * @returns
   */
  const findActualBudget = (month: number) => {
    if (!state.actualBudget || !state.actualBudget.length) return null;
    return (
      state.actualBudget.find((actual: any) => actual.month === month) || null
    );
  };

  /**
   *
   */
  const handleChangeModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   * @param param0
   */
  const addAnnualCost = (
    e: any,
    {
      expense,
      value,
    }: {
      expense: string;
      value: number;
    }
  ) => {
    e.preventDefault();
    //divide the cost over 12 months
    let newExpenses = state.estimateBudget;
    newExpenses.forEach((monthData: any) => {
      monthData.expenses.push({ expense, value: value?  (value/ 12).toFixed(2) : 0 });
    });
    setState({ ...state, estimateBudget: newExpenses });
    setOpenModal(false);
  };

  return (
    <DrawerLayout
      open={open}
      handleDrawerClose={() => setOpen(!open)}
      drawerWidth={299}
      drawerChildren={
        <Fragment>
          <Link to={costMenu.link}>
            <ListItem button>
              <ListItemIcon>{costMenu.icon}</ListItemIcon>
              <ListItemText primary={costMenu.label} />
            </ListItem>
          </Link>
          {renderYearSummary()}
        </Fragment>
      }
    >
      <form
        id="budget-estimate-form"
        onSubmit={(event) => {
          event.preventDefault();
          saveExpenseToDatabase();
        }}
      >
        <Paper className={classes.paper}>
          {loading ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            ""
          )}
          <BudgetEstimateYears
            budgetEstimateData={state}
            setYearData={setState}
            handleChangeModalState={handleChangeModalState}
          />
          {errors ? (
            <Alert severity="error" style={{ margin: "10px 0px" }}>
              {errors}
            </Alert>
          ) : (
            ""
          )}
          {success ? (
            <Alert severity="success" style={{ margin: "10px 0px" }}>
              {success}
            </Alert>
          ) : (
            ""
          )}
          <BudgetEstimateReports
            months={months}
            actualData={state.actualBudget}
            estimateData={state.estimateBudget}
          />
          <List>
            {state?.estimateBudget.length
              ? state.estimateBudget.map((monthData: any, key: any) => {
                  return (
                    <ShowExpenses
                      months={months}
                      data={monthData}
                      actualBudget={findActualBudget(key)}
                      saveData={saveData}
                      copyExpenseToAnotherMonth={copyExpenseToAnotherMonth}
                      key={key}
                    />
                  );
                })
              : null}
          </List>
        </Paper>
      </form>
      <Modal
        className={classes.modalWindow}
        open={openModal}
        onClose={handleChangeModalState}
      >
        <Paper className={classes.modalPaper}>
          <form
            id="annual-cost-form"
            onSubmit={(e) => {
              addAnnualCost(e, annualCostEstimate);
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">
                  {lang.get("addAnnualCost")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={5} xl={5}>
                <TextField
                  label={lang.get("expense")}
                  name="expense"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => handleOnChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5} lg={5} xl={5}>
                <TextField
                  label={lang.get("value")}
                  name="value"
                  variant="outlined"
                  type="number"
                  required
                  fullWidth
                  onChange={(e) => handleOnChange(e)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                xl={2}
                className={classes.addButton}
              >
                <Button
                  form="annual-cost-form"
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {lang.get("add")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </DrawerLayout>
  );
}

export default React.memo(BudgetEstimate);
