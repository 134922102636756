import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Address, Subcontractor } from "../Models/Models";
import Lang from "../lang";
import React, { useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  get,
  UrlEnum,
  fromStringTemplate,
  StatusEnum,
  isMobile,
} from "../Utils/Utils";
import PreviewPDF from "./PreviewPDF";
import Config from "../Utils/Config";
import InvoiceFooter from "../DocumentTemplates/InvoiceFooter";
import CachedDataSingleton from "../cachedDataSingleton";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import TextEditor from "../Components/TextEditor";
import FormSubmitButton from "../Forms/FormSubmitButton";
import { PostAdd } from "@material-ui/icons";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

type SubcontractorContractProps = {
  company: any;
  setSmallMessage: Function;
  subcontractor: Subcontractor;
};

let submitFunction: any = null;

function SubcontractorContract(props: SubcontractorContractProps) {
  const [htmlContent, setHtmlContent] = useState("");
  const [customText, setCustomText] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const textEditorRef: any = React.createRef();
  let url = UrlEnum.pdf.replace("{type}", "subcontractorContract");
  url = url.replace("{id}", "0");

  /**
   *
   */
  useEffect(() => {
    get(UrlEnum.documentTemplates + "/subcontractorContract").then(
      (response) => {
        if (response.errors) {
          alert(response.errors);
          setLoading(false);
          return;
        }
        if (response) {
          if (response.customText) {
            setCustomText(JSON.parse(response.customText));
          } else {
            setCustomText("");
          }
        }
        setLoading(false);
      }
    );
  }, []);

  /**
   *
   * @param e
   * @returns
   */
  async function submit(e: any) {
    e.preventDefault();
    setOpen(false);
    if (submitFunction) {
      let data = {
        customText: textEditorRef.current
          ? JSON.stringify(textEditorRef.current.getContent())
          : "",
      };
      setCustomText(
        textEditorRef.current ? textEditorRef.current.getContent() : null
      );

      await submitFunction(data);
    }
    generateSubcontractorContract();
    return false;
  }

  let footer = ReactDOMServer.renderToString(<div>{renderFooter()}</div>);
  footer = `<html>
              <head>
              <meta charset='UTF-8'/>
              <script>
                  var test = function() {
                      var vars = {};
                      var x = document.location.search.substring(1).split('&');
                      for (var i in x) {
                          var z = x[i].split('=', 2);
                          vars[z[0]] = unescape(z[1]);
                      }
                      if (vars['page'] == vars['topage']) {
                          document.getElementById("extra").innerHTML = ;
                      }else{
                          var footer = document.getElementById("footer");
                          footer.style.setProperty("position","relative");
                          footer.style.setProperty("top","50px");
                      }
                      document.getElementById("pageNum").innerHTML = vars['page'] + "/" + vars['topage'];
                  };
              </script>
              </head><body onload="test()" ><div id="extra" ></div>${footer}</body></html>`;

  /**
   *
   */
  const generateSubcontractorContract = async () => {
    const html = await renderSubcontractorContract(
      props.company,
      props.subcontractor
    );
    if (html !== undefined) {
      const htmlContentString = `<html>
                  <head>
                      <meta charset='UTF-8'/>
                      <style>
              @import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
              html{
                font-family:Roboto,Arial, sans-serif;
              },
                      </style>
                  </head>
                  <body><p>${html}</p></body></html>`;
      setHtmlContent(htmlContentString);
    }
  };

  /**
   *
   */
  function renderFooter() {
    const company = cachedData.get("company");
    return <InvoiceFooter company={company}></InvoiceFooter>;
  }

  /**
   *
   * @param company
   * @returns
   */
  async function renderSubcontractorContract(
    company: {
      name: string;
      id: number;
      address: any;
      bankName: string;
      companyRegNo: string;
      vatNo: string;
      representativeName: string;
    },
    subcontractor: Subcontractor
  ) {
    const response = await get(
      `${UrlEnum.documentTemplates}/subcontractorContract/${company.id}`
    );
    if (response.errors) return "";
    if (Object.keys(response).length === 0) {
      props.setSmallMessage(lang.get("confirmContract"), StatusEnum.ERROR);
      return;
    }
    let contentJson = JSON.parse(response.customText);
    const contentState = convertFromRaw(contentJson);
    let customHtml = stateToHTML(contentState);
    customHtml = fromStringTemplate(customHtml, {
      companyName: company?.name ? company?.name : "",
      companyAddress: company?.address
        ? Address.stringify(company?.address)
        : "",
      companyBank: company.bankName ? company.bankName : "",
      companyRegNo: company.companyRegNo ? company.companyRegNo : "",
      companyVatNo: company.vatNo ? company.vatNo : "",
      companyRepresentativeName: company.representativeName
        ? company.representativeName
        : "",
      subcontractorName: subcontractor?.name ? subcontractor?.name : "",
      subcontractorAddress: subcontractor?.address
        ? Address.stringify(subcontractor?.address)
        : "",
      subcontractorVatNo: subcontractor?.vatNo ? subcontractor?.vatNo : "",
      date: moment().format(Config.momentEUDateFormat),
      time: moment().format(Config.momentEUDateFormatForHour),
    });
    return customHtml;
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PostAdd style={{ color: "#ffffff" }} />}
        onClick={() => setOpen(true)}
      >
        <Typography style={{ color: "#ffffff" }}>
          {lang.get("contract")}
        </Typography>
      </Button>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Dialog
            fullScreen={isMobile()}
            style={{ zIndex: 2000 }}
            maxWidth="xl"
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <div style={{ flexGrow: 2 }}>{lang.get("preview")}</div>
            </DialogTitle>
            <DialogContent style={{}}>
              <TextEditor
                height={450}
                fontSize={15}
                docType="subcontractorContract"
                initialState={customText}
                ref={textEditorRef}
              />
            </DialogContent>
            <DialogActions>
              <Grid xs={12} md={1} style={{ margin: 13 }}>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={"subcontractorContract"}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={`${UrlEnum.documentTemplates}/subcontractorContract`}
                  />
                </form>
              </Grid>
            </DialogActions>
          </Dialog>
          {htmlContent && (
            <PreviewPDF
              open={htmlContent.length > 0}
              onClose={() => setHtmlContent("")}
              htmlContent={htmlContent}
              footerHtml={footer}
              url={url}
              setSmallMessage={props.setSmallMessage}
              emailData={{ to: "", subject: "", message: "" }}
            />
          )}
        </>
      )}
    </>
  );
}

export default SubcontractorContract;
