import React, { Fragment } from "react";
import Lang from "../lang";
import {
  Theme,
  createStyles,
  withStyles,
  Grid,
  Paper,
  withWidth,
  Button,
  Menu,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import DrawerLayout from "../Pages/DrawerLayout";
import {
  isMobile,
  UrlEnum,
  LocalUrlEnum,
  get,
  handleInputChange,
  post,
  DocTypes,
  Statuses,
  PaymentMode,
} from "../Utils/Utils";
import {
  Invoice as InvoiceModel,
  DocService,
  Package,
  Service,
  Article,
} from "../Models/Models";
import InvoiceGeneralInfo from "../DocumentComponents/InvoiceGeneralInfo";
import { Client } from "../Models/Models";
import EditDocService from "../Components/EditDocService";
import DocTotals from "../Components/DocTotals";
import CachedDataSingleton from "../cachedDataSingleton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HierarchicalList from "../Components/HierarchicalList";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PaymentIcon from "@material-ui/icons/Payment";
import DescriptionIcon from "@material-ui/icons/Description";
import SelectServices from "./SelectServices";
import Config from "../Utils/Config";
import { CompanyOptionsEnum, StatusEnum } from "../Utils/Utils";
import InvoicePayments from "./InvoicePayments";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import DashboardContext from "../Contexts/DashboardContext";
import PreviewDocument from "./PreviewDocument";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Receipt from "../DocumentTemplates/Receipt";
import DocStatus from "../Components/DocStatus";
import { ValidatorForm } from "react-material-ui-form-validator";
import theme from "../Theme/Theme";
import ConfirmDialog from "../Components/ConfirmDialog";
// import { element } from 'prop-types';
const isMobileView = isMobile();
const lang = Lang.getInstance();

type InvoiceProps = {
  match: any;
  history: any;
  setLoading: (value: boolean) => void;
  setOpenMessage: (value: string) => void;
  width: any;
  theme: Theme;
  classes: any;
  type: string;
  showSmallMessage: Function;
};

type InvoiceState = {
  drawerOpen: boolean;
  model: InvoiceModel;
  serviceCategsAnchor: any;
  selectedCateg: any;
  selectedServices: boolean; // keep the id's  of the selected services;
  selectServicesOpen: boolean;
  paymentsOpen: boolean;
  previewOpen: boolean;
  loading: boolean;
  isSaved: boolean;
  open: boolean;
  indexService: number;
};

const styles = (theme: Theme) =>
  createStyles({
    servicesOptions: {
      left: 300,
      top: 64,
      padding: 0,
      transition: theme.transitions.create("left, width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "calc(100% - 300px)",
      [theme.breakpoints.down("md")]: {
        top: 56,
      },
    },
    servicesOptionsShift: {
      transition: theme.transitions.create("left, width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: 0,
      width: "100%",
    },
    saveBtn: {
      float: "right",
    },
    buttonLabel: {
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: 0,
      },
    },
    statusBtn: {
      width: "100%",
    },
    hierarchicalListBtn: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  });

class Invoice extends React.PureComponent<InvoiceProps, InvoiceState> {
  cachedDataSingleton: any;
  serviceCategories: any;
  matchParamsId: number;
  saveInterval: any;
  locationListenerUnlisten: any;
  pathname: string;
  generalInfoFormRef: any;

  constructor(props: InvoiceProps) {
    super(props);
    this.cachedDataSingleton = CachedDataSingleton.getInstance();
    this.serviceCategories = this.cachedDataSingleton.get("serviceCategories");
    this.state = {
      drawerOpen: !isMobileView,
      model: { ...new InvoiceModel(), type: props.type },
      serviceCategsAnchor: null,
      selectedCateg: this.serviceCategories[0],
      selectedServices: false,
      selectServicesOpen: false,
      paymentsOpen: false,
      previewOpen: false,
      loading: false,
      isSaved: false,
      open: false,
      indexService: 0,
    };

    this.matchParamsId = this.props.match.params.id
      ? parseInt(this.props.match.params.id)
      : 0;
    this.pathname = this.props.history.location.pathname;
    this.generalInfoFormRef = React.createRef();
  }

  /**
   *
   */
  componentDidMount() {
    if (
      this.matchParamsId &&
      this.matchParamsId >= 0 &&
      this.state.model.id === 0
    ) {
      this.getInvoiceData();
    } else {
      this.getInvoiceNumber();
    }
  }
  private activateAutoSave() {
    if (this.state.model.id > 0) {
      this.saveInterval = setInterval(() => {
        this.saveInvoice();
      }, Config.autoSaveDocTimeout);
      // this.save(false);
    }
  }

  /**
   * get new number
   */
  private async getInvoiceNumber() {
    const url = UrlEnum.invoices + "/getNumber/" + this.props.type;
    const response = await get(url);
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
      return;
    }
    let invoice = { ...this.state.model };
    invoice.invoiceNo = response;
    this.setState({ model: invoice });
  }
  /**
   * set default values
   */
  private setDefaultData(data: any) {
    const vatTypes = this.cachedDataSingleton.get("vatTypes");

    data.invoiceDate = moment(data.invoiceDate, Config.momentEUDateFormat);
    if (!data.paymentMode) data.paymentMode = PaymentMode.bankTransfer;
    if (!data.series && data.series !== 0)
      data.series = new Date().getFullYear().toString();
    if (data.vatRate === null || data.vatRate === "") {
      if (
        data.client.fk_VATTypeId !== null &&
        data.client.fk_VATTypeId !== ""
      ) {
        const vt = vatTypes.find((v: any) => v.id === data.client.fk_VATTypeId);
        if (vt) {
          data.vatRate = vt.value;
        }
      } else data.vatRate = 21;
    }
    if (data.discount === null || data.discount === "") {
      if (data.client.discount !== "" && data.client.discount !== null)
        data.discount = data.client.discount;
      else data.discount = 0;
    }
    return data;
  }

  /**
   * get invoice data from server
   */
  private getInvoiceData() {
    this.props.setLoading(true);
    const url = UrlEnum.invoices + "/" + this.matchParamsId;
    get(url)
      .then((data) => {
        if (!data || !data.id) {
          alert(lang.get("notFound"));
          this.props.setLoading(false);
          return;
        }
        this.props.setLoading(false);
        // calculate vat for initial services
        for (let s of data.invoice_services) {
          s.vat = parseFloat(
            ((s.totalServicePrice * this.state.model.vatRate) / 100).toFixed(
              Config.noOfDecimals
            )
          );
        }
        data = this.setDefaultData(data);
        this.setState({ model: data });
        this.activateAutoSave();
      })
      .catch(() => {
        this.props.setLoading(false);
      });
  }

  /**
   * change for form inputs
   */
  private handleChange(event: React.FormEvent, model: any) {
    if (!model || !model.hasOwnProperty("id")) {
      model = this.state.model;
    }
    const newModel = handleInputChange(event, model);
    this.setState({ model: newModel });
  }

  /**
   * change invoice date
   */
  private handleDateChange(momentDate: MaterialUiPickersDate) {
    let invoice = { ...this.state.model };
    if (momentDate !== null) {
      invoice.invoiceDate = momentDate;
      this.setState({ model: invoice });
    }
  }

  /**
   * render component
   */
  private handleClientChange(event: any, client: Client, action: string) {
    let newInvoice = Object.assign({}, this.state.model);
    newInvoice.client = client;
    if (!client) {
      this.setState({ model: newInvoice });
      return;
    }
    newInvoice.fk_ClientId = client.id;
    if (client.discount) newInvoice.discount = parseInt(client.discount);
    else newInvoice.discount = 0;
    const vatTypes = this.cachedDataSingleton.get("vatTypes");
    const vt = vatTypes.find(
      (v: any) => v.id === parseInt(client.fk_VATTypeId.toString())
    );
    if (vt) {
      newInvoice.vatRate = vt.value;
    }
    this.setState({ model: newInvoice });
    this.save(true);
  }

  /**
   * change the recurrence interval value
   * @param recurrentInterval
   */
  private handleRecurrentChange(recurrentInterval: number) {
    let newInvoice = Object.assign({}, this.state.model);
    newInvoice.recurrenceInterval = recurrentInterval;
    this.setState({ model: newInvoice });
  }

  /**
   * delete service from invoice
   */
  private handleDeleteService(event: React.MouseEvent) {
    const notSelected = this.state.model.invoice_services.filter(
      (s) => !s.selected
    );
    const newModel = { ...this.state.model };
    newModel.invoice_services = notSelected;
    this.setState({ model: newModel, selectedServices: false });
  }

  /**
   * add new empty service in invoice
   */
  private handleAddService(event: any) {
    let newModel = { ...this.state.model };
    let newService = new DocService();
    let company = this.cachedDataSingleton.get("company");
    newService.serviceCategName = this.state.selectedCateg.name;
    newService.isNew = true;
    let optionTradeMargin = company.company_options.find(
      (element: any) => element.option === CompanyOptionsEnum.TRADEMARGIN
    );
    if (optionTradeMargin) newService.tradeMargin = optionTradeMargin.value;
    newModel.invoice_services.push(newService);

    this.setState({ model: newModel });
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  }

  /**
   * delete selected services
   */
  private handleDocServiceSelectChange(
    event: React.ChangeEvent<HTMLInputElement>,
    docService: DocService
  ) {
    let ds = { ...docService };
    ds.selected = event.currentTarget.checked;
    const index = this.state.model.invoice_services.indexOf(docService);
    if (index < 0) return;

    this.state.model.invoice_services.splice(index, 1, ds);
    const selected = this.state.model.invoice_services.filter(
      (s) => s.selected === true
    );

    this.setState({
      model: { ...this.state.model },
      selectedServices: selected.length !== 0,
    });
  }

  /**
   * return selected services
   */
  private getSelectedServices(): Array<DocService> {
    return this.state.model.invoice_services.filter((s) => s.selected === true);
  }

  /**
   * DocService change
   */
  private handleDocServiceInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    docService: DocService
  ) {
    let val: any = event.currentTarget.value;
    if (
      event.currentTarget.name === "serviceQuantity" ||
      event.currentTarget.name === "servicePrice"
    ) {
      if (val !== "-") {
        val = parseFloat(event.currentTarget.value);
        val = isNaN(val) ? "" : val;
      }
      if (
        event.currentTarget.value[event.currentTarget.value.length - 1] === "."
      ) {
        event.currentTarget.value = val.toString() + ".";
      } else {
        event.currentTarget.value = val.toString();
      }
      if (event.currentTarget.name === "servicePrice") {
        docService.materialsCost = docService.laborCost = docService.tradeMargin = 0;
      }
    }
    const newDs = handleInputChange(event, docService);
    DocService.recalculatePrice(newDs, this.state.model.vatRate);
    const index = this.state.model.invoice_services.indexOf(docService);
    let newModel = { ...this.state.model };
    newModel.invoice_services.splice(index, 1, newDs);
    this.setState({ model: newModel });
  }

  /**
   * add all services in package in selected categ
   * @param event
   * @param pkg
   */
  private async selectPackage(event: React.MouseEvent, pkg: Package) {
    event.preventDefault();
    const response = await get(
      `${UrlEnum.servicePackages}/services/${pkg.id}/${this.state.model.id}/${this.state.model.type}`
    );
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
    }
    for (let s of response) {
      DocService.recalculatePrice(s, this.state.model.vatRate);
    }
    let newInvoice = this.state.model;
    newInvoice.invoice_services = newInvoice.invoice_services.concat(response);
    this.setState({ model: newInvoice, selectServicesOpen: false });
    return false;
  }

  /**
   * select all services
   */
  private selectAll(event: React.MouseEvent<HTMLInputElement>) {
    const invoiceServices: Array<DocService> = [];
    for (let is of this.state.model.invoice_services) {
      let newIs = { ...is };
      newIs.selected = event.currentTarget.checked;
      invoiceServices.push(newIs);
    }
    let m = { ...this.state.model };
    m.invoice_services = invoiceServices;
    this.setState({
      model: m,
      selectedServices:
        event.currentTarget.checked && invoiceServices.length > 0,
    });
  }

  /**
   * select and add a service
   */
  private async selectService(event: React.MouseEvent, serviceInfo: any) {
    event.preventDefault();
    this.props.setLoading(true);
    const response = await get(`${UrlEnum.services}/${serviceInfo.id}`);
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
      this.props.setLoading(false);
      return false;
    }

    this.props.setLoading(false);
    response.serviceQuantity = 1;

    DocService.recalculatePrice(response, this.state.model.vatRate);
    let newModel = this.state.model;

    let docService: DocService = Service.toDocService(response);
    //set category to selected category
    docService.serviceCategName = this.state.selectedCateg.name;
    newModel.invoice_services.push(docService);
    this.setState({ model: newModel });
    return false;
  }

  /**
   * select and add a article
   */
  private selectArticle(event: React.MouseEvent, article: Article): boolean {
    event.preventDefault();
    let invoice = { ...this.state.model };

    let docService: DocService = new DocService();
    docService.serviceName = article.name;
    docService.serviceQuantity = 1;
    docService.servicePrice = article.price;
    docService.totalServicePrice = article.price;
    docService.serviceUm = article.um;
    //set category to selected category
    docService.serviceCategName = this.state.selectedCateg.name;

    invoice.invoice_services.push(docService);
    this.setState({ model: invoice });
    return false;
  }

  private handleChangeOpen(indexService: number) {
    this.setState({ open: !this.state.open, indexService: indexService });
  }

  private async handleSaveService(confirm: boolean) {
    if (!confirm) {
      this.setState({ open: false });
      return;
    }
    await this.save(true);

    const docService = this.state.model.invoice_services[
      this.state.indexService
    ];
    const model = {
      id: docService.id,
      companyId: this.cachedDataSingleton.get("company").id,
    };

    let url = !isNaN(docService.id)
      ? `${UrlEnum.services}/updateFrom/${docService.id}/${model.companyId}`
      : `${UrlEnum.services}/updateFrom/${model.companyId}`;
    let data = await post(url, model);
    if (data.errors) {
      this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      this.setState({ open: false });
      return;
    }
    if (docService.initialServiceId === 0) {
      let newModel = Object.assign(this.state.model);
      newModel.invoice_services.forEach((service: any) => {
        if (service.id === docService.id) service.initialServiceId = data;
      });

      this.setState({ model: newModel });
      this.setState({ loading: true }, () => this.save(true));
    }
    this.setState({ open: false });
  }
  /**
   *
   */
  private servicesByCategs() {
    let categs: any = {};
    let categsServ: any = {};
    let invoiceTotalNoVat = 0;
    for (const service of this.state.model.invoice_services) {
      service.servicePrice = service.servicePrice || 0;
      invoiceTotalNoVat +=
        parseFloat(service.servicePrice.toString()) * service.serviceQuantity;
      DocService.recalculatePrice(service, this.state.model.vatRate);
      const indexService = this.state.model.invoice_services.indexOf(service);
      const serviceView = (
        <EditDocService
          handleSelectChange={this.handleDocServiceSelectChange.bind(this)}
          handleChangeOpen={this.handleChangeOpen.bind(this)}
          handleInputChange={this.handleDocServiceInputChange.bind(this)}
          key={service.id}
          indexService={indexService}
          model={service}
          width={this.props.width}
          vatRate={this.state.model.vatRate}
          disabled={this.state.model.status.id !== 1}
        />
      );
      if (!categs[service.serviceCategName]) {
        categs[service.serviceCategName] = [serviceView];
        categsServ[service.serviceCategName] = [service];
      } else {
        categs[service.serviceCategName].push(serviceView);
        categsServ[service.serviceCategName].push(service);
      }
    }
    // do not set state in render
    let invoice = this.state.model;
    invoice.totalNoVat = invoiceTotalNoVat;
    invoice.totalWithVat = InvoiceModel.getTotals(
      this.state.model
    ).totalWithVat;
    return [categs, categsServ];
  }

  /**
   * close payments and asign new payments to invoice
   * @param payments
   */
  private handlePaymentsClose(payments: any) {
    let invoice = this.state.model;
    invoice.payments = payments;
    this.setState({ paymentsOpen: false, model: invoice });
  }

  /**
   * generate credit note from invoice
   */
  private async generate(path: string) {
    // we assume that model is an invoice
    const selectedServices: Array<DocService> = this.getSelectedServices();
    if (!selectedServices || selectedServices.length === 0) {
      this.props.setOpenMessage(lang.get("pleaseselectServices") + ".");
      return;
    }
    const serviceIds: Array<number> = selectedServices.map((s) => s.id);
    const url = UrlEnum.invoices + "/" + path + "/" + this.state.model.id;
    const response = await post(url, { serviceIds: serviceIds });
    if (response.errors) {
      const errors =
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.setOpenMessage(errors);
      return;
    }

    this.props.history.push(
      (path.toLowerCase().indexOf("proforma") >= 0
        ? LocalUrlEnum.invoices
        : LocalUrlEnum.creditNotes) +
        "/" +
        response
    );
  }

  /**
   * generate new doc options
   */
  private renderGenerateMenuByType() {
    let r = null;
    switch (this.props.type) {
      case DocTypes.invoice:
        r = (
          <ListItem
            button
            onClick={() => this.generate("creditNoteFromInvoice")}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={lang.get("creditNote")} />
          </ListItem>
        );
        break;
      case DocTypes.proforma:
        r = (
          <ListItem button onClick={() => this.generate("fromProforma")}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={lang.get("invoice")} />
          </ListItem>
        );
        break;
      default:
        return null;
    }
    return r;
  }

  /**
   * back button to document type
   */
  private renderBackToDocumentsByType() {
    let r = null;
    switch (this.props.type) {
      case DocTypes.invoice:
        r = (
          <Link to={LocalUrlEnum.invoices}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("invoices")} />
            </ListItem>
          </Link>
        );
        break;
      case DocTypes.proforma:
        r = (
          <Link to={LocalUrlEnum.proformas}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("proformas")} />
            </ListItem>
          </Link>
        );
        break;
      case DocTypes.creditNote:
        r = (
          <Link to={LocalUrlEnum.creditNotes}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("creditNotes")} />
            </ListItem>
          </Link>
        );
        break;
      default:
        return null;
    }
    return r;
  }

  /**
   * render header
   */
  private renderHeader() {
    if (this.props.width === "xs" || this.props.width === "sm") {
      return (
        <Grid container classes={{ root: "docServiceWrapper servicesHeader" }}>
          <Grid item>
            <input
              type="checkbox"
              onClick={this.selectAll.bind(this)}
              style={{ marginRight: 20, color: theme.palette.header?.main }}
            />
          </Grid>
          <Grid item lg={5} md={4} sm={5} xs={8}>
            {lang.get("name")}
          </Grid>
          <Grid item>{lang.get("options")}</Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container classes={{ root: "docServiceWrapper servicesHeader" }}>
          <Grid item>
            <input
              type="checkbox"
              onClick={this.selectAll.bind(this)}
              style={{ marginRight: 20, color: theme.palette.header?.main }}
            />
          </Grid>
          <Grid item lg={5} md={4} sm={3} xs={6}>
            {lang.get("name")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("measureUnit")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("quantity")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("unitPrice")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("value")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("vat")}
          </Grid>
          <Grid item>{lang.get("options")}</Grid>
        </Grid>
      );
    }
  }

  async save(manualSave: boolean) {
    //validate general info
    if (this.generalInfoFormRef) {
      const valid = await this.generalInfoFormRef.current.isFormValid();
      if (!valid) {
        this.generalInfoFormRef.current.submit();
        this.setState({ loading: false });
        return false;
      }
    }
    if (!this.state.model.client || this.state.model.client.id <= 0) {
      this.setState({ loading: false });
      return false;
    }
    // transform invoice to any to change invoiceDate from moment to string
    let data: any = { ...this.state.model } as any;
    data.invoiceDate = moment.isMoment(this.state.model.invoiceDate)
      ? this.state.model.invoiceDate.format(Config.momentUSDateFormat)
      : this.state.model.invoiceDate;

    // make sure data.dicount exists
    data.discount = data.discount ? data.discount : 0;
    data.totalWithVat = data.totalWithVat.toFixed(2);
    const response = await post(
      UrlEnum.invoices + "/" + (this.state.model.id || 0),
      data
    );

    if (response.errors) {
      const errors =
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.setOpenMessage(errors);
      this.setState({ loading: false });
      return;
    }
    let newInvoice: InvoiceModel = { ...this.state.model };

    // update model values with new values from server
    // but ignore  and invoiceDate, which comes in string format not as moment
    const keys = Object.keys(newInvoice);
    for (const key of keys) {
      if (
        response[key] &&
        key !== "client" &&
        key !== "invoiceDate" &&
        key !== "invoice_services"
      ) {
        (newInvoice as any)[key] = response[key];
      }
      if (key === "invoice_services") {
        const services = newInvoice.invoice_services.filter(
          (element) => element.isNew === true
        );
        let index;
        for (let i = 0; i < services.length; i++) {
          index = response.invoice_services.findIndex(
            (element: any) => element.serviceName === services[i].serviceName
          );
          if (index >= 0) response.invoice_services[index].isNew = true;
        }
        (newInvoice as any)[key] = response[key];
      }
    }
    this.setState({ model: newInvoice, loading: false });

    if (manualSave) {
      this.props.showSmallMessage(lang.get("saved"));
    }
    // change url according to type
    let url = LocalUrlEnum.invoices;
    switch (this.props.type) {
      case DocTypes.proforma:
        url = LocalUrlEnum.proformas;
        break;
      case DocTypes.creditNote:
        url = LocalUrlEnum.creditNotes;
        break;
      default:
        url = LocalUrlEnum.invoices;
    }

    if (!parseFloat(this.props.match?.params.id)) {
      this.props.history.push(url + "/" + newInvoice.id);
    }

    // enable autosave
    if (!this.saveInterval && this.state.model.status.id === 1) {
      this.saveInterval = setInterval(() => {
        this.saveInvoice();
      }, Config.autoSaveDocTimeout);
    }
  }
  /**
   * submit and save invoice
   */
  saveInvoice() {
    if (this.state.loading) return;

    let manualSave: boolean = false;
    if (arguments && arguments[0]) {
      manualSave = true;
    }
    this.setState({ loading: true }, () => this.save(manualSave));
  }

  /**
   * save invoice before leaving page
   */
  async saveInvoiceMin() {
    if (!this.state.model.id) return;

    let data: any = { ...this.state.model } as any;
    data.invoiceDate = moment.isMoment(this.state.model.invoiceDate)
      ? this.state.model.invoiceDate.format(Config.momentUSDateFormat)
      : this.state.model.invoiceDate;

    post(UrlEnum.invoices + "/" + (this.state.model.id || 0), data);
  }

  /**
   *
   */
  componentWillMount() {
    this.locationListenerUnlisten = this.props.history.listen(
      (location: any, action: any) => {
        if (
          this.state.model.status.id === 1 &&
          location.pathname !== this.pathname
        ) {
          this.saveInvoiceMin();
          clearInterval(this.saveInterval);
          this.locationListenerUnlisten();
        }
      }
    );
  }

  /**
   * change status
   */
  async changeInvoiceState() {
    if (this.state.model.status !== null) {
      let nextState = this.state.model.status;
      if (this.state.model.status.id === Statuses.Active.id) {
        nextState = Statuses.Inactive;
      } else {
        nextState = Statuses.Active;
      }

      const url = `${UrlEnum.invoices}/changeState/${this.state.model.id}/${nextState.id}`;
      const response = await get(url);
      if (response.errors) {
        alert(response.errors);
        return;
      }
      let newEstimate = { ...this.state.model };
      newEstimate.status = nextState;
      this.setState({ model: newEstimate });
    }
  }

  /**
   * returns the receipt of an invoice
   */
  hasReceipt() {
    const receipt = this.state.model.payments
      .reverse()
      .find((p) => p.receiptNo);
    return receipt ? (
      <Receipt
        number={receipt.receiptNo}
        date={receipt.paymentDate}
        total={receipt.amount}
      />
    ) : null;
  }

  showTVA() {
    const company = this.cachedDataSingleton.get("company");
    if (company.address.fk_countryId === 21) {
      const workInConst = company.company_options.find(
        (element: any) =>
          element.option === CompanyOptionsEnum.WORKINCONSTRUCTION
      );

      if (this.state.model.vatRate === 0) {
        if (workInConst) {
          if (!!parseInt(workInConst.value)) {
            return (
              <div style={{ paddingTop: "20px", width: "100%" }}>
                Autoliquidation: En l’absence de contestation par écrit, dans un
                délai d’un mois à compter de la réception de la facture, le
                client est présumé reconnaître qu’il est un assujetti tenu au
                dépôt de déclarations périodiques. Si cette condition n’est pas
                remplie, le client endossera, par rapport à cette condition, la
                responsabilité quant au paiement de la taxe, des intérêts et des
                amendes dus.
              </div>
            );
          }
        }
      } else if (this.state.model.vatRate === 6) {
        if (workInConst) {
          if (!!parseInt(workInConst.value)) {
            return (
              <div
                style={{
                  paddingTop: "20px",
                  width: "100%",
                  textAlign: "justify",
                }}
              >
                <p>
                  Taux de TVA : En l’absence de contestation par écrit, dans un
                  délai d’un mois à compter de la réception de la facture, le
                  client est présumé reconnaître que
                </p>
                <p>
                  (1) les travaux sont effectués à un bâtiment d’habitation dont
                  la première occupation a eu lieu au cours d’une année civile
                  qui précède d’au moins [dix ou quinze ans] la date de la
                  première facture relative à ces travaux,
                </p>
                <p>
                  (2) qu’après l’exécution de ces travaux, l’habitation est
                  utilisée, soit exclusivement soit à titre principal comme
                  logement privé et
                </p>
                <p>
                  (3) que ces travaux sont fournis et facturés à un consommateur
                  final. Si au moins une de ces conditions n’est pas remplie, le
                  taux normal de TVA de 21 p.c. sera applicable et le client
                  endossera, par rapport à ces conditions, la responsabilité
                  quant au paiement de la taxe, des intérêts et des amendes dus.
                </p>
              </div>
            );
          }
        }
      }
    }
    return "";
  }

  handleDiscountBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.value) return;
    let newModel = { ...this.state.model };
    newModel.discount = 0;
    this.setState({ model: newModel });
  }

  /**
   * render categ select
   */
  renderCategoriesSelect(classes: any) {
    return (
      <div style={{ padding: 10 }}>
        <label>{lang.get("categories")}</label>
        <Button
          classes={{ root: classes.hierarchicalListBtn }}
          color="inherit"
          onClick={(event: React.MouseEvent) =>
            this.setState({ serviceCategsAnchor: event.currentTarget })
          }
        >
          {this.state.selectedCateg.name}
          <ArrowDropDownIcon fontSize="small" />
        </Button>
        <Menu
          className="serviceCategs"
          id="serviceCategsMenu"
          anchorEl={this.state.serviceCategsAnchor}
          keepMounted={true}
          open={Boolean(this.state.serviceCategsAnchor)}
          onClose={() => this.setState({ serviceCategsAnchor: null })}
        >
          <div>
            <HierarchicalList
              models={this.serviceCategories}
              childrenLabel="children_categ"
              foreignKey="fk_ParentCategId"
              onClick={(event: React.MouseEvent, model: any) =>
                this.setState({
                  selectedCateg: model,
                  serviceCategsAnchor: null,
                })
              }
            />
          </div>
        </Menu>
      </div>
    );
  }
  firstSaved() {
    if (this.state.model.invoice_services.length === 0) {
      this.handleAddService(this);
    }
  }
  /**
   *
   */
  renderProjectLink() {
    if (!this.state.model.folder) return null;
    return (
      <small>
        <Link
          className="link"
          to={LocalUrlEnum.projects + "/" + this.state.model.folder?.id}
        >
          {lang.get("folder")}: {this.state.model.folder?.folderNo}
        </Link>
      </small>
    );
  }
  renderModal() {
    return (
      <Dialog open={this.state.open}>
        <h1>Hello</h1>
      </Dialog>
    );
  }
  /**
   *
   */
  render() {
    // if we have params id and no model id, invoice is not fetched from the server
    if (this.matchParamsId > 0 && this.state.model.id === 0) return "";

    // const width = this.useWidth();
    const [categs, categsServ] = this.servicesByCategs();
    const { classes } = this.props;
    return (
      <DrawerLayout
        open={this.state.drawerOpen}
        drawerWidth={!isMobileView ? 300 : "100%"}
        handleDrawerClose={() =>
          this.setState({ drawerOpen: !this.state.drawerOpen })
        }
        drawerChildren={
          <Fragment>
            <List>
              {this.renderBackToDocumentsByType()}
              <Divider />
              <ListItem
                button
                disabled={!this.state.model.id}
                onClick={(event: React.MouseEvent) =>
                  this.setState({ previewOpen: true })
                }
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("previewSend")} />
              </ListItem>
              {this.state.previewOpen ? (
                <DashboardContext.Consumer>
                  {(context) => (
                    <PreviewDocument
                      open={this.state.previewOpen}
                      onClose={(emailSent) => {
                        this.setState({ previewOpen: false });
                        if (emailSent) {
                          this.changeInvoiceState();
                        }
                      }}
                      type={this.state.model.type}
                      currency={this.state.model.currency}
                      services={[categsServ]}
                      setOpenMessage={context.setOpenMessage}
                      setSmallMessage={context.setSmallMessage}
                      totals={[InvoiceModel.getTotals(this.state.model)]}
                      id={this.state.model.id}
                      docData={[
                        {
                          series: this.state.model.series.toString(),
                          no: this.state.model.invoiceNo,
                          date: moment.isMoment(this.state.model.invoiceDate)
                            ? this.state.model.invoiceDate.format(
                                Config.momentEUDateFormat
                              )
                            : moment(this.state.model.invoiceDate).format(
                                Config.momentEUDateFormat
                              ),
                          vatRate: this.state.model.vatRate,
                          paymentMode: this.state.model.paymentMode,
                          paymentDate: this.state.model.paymentDue.toString(),
                          client: this.state.model.client,
                          folder: this.state.model.folder,
                        },
                      ]}
                      children={[this.hasReceipt()]}
                      showTVA={this.showTVA()}
                    />
                  )}
                </DashboardContext.Consumer>
              ) : (
                ""
              )}
              <Divider />

              {this.props.type !== DocTypes.proforma ? (
                <Fragment>
                  <ListItem
                    button
                    onClick={(event: React.MouseEvent) =>
                      this.setState({ paymentsOpen: true })
                    }
                  >
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary={lang.get("payments")} />
                  </ListItem>
                  {this.state.paymentsOpen ? (
                    <InvoicePayments
                      key={this.state.model.id}
                      open={this.state.paymentsOpen}
                      onClose={this.handlePaymentsClose.bind(this)}
                      invoiceId={this.state.model.id}
                      payments={this.state.model.payments}
                      invoiceAmount={
                        +this.state.model.totalWithVat.toFixed(
                          Config.noOfDecimals
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                </Fragment>
              ) : null}

              <Divider />
              <ListItem>
                <ListItemText primary={lang.get("generate")} />
              </ListItem>
              {this.renderGenerateMenuByType()}
              <ListItem>
                <DocStatus
                  size="medium"
                  className={classes.statusBtn}
                  status={this.state.model.status}
                  onChangeStatus={this.changeInvoiceState.bind(this)}
                />
              </ListItem>
            </List>
          </Fragment>
        }
        menu={
          <>
            {!this.state.selectedServices ? (
              <>
                <Button
                  disabled={
                    this.state.model.status.id !== 1 ||
                    this.state.model.id === 0
                  }
                  color="inherit"
                  onClick={(event: React.MouseEvent) =>
                    this.setState({ selectServicesOpen: true })
                  }
                  classes={{ label: classes.buttonLabel }}
                >
                  <MoveToInboxIcon />
                  {lang.get("selectServices")}
                </Button>

                {this.state.selectServicesOpen ? (
                  <SelectServices
                    open={this.state.selectServicesOpen}
                    onClose={() => this.setState({ selectServicesOpen: false })}
                    onSelectPackage={this.selectPackage.bind(this)}
                    onSelectService={this.selectService.bind(this)}
                    onSelectArticle={this.selectArticle.bind(this)}
                  />
                ) : null}

                <Button
                  disabled={
                    this.state.model.status.id !== 1 ||
                    this.state.model.id === 0
                  }
                  color="inherit"
                  onClick={this.handleAddService.bind(this)}
                  classes={{ label: classes.buttonLabel }}
                >
                  <AddIcon />
                  {lang.get("new") + " " + lang.get("service")}
                </Button>
                {this.renderCategoriesSelect(classes)}

                <Typography style={{ flexGrow: 1 }}></Typography>
                {this.state.loading ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                ) : null}
              </>
            ) : (
              <>
                <Button
                  disabled={
                    this.state.model.status.id !== 1 ||
                    this.state.model.id === 0
                  }
                  color="inherit"
                  onClick={this.handleDeleteService.bind(this)}
                  classes={{ label: classes.buttonLabel }}
                >
                  <DeleteIcon /> &nbsp; {lang.get("delete")}
                </Button>
                <Typography style={{ flexGrow: 1 }}></Typography>
              </>
            )}

            <Button
              disabled={this.state.model.status.id !== 1 || this.state.loading}
              className={classes.saveBtn}
              style={{
                color: theme.palette.textColorSecondary?.main,
                background: theme.palette.successColor?.main,
              }}
              size="large"
              variant="contained"
              onClick={this.saveInvoice.bind(this)}
            >
              {lang.get("save")}
            </Button>
          </>
        }
      >
        <Fragment>
          <h2>
            {lang.get(this.props.type)} {this.renderProjectLink()}
          </h2>
          <div>
            <ValidatorForm
              ref={this.generalInfoFormRef}
              instantValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <InvoiceGeneralInfo
                key={this.state.model.id}
                invoice={this.state.model}
                onChange={this.handleChange.bind(this)}
                onDateChange={this.handleDateChange.bind(this)}
                onClientChange={this.handleClientChange.bind(this)}
                onRecurrentChange={this.handleRecurrentChange.bind(this)}
                onDiscountBlur={this.handleDiscountBlur.bind(this)}
              ></InvoiceGeneralInfo>
            </ValidatorForm>
            {this.state.model.id > 0 ? (
              <div>
                <Paper style={{ marginTop: 30 }}>
                  {this.renderCategoriesSelect(classes)}
                  {this.renderHeader()}
                  {Object.keys(categs).map((categName, index) => (
                    <Fragment key={index}>
                      <h3>
                        {index + 1}. {categName}
                      </h3>
                      {categs[categName].length > 1 ? (
                        categs[categName].map((value: any, key: any) => {
                          return (
                            <div>
                              <p
                                style={{
                                  position: "absolute",
                                  marginTop: 15,
                                  marginLeft: 10,
                                }}
                              >
                                {index + 1}.{key + 1}{" "}
                              </p>
                              {value}
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          {" "}
                          <p
                            style={{
                              position: "absolute",
                              marginTop: 15,
                              marginLeft: 10,
                            }}
                          >
                            {index + 1}.1{" "}
                          </p>
                          {categs[categName]}
                        </div>
                      )}
                    </Fragment>
                  ))}
                  {this.firstSaved()}
                </Paper>
                <DocTotals doc={this.state.model} />
              </div>
            ) : (
              ""
            )}
          </div>
        </Fragment>
        {this.state.open ? (
          <ConfirmDialog
            open={this.state.open}
            text={lang.get("saveService")}
            onResult={(result) => this.handleSaveService(result)}
          />
        ) : (
          ""
        )}
      </DrawerLayout>
    );
  }
}

export default withWidth()(withStyles(styles, { withTheme: true })(Invoice));
