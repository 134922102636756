import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../Components/Header";
import { Switch, Route, useHistory } from "react-router-dom";
import Projects from "./Documents/Projects";
import Invoices from "./Documents/Invoices";
import Invoice from "../DocumentComponents/Invoice";
import Project from "../DocumentComponents/Project";
import Estimates from "./Documents/Estimates";
import Estimate from "../DocumentComponents/Estimate";
import CompanyInfo from "../Pages/Options/CompanyInfo";
import Clients from "./Clients";
import Employees from "./Employees";
import Subcontractors from "./Subcontractors";
import Overview from "./Overview";
import Services from "./Services";
import {
  get,
  UrlEnum,
  LocalUrlEnum,
  isLoggedIn,
  StatusEnum,
  UserRolesEnum,
  readCookie,
} from "../Utils/Utils";
import CachedDataSingleton from "../cachedDataSingleton";
import { CircularProgress, Drawer, makeStyles, Paper } from "@material-ui/core";
import AccountTabs from "./AccountOptions/AccountTabs";
import Lang from "../lang";
import Articles from "./Articles";
import DocumentTemplates from "./DocumentTemplates";
import CompanyUsers from "./CompanyUsers";
import { isMobile, DocTypes } from "../Utils/Utils";
import Costs from "../DocumentComponents/Costs";
import MessageDialog from "../Components/MessageDialog";
import DashboardContext from "../Contexts/DashboardContext";
import Slide from "@material-ui/core/Slide";
import TimeTrackingComponent from "../Pages/TimeTrackingComponent";
import DocumentsReport from "./Reports/Documents";
import LiabilityReportTable from "./Liability/Table";
import { checkUserRoles } from "../Utils/CheckUserRoles";
import { menuOptionFilteredByRole } from "../Utils/MenuOptionsFilteredByRole";
import { CACHED_DATA_TAG } from "../Utils/Constants";
import PageNotFound from "../Components/PageNotFound";
import MenuList from "../Components/DashboardComponents/MenuList";
import clsx from "clsx";
import scriptLoader from "react-async-script-loader";
import theme from "../Theme/Theme";
import CompanyOverview from "../DocumentComponents/CompanyOverview";
// import DocumentsTables from "../Components/FlexibleDashboard/DocumentsTables";
import CompanyOptions from "./AccountOptions/CompanyOptions";
import { CompanyOptionsEnum } from "../Utils/Utils";
import Trial from "./Trial";
import BudgetEstimate from "../DocumentComponents/BudgetEstimate.tsx";
import NotificationModal from "./NotificationModal";

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: isMobile() ? 95 : 75,
    height: "92%",
  },
  loadingWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1500,
    background: "rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
  },
  smallMessage: {
    position: "fixed",
    top: 70,
    right: 10,
    zIndex: 2000,
    background: theme.palette.errorColor?.main,
    color: theme.palette.textColorSecondary?.main,
    minWidth: 260,
    padding: "10px 15px 10px 15px",
  },
  success: {
    background: theme.palette.successColor?.main,
  },
  error: {
    background: theme.palette.errorColor?.main,
  },
  warning: {
    background: theme.palette.warning.dark,
  },
  info: {
    background: theme.palette.info.dark,
  },
  drawerPaper: {
    background: theme.palette.dashboard?.main,
    zIndex: 15,
    marginTop: "64px",
    height: "calc(100% - 64px)",
    // height:"100%",
    width: 340,
    paddingTop: 10,
    color: theme.palette.text,
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      height: "calc(100% - 50px)",
    },
  },

  drawerRoot: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "1299 !important",
    },
  },

  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    position: "absolute",
    height: "100%",
    width: "100%",
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 340,
    width: "calc(100% - 340px)",
  },
}));

function Dashboard(props) {
  const history = useHistory();
  const classes = useStyles();
  const smallMessageTimeout = 5000;

  const [acceptNotification, setAcceptNotification] = useState(false);
  const [hasCache, setHasCache] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(null);
  const [smallMessage, setSmallMessage] = useState(null);
  const [openMenu, setOpenMenu] = useState(isMobile() ? false : true);
  const menuOptions = menuOptionFilteredByRole();

  const changeLoadingState = (val) => {
    setLoading(val);
  };

  const openMessageDialog = (val) => {
    setOpenMessage(val);
  };

  const showSmallMessage = (message, status = StatusEnum.SUCCESS) => {
    setSmallMessage({ message: message, status: status });
    // hide after timeout
    setTimeout(() => {
      setSmallMessage(null);
    }, smallMessageTimeout);
  };

  const loggedInButNoCompany = () => {
    try {
      let userData = readCookie("userData");
      userData = JSON.parse(userData);
      if (!userData || !userData.fk_companyid) {
        return true;
      }
      return false;
    } catch (e) {}
  };

  useEffect(() => {
    window.addEventListener("focus", () => {
      if (!isLoggedIn()) document.location.href = LocalUrlEnum.login;
    });
  }, []);

  // check if we need to bring cached data
  /**
   * get cached data and save to local storage.
   * if cache exists, don't bring again for a period of time
   */
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
      console.log();
    } else {
      if (Notification.permission !== "granted") {
        let notificationCookie = readCookie("accept_notification");
        if (notificationCookie === "false") {
          setAcceptNotification(true);
          document.cookie = "accept_notification =;";
        } else if (notificationCookie === "true") {
          Notification.requestPermission().then((permission) => {
            console.log(permission);
          });
        }
      }
    }
    if (!hasCache) {
      let storage = window.localStorage;
      const cachedData = JSON.parse(storage.getItem(CACHED_DATA_TAG));

      const oldHash = cachedData ? cachedData.hash : "";
      const url = oldHash
        ? UrlEnum.cachedData.replace("{oldHash}", oldHash)
        : UrlEnum.cachedData.replace("/{oldHash}", "");
      get(
        url,
        {
          "Content-Type": "application/json",
          Accept: "*/json",
          cookie: document.cookie,
        },
        history
      ).then((data) => {
        if (!data || data.errors) {
          setSmallMessage(`${lang.get("companyInfo")} - ${lang.get("error")}`);
          history.push(LocalUrlEnum.login);
          return;
        }

        // if we have new data store it in local storage
        if (data instanceof Object && data.hash) {
          storage.setItem(CACHED_DATA_TAG, JSON.stringify(data));
        } else {
          data = cachedData;
        }
        let cds = CachedDataSingleton.getInstance();
        cds.set(data);
        setHasCache(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserLoggedIn = isLoggedIn();
  if (!isUserLoggedIn) {
    document.cookie = "";
    window.localStorage.removeItem(CACHED_DATA_TAG);
    window.location.href = LocalUrlEnum.login;
    return null;
  }

  // return if logged in user has no company
  if (loggedInButNoCompany()) {
    window.location.href = LocalUrlEnum.wizard;
    return null;
  }

  if (!hasCache) {
    return (
      <div className={classes.root} align="center">
        <CircularProgress color="secondary" />
      </div>
    );
  }
  function hasCompanyOptions() {
    const cachedData = CachedDataSingleton.getInstance();
    const company = cachedData.get("company");
    if (company.company_options.length === 0) return false;
    else {
      let optionTradeMargin = company.company_options.find(
        (element) => element.option === CompanyOptionsEnum.TRADEMARGIN
      );
      if (!optionTradeMargin) return false;
    }
    return true;
  }
  const render = (
    <div>
      <CssBaseline />
      <Drawer
        variant={isMobile() ? "temporary" : "persistent"}
        anchor="left"
        open={openMenu}
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper,
        }}
      >
        <MenuList
          isMobile={isMobile()}
          style={{ color: theme.palette.textColor?.main }}
          menuButtonClick={() => {
            setOpenMenu(!openMenu);
          }}
        />
      </Drawer>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: openMenu,
        })}
      >
        <Header
          menuOptions={menuOptions}
          menuButtonClick={() => {
            setOpenMenu(!openMenu);
          }}
        />
        {hasCompanyOptions() ? "" : <CompanyOptions />}
        <div className={classes.body}>
          <DashboardContext.Provider
            value={{
              setLoading: (val) => {
                changeLoadingState(val);
              },
              setOpenMessage: (message) => {
                openMessageDialog(message);
              },
              setSmallMessage: (message, status = StatusEnum.SUCCESS) => {
                showSmallMessage(message, status);
              },
            }}
          >
            <Switch>
              <Route exact path="/">
                <Overview
                  showSmallMessage={showSmallMessage}
                  setLoading={changeLoadingState}
                />

                {checkUserRoles([
                  UserRolesEnum.ADMIN,
                  UserRolesEnum.ESTIMATES,
                ]) ? (
                  // <DocumentsTables userRoles={UserRolesEnum.ESTIMATES} showSmallMessage={showSmallMessage} />
                  <div></div>
                ) : null}
                {checkUserRoles([
                  UserRolesEnum.ADMIN,
                  UserRolesEnum.INVOICING,
                  UserRolesEnum.ACCOUNTING,
                ]) ? (
                  // <DocumentsTables userRoles={UserRolesEnum.INVOICING} showSmallMessage={showSmallMessage} />
                  <div></div>
                ) : null}
                {checkUserRoles([
                  UserRolesEnum.ADMIN,
                  UserRolesEnum.INVOICING,
                  UserRolesEnum.ACCOUNTING,
                  UserRolesEnum.ESTIMATES,
                ]) ? (
                  // <DocumentsTables
                  //   userRoles={UserRolesEnum.ADMIN}
                  //   showSmallMessage={showSmallMessage}
                  // />
                  <div></div>
                ) : null}
              </Route>

              {/** projects */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.projects + "/:id"}
                  render={(props) => (
                    <Project
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.folder}
                    />
                  )}
                />
              ) : null}

              {/** invoices */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.invoices + "/:id(\\d+)"}
                  render={(props) => (
                    <Invoice
                      key={"invoice" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.invoice}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.invoices +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Invoices
                      key={"invoice" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.invoice}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.invoices}
                  render={(props) => (
                    <Invoices
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.invoice}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.invoices + LocalUrlEnum.liability}
                  render={(props) => (
                    <LiabilityReportTable
                      key={"liability" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={"liability"}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.invoices + LocalUrlEnum.report}
                  render={(props) => (
                    <DocumentsReport
                      key={"invoicesReport"}
                      type={DocTypes.invoice}
                      menuOptions={menuOptions}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.estimates + LocalUrlEnum.report}
                  render={(props) => (
                    <DocumentsReport
                      key={"estimatesReport"}
                      type={DocTypes.estimate}
                      menuOptions={menuOptions}
                    />
                  )}
                />
              ) : null}

              {/** proformas */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.proformas + "/:id(\\d+)"}
                  render={(props) => (
                    <Invoice
                      key={"proforma" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.proforma}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.proformas +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Invoices
                      key={"proforma" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.proforma}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.proformas}
                  render={(props) => (
                    <Invoices
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type="proforma"
                    />
                  )}
                />
              ) : null}

              {/* PROJECT */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.projects +
                    "/:startDate/:endDate/:page/:perPage/:q?/:blockedStatus"
                  }
                  render={(props) => (
                    <Projects
                      key={"projects" + history.location.pathname}
                      type={DocTypes.folder}
                      history={history}
                      match={props.match}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.projects}
                  render={(props) => (
                    <Projects
                      type={DocTypes.folder}
                      history={history}
                      match={props.match}
                    />
                  )}
                />
              ) : null}

              {/** invoices */}

              {/*<Route
              exact
              path={LocalUrlEnum.invoices + "/:id(\\d+)"}
              render={props => (
                <Invoice
                  key={"invoice" + history.location.pathname}
                  showSmallMessage={showSmallMessage}
                  setOpenMessage={openMessageDialog}
                  setLoading={changeLoadingState}
                  match={props.match}
                  history={history}
                  type={DocTypes.invoice}
                />
              )}
            />

            <Route
              exact
              path={
                LocalUrlEnum.invoices +
                "/:startDate/:endDate/:page/:perPage/:q?"
              }
              render={props => (
                <Invoices
                  key={"invoice" + history.location.pathname}
                  showSmallMessage={showSmallMessage}
                  changeLoadingState
                  match={props.match}
                  history={history}
                  type={DocTypes.invoice}
                />
              )}
            />

            <Route
              exact
              path={LocalUrlEnum.invoices}
              render={props => (
                <Invoices
                  showSmallMessage={showSmallMessage}
                  changeLoadingState
                  match={props.match}
                  history={history}
                  type={DocTypes.invoice}
                />
              )}
            />

            <Route
              exact
              path={LocalUrlEnum.invoices + "/report"}
              render={props => <DocumentsReport type={DocTypes.invoice} />}
              />*/}

              {/** proformas */}

              {/*<Route
              exact
              path={LocalUrlEnum.proformas + "/:id(\\d+)"}
              render={props => (
                <Invoice
                  key={"proforma" + history.location.pathname}
                  showSmallMessage={showSmallMessage}
                  setOpenMessage={openMessageDialog}
                  setLoading={changeLoadingState}
                  match={props.match}
                  history={history}
                  type={DocTypes.proforma}
                />
              )}
            />

            <Route
              exact
              path={
                LocalUrlEnum.proformas +
                "/:startDate/:endDate/:page/:perPage/:q?"
              }
              render={props => (
                <Invoices
                  key={"proforma" + history.location.pathname}
                  showSmallMessage={showSmallMessage}
                  changeLoadingState
                  match={props.match}
                  history={history}
                  type={DocTypes.proforma}
                />
              )}
            />

            <Route
              exact
              path={LocalUrlEnum.proformas}
              render={props => (
                <Invoices
                  showSmallMessage={showSmallMessage}
                  changeLoadingState
                  match={props.match}
                  history={history}
                  type="proforma"
                />
              )}
              />*/}

              {/** credit notes */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.creditNotes + "/:id(\\d+)"}
                  render={(props) => (
                    <Invoice
                      key={"creditnote" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.creditNote}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.creditNotes +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Invoices
                      key={"creditnote" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.creditNote}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.creditNotes}
                  render={(props) => (
                    <Invoices
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type="creditNote"
                    />
                  )}
                />
              ) : null}

              {/** estimates */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.estimates + "/:id(\\d+)"}
                  render={(props) => (
                    <Estimate
                      key={"estimate" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.estimate}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  path={
                    LocalUrlEnum.estimates +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Estimates
                      key={"estimate" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.estimate}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.estimates}
                  render={(props) => (
                    <Estimates
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.estimate}
                    />
                  )}
                />
              ) : null}

              {/** order tickets */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.orderTickets + "/:id(\\d+)"}
                  render={(props) => (
                    <Estimate
                      key={"orderticket" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.orderTicket}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.orderTickets +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Estimates
                      key={"orderticket" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.orderTicket}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.orderTickets}
                  render={(props) => (
                    <Estimates
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.orderTicket}
                    />
                  )}
                />
              ) : null}

              {/** subcontractor order tickets */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.subcontractorordertickets + "/:id(\\d+)"}
                  render={(props) => (
                    <Estimate
                      key={
                        "subcontractororderticket" + history.location.pathname
                      }
                      showSmallMessage={showSmallMessage}
                      setOpenMessage={openMessageDialog}
                      setLoading={changeLoadingState}
                      match={props.match}
                      history={history}
                      type={DocTypes.subcontractorOrderTicket}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.subcontractorordertickets +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  render={(props) => (
                    <Estimates
                      key={
                        "subcontractororderticket" + history.location.pathname
                      }
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.subcontractorOrderTicket}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.ESTIMATES,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.subcontractorordertickets}
                  render={(props) => (
                    <Estimates
                      showSmallMessage={showSmallMessage}
                      changeLoadingState
                      match={props.match}
                      history={history}
                      type={DocTypes.subcontractorOrderTicket}
                    />
                  )}
                />
              ) : null}

              {/** clients */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.clients + "/:id?"}>
                  <Clients showSmallMessage={showSmallMessage} />
                </Route>
              ) : null}

              {/** employees */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.SUBCONTRACTORS,
              ]) ? (
                <Route path={LocalUrlEnum.employees + "/:id?"}>
                  <Employees />
                </Route>
              ) : null}

              {/** subcontractors */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.SUBCONTRACTORS,
              ]) ? (
                <Route path={LocalUrlEnum.subcontractors + "/:id?"}>
                  <Subcontractors showSmallMessage={showSmallMessage} />
                </Route>
              ) : null}

              {/** services */}

              <Route path={LocalUrlEnum.services}>
                <Services />
              </Route>

              {/** company info */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.companyInfo}>
                  <CompanyInfo />
                </Route>
              ) : null}

              {/** articles */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.articles}>
                  <Articles />
                </Route>
              ) : null}

              {/** company overview */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.companyoverview}>
                  <CompanyOverview />
                </Route>
              ) : null}

              {/** options */}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.options}>
                  <AccountTabs></AccountTabs>
                </Route>
              ) : null}

              {/** templates */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.INVOICING,
                UserRolesEnum.ESTIMATES,
                UserRolesEnum.SUBCONTRACTORS,
                UserRolesEnum.TIMEKEEPING,
                UserRolesEnum.ACCOUNTING,
              ]) ? (
                <Route path={LocalUrlEnum.documentTemplates}>
                  <DocumentTemplates />
                </Route>
              ) : null}

              {/** Company Users */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.COMPANYUSERS,
              ]) ? (
                <Route path={LocalUrlEnum.users}>
                  <CompanyUsers
                    showSmallMessage={showSmallMessage}
                    setOpenMessage={openMessageDialog}
                  />
                </Route>
              ) : null}
              {/** budget estimate */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.COSTMANAGEMENT,
              ]) ? (
                <Route
                  exact
                  path={`${LocalUrlEnum.budgetEstimate}/:year?`}
                  component={(props) => (
                    <BudgetEstimate
                      key={`costs/${history.location.pathname}`}
                      showSmallMessage={showSmallMessage}
                      menuOptions={menuOptions}
                    />
                  )}
                />
              ) : null}
              {/** costs */}
              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.COSTMANAGEMENT,
              ]) ? (
                <Route
                  exact
                  path={
                    LocalUrlEnum.costs +
                    "/:startDate/:endDate/:page/:perPage/:q?"
                  }
                  component={(props) => (
                    <Costs
                      key={"costs" + history.location.pathname}
                      showSmallMessage={showSmallMessage}
                      history={history}
                      match={props.match}
                      openMenu={openMenu}
                    />
                  )}
                />
              ) : null}

              {checkUserRoles([
                UserRolesEnum.ADMIN,
                UserRolesEnum.COSTMANAGEMENT,
              ]) ? (
                <Route
                  exact
                  path={LocalUrlEnum.costs}
                  component={(props) => (
                    <Costs
                      showSmallMessage={showSmallMessage}
                      history={history}
                      match={props.match}
                      openMenu={openMenu}
                    />
                  )}
                />
              ) : null}

              {/** time tracking */}

              <Route path={LocalUrlEnum.timeTracking}>
                <TimeTrackingComponent
                  showSmallMessage={showSmallMessage}
                  onChangeLoading={(value) => setLoading(value)}
                  history={history}
                />
              </Route>

              {/** not found */}

              <Route path="*">
                <h1>
                  <PageNotFound />
                </h1>
              </Route>
              {/* is trial */}
              <Route exact path={"gp/:internalClientId"} />
            </Switch>
          </DashboardContext.Provider>
        </div>
      </div>
      {loading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ""
      )}
      <MessageDialog
        open={openMessage ? true : false}
        text={openMessage || ""}
        onResult={() => setOpenMessage(null)}
      />
      <Slide direction="left" in={smallMessage !== null}>
        <Paper
          className={`${classes.smallMessage} ${classes[smallMessage?.status]}`}
        >
          <p>{smallMessage?.message || ""}</p>
        </Paper>
      </Slide>

      <Trial isMobile={isMobile()} />
      <NotificationModal
        open={acceptNotification}
        handleClose={() => setAcceptNotification(false)}
      />
    </div>
  );
  return render;
}
export default scriptLoader(
  "https://maps.google.com/maps/api/js?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&language=en"
)(Dashboard);
