import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm } from "react-material-ui-form-validator"; //TextValidator
import PropTypes from "prop-types";
import { Button, Dialog, useMediaQuery } from "@material-ui/core";

function NotificationModal({ handleClose, open }) {
  const matches = useMediaQuery("(max-width:600px)");

  function handleSubmit() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
      // console.log();
    } else {
      document.cookie = "accept_notification = true;";
      Notification.requestPermission().then((permission) => {
        console.log(permission);
      });
    }
    handleClose();
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ sx: { maxWidth: "unset" } }}
    >
      <p style={{ cursor: "pointer", position: "absolute", top: 8, right: 20 }}>
        <CloseIcon onClick={handleClose} />{" "}
      </p>
      <ValidatorForm onSubmit={handleSubmit} style={{ padding: 10 }}>
        <div style={{ textAlign: "center" }}>
          <p style={{ margin: 20, fontSize: 20 }}>
            Allow browser notifications?
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
        <div
          style={
            matches
              ? { margin: 10 }
              : { justifyContent: "center", display: "flex", margin: 10 }
          }
        >
          <Button
            style={
              matches
                ? { backgroundColor: "red", color: "white", marginTop: 10 }
                : { color: "white", margin: 10, width: "30%" }
            }
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleClose}
            fullWidth
          >
            Block
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            style={
              matches
                ? { color: "white", marginTop: 10 }
                : { color: "white", margin: 10, width: "30%" }
            }
            fullWidth
          >
            Allow
          </Button>
        </div>
      </ValidatorForm>
    </Dialog>
  );
}

NotificationModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
export default NotificationModal;
