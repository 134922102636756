import React from "react";
import {
  Grid,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Lang from "../../lang";
// import { post } from "../../Utils/Utils";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CachedDataSingleton from "../../cachedDataSingleton";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

const useStyles = ((theme) =>
  Object.assign({
    textField: {
      margin: "15px",
    },
  })
);
class StripePersonalAddress extends React.Component{

  constructor(props)
  {
    super(props);
    this.state={
      model: props.stripeAccountModel,
    countries:cachedData.get("countries"),
    }
  }
  formRef = React.createRef();

   handleInputChange(event) {
    const currModel=this.state.model;
    let name = event.currentTarget.name;
    let value = event.currentTarget.value;
    currModel[name]= value;
    this.setState({model:currModel});
  }
  handleSelectCountries(event)
  {
    const currModel=this.state.model;
    let value = event.target.value;
    currModel.country= value;
    this.setState({model:currModel});
  }
  validate () {
    let form = this.formRef.current;
    return form.isFormValid(false);
  }

   renderModalComponent() {
    const classes =this.props.classes;
    return (
      <ValidatorForm ref={this.formRef} instantValidate={false} onSubmit={() => {}}>
        <Grid style={{ width: "90%", margin: "0 auto", padding: "20px" }}>
          <FormControl fullWidth className={classes.textField} style={{marginTop:0}}>
            <InputLabel>{lang.get("country")}</InputLabel>
            <Select
              name="countries"
              fullWidth
              id="site_address.country"
              validators={['required']}
              errorMessages={lang.get("fieldRequired")}
              required={true}
              value={this.state.model.country}
              onChange={(e) => this.handleSelectCountries(e)}
            >
              {this.state.countries.map((country) => (
                <MenuItem
                  value={country.name}
                  id="site_address.fk_countryId"
                  key={country.id}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextValidator
            name="address"
            label={lang.get("address")}
            size="small"
            variant="outlined"
            className={classes.textField}
            value={this.state.model.address}
            onChange={(event) => this.handleInputChange(event)}
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <TextValidator
            name="postalCode"
            label={lang.get("postalCode")}
            size="small"
            variant="outlined"
            className={classes.textField}
            value={this.state.model.postalCode}
            onChange={(event) => this.handleInputChange(event)}
            type="number"
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <TextValidator
            name="city"
            label={lang.get("city")}
            size="small"
            variant="outlined"
            className={classes.textField}
            value={this.state.model.city}
            onChange={(event) => this.handleInputChange(event)}
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <TextValidator
            name="businessWebsite"
            label={lang.get("webPage")}
            size="small"
            variant="outlined"
            className={classes.textField}
            value={this.state.model.businessWebsite}
            onChange={(event) => this.handleInputChange(event)}
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
        </Grid>
      </ValidatorForm>
    );
  }
 render(){

  return <>{this.renderModalComponent()} </>;
 }
}
export default withStyles(useStyles, { withTheme: true })(StripePersonalAddress);
