import React, { Fragment } from "react";
import Lang from "../lang";
import {
  Theme,
  createStyles,
  withStyles,
  Grid,
  Paper,
  withWidth,
  Button,
  Menu,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Input,
  InputAdornment,
  IconButton,
  Dialog,
} from "@material-ui/core";
import DrawerLayout from "../Pages/DrawerLayout";
import {
  isMobile,
  UrlEnum,
  LocalUrlEnum,
  get,
  handleInputChange,
  post,
  DocTypes,
  Statuses,
  StatusEnum,
} from "../Utils/Utils";
import {
  Estimate as EstimateModel,
  DocService,
  Package,
  Service,
  Article,
  Address,
} from "../Models/Models";
// import { Client } from "../Models/Models";
import EditDocService from "../Components/EditDocService";
import DocTotals from "../Components/DocTotals";
import CachedDataSingleton from "../cachedDataSingleton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HierarchicalList from "../Components/HierarchicalList";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/Description";
import SelectServices from "./SelectServices";
import Config from "../Utils/Config";
import { CompanyOptionsEnum } from "../Utils/Utils";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import DashboardContext from "../Contexts/DashboardContext";
import PreviewDocument from "./PreviewDocument";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EstimateGeneralInfo from "./EstimateGeneralInfo";
import { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import DocStatus from "../Components/DocStatus";
import GenerateInProject from "./GenerateInProject";
import SubcontractorsDialog from "./SubcontractorsDialog";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import OrderTicketPayments from "../DocumentComponents/OrderTicketPayments";
import MicrosoftExcel from "../Icons/MicrosoftExcel";
import { ImportExcelDialog } from "../Components/ExcelImport";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import { ValidatorForm } from "react-material-ui-form-validator";
import CreateIcon from "@material-ui/icons/Create";
import { DatePicker } from "@material-ui/pickers";
// import { YoutubeSearchedForOutlined } from '@material-ui/icons';
import VatAttestation from "./VatAttestation";
import theme from "../Theme/Theme";
import ConfirmDialog from "../Components/ConfirmDialog";
const isMobileView = isMobile();
const lang = Lang.getInstance();

type EstimateProps = {
  match: any;
  history: any;
  setLoading: (value: boolean) => void;
  setOpenMessage: (value: string) => void;
  width: any;
  theme: Theme;
  classes: any;
  type: string;
  showSmallMessage: Function;
};

type EstimateState = {
  drawerOpen: boolean;
  model: EstimateModel;
  serviceCategsAnchor: any;
  selectedCateg: any;
  selectedServices: boolean; // keep the id's  of the selected services;
  selectServicesOpen: boolean;
  previewOpen: boolean;
  generateInProject: string | null;
  loading: boolean;
  paymentsOpen: boolean;
  importOpen: boolean;
  editCreatedAt: boolean;
  globalTradeMargin: number;
  open: boolean;
  indexService: number;
  openOrderTicketModal: boolean;
};

const styles = (theme: Theme) =>
  createStyles({
    servicesOptions: {
      left: 300,
      top: 64,
      padding: 0,
      transition: theme.transitions.create("left, width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "calc(100% - 300px)",
      [theme.breakpoints.down("md")]: {
        top: 56,
      },
    },
    servicesOptionsShift: {
      transition: theme.transitions.create("left, width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: 0,
      width: "100%",
    },
    saveBtn: {
      float: "right",
    },
    buttonLabel: {
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: 0,
      },
    },
    statusBtn: {
      width: "100%",
    },
  });

class Estimate extends React.PureComponent<EstimateProps, EstimateState> {
  cachedDataSingleton: any;
  serviceCategories: any;
  matchParamsId: number;
  saveInterval: any;
  locationListenerUnlisten: any;
  pathname: string;
  generalInfoFormRef: any;

  constructor(props: EstimateProps) {
    super(props);
    this.cachedDataSingleton = CachedDataSingleton.getInstance();
    this.serviceCategories = this.cachedDataSingleton.get("serviceCategories");
    let initialModel = new EstimateModel();
    initialModel.type = props.type;
    this.state = {
      drawerOpen: !isMobileView,
      model: initialModel,
      serviceCategsAnchor: null,
      selectedCateg: this.serviceCategories[0],
      selectedServices: false,
      selectServicesOpen: false,
      previewOpen: false,
      generateInProject: null,
      loading: false,
      paymentsOpen: false,
      importOpen: false,
      editCreatedAt: false,
      globalTradeMargin: 0,
      open: false,
      indexService: 0,
      openOrderTicketModal: false,
    };
    this.matchParamsId = this.props.match.params.id
      ? parseInt(this.props.match.params.id)
      : 0;
    this.pathname = this.props.history.location.pathname;
    this.generalInfoFormRef = React.createRef();
  }

  /**
   *
   */
  componentDidMount() {
    if (
      this.matchParamsId &&
      this.matchParamsId >= 0 &&
      this.state.model.id === 0
    ) {
      this.getEstimateData();
    } else {
      this.getEstimateNumber();
    }
  }
  private activateAutoSave() {
    if (this.state.model.id > 0) {
      this.saveInterval = setInterval(() => {
        this.saveEstimate();
      }, Config.autoSaveDocTimeout);
      // this.save(false);
    }
  }

  /**
   * get new number
   */
  private async getEstimateNumber() {
    const url = UrlEnum.estimates + "/getNumber/" + this.props.type;
    const response = await get(url);
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
      return;
    }
    let estimate = { ...this.state.model };
    estimate.estimateNo = response;
    this.setState({ model: estimate });
  }

  /**
   * get estimate data from server
   */
  private getEstimateData() {
    this.props.setLoading(true);
    const url = UrlEnum.estimates + "/" + this.matchParamsId;
    get(url)
      .then((data) => {
        if (!data || !data.id) {
          alert(lang.get("notFound"));
          this.props.setLoading(false);
          return;
        }
        this.props.setLoading(false);
        // calculate vat for initial services
        for (let s of data.estimate_services) {
          s.vat = parseFloat(
            ((s.totalServicePrice * this.state.model.vatRate) / 100).toFixed(
              Config.noOfDecimals
            )
          );
        }
        data.startDate = moment(data.startDate, Config.momentEUDateFormat);
        data.endDate = moment(data.endDate, Config.momentEUDateFormat);
        if (!data.paymentPhases) {
          data.paymentPhases = new EstimateModel().paymentPhases;
        }
        this.setState({
          model: data,
          globalTradeMargin: data.estimate_services.length
            ? data.estimate_services[0].tradeMargin
            : 0,
        });
        this.activateAutoSave();
      })
      .catch(() => {
        this.props.setLoading(false);
      });
  }

  /**
   * change for form inputs
   */
  private handleChange(event: any, model: any) {
    if (!model || !model.hasOwnProperty("id")) {
      model = this.state.model;
    }
    const newModel = handleInputChange(event, model);
    this.setState({ model: newModel });
  }

  /**
   * change estimate date
   */
  private handleDateChange(
    dateProp: string,
    momentDate: MaterialUiPickersDate
  ) {
    let estimate = { ...this.state.model };
    if (momentDate !== null) {
      estimate[dateProp] = momentDate;
      this.setState({ model: estimate });
    }
  }

  /**
   * render component
   */
  private handleClientChange(
    e: any,
    client: any,
    reason: AutocompleteChangeReason,
    details?: any
  ) {
    let newestimate = Object.assign({}, this.state.model);
    newestimate.client = client;
    if (!client) {
      this.setState({ model: newestimate });
      return;
    }
    newestimate.fk_ClientId = client.id;
    const vatTypes = this.cachedDataSingleton.get("vatTypes");
    const vt = vatTypes.find((v: any) => v.id === client.fk_VATTypeId);
    if (vt) {
      newestimate.vatRate = vt.value;
    }
    if (details) {
      newestimate.site_address.address = newestimate.client.address.address;
      newestimate.site_address.code = newestimate.client.address.code;
      newestimate.site_address.city = newestimate.client.address.city;
      newestimate.site_address.fk_countryId =
        newestimate.client.address.fk_countryId;
    }
    if (client.discount) newestimate.discount = parseInt(client.discount);
    else newestimate.discount = 0;
    this.setState({ model: newestimate });
    this.save(true);
  }

  /**
   *
   * @param e
   * @param subcontractor
   * @param reason
   */
  private handleSubcontractorChange(
    e: any,
    subcontractor: any | null,
    reason: AutocompleteChangeReason
  ) {
    if (!subcontractor) return;
    let newestimate = Object.assign({}, this.state.model);
    newestimate.fk_SubcontractorId = subcontractor.id;
    this.setState({ model: newestimate });
  }

  /**
   * delete service from estimate
   */
  private handleDeleteService(event: React.MouseEvent) {
    const notSelected = this.state.model.estimate_services.filter(
      (s) => !s.selected
    );
    const newModel = { ...this.state.model };
    newModel.estimate_services = notSelected;
    this.setState({ model: newModel, selectedServices: false });
  }

  /**
   * add new empty service in estimate
   */
  private handleAddService(event: any) {
    let newModel = { ...this.state.model };
    let newService = new DocService();
    let company = this.cachedDataSingleton.get("company");
    newService.serviceCategName = this.state.selectedCateg.name;
    let optionTradeMargin = company.company_options.find(
      (element: any) => element.option === CompanyOptionsEnum.TRADEMARGIN
    );
    if (optionTradeMargin) newService.tradeMargin = optionTradeMargin.value;
    newModel.estimate_services.push(newService);
    this.setState({ model: newModel });
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  }

  /**
   * delete selected services
   */
  private handleDocServiceSelectChange(
    event: React.ChangeEvent<HTMLInputElement>,
    docService: DocService
  ) {
    let ds = { ...docService };
    ds.selected = event.currentTarget.checked;
    const index = this.state.model.estimate_services.indexOf(docService);
    if (index < 0) return;

    this.state.model.estimate_services.splice(index, 1, ds);
    const selected = this.state.model.estimate_services.filter(
      (s) => s.selected === true
    );

    this.setState({
      model: { ...this.state.model },
      selectedServices: selected.length !== 0,
    });
  }

  /**
   * return selected services
   */
  private getSelectedServices(): Array<DocService> {
    return this.state.model.estimate_services.filter(
      (s) => s.selected === true
    );
  }

  /**
   * DocService change
   */
  private handleDocServiceInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    docService: DocService
  ) {
    let val: any = event.currentTarget.value;
    if (
      event.currentTarget.name === "serviceQuantity" ||
      event.currentTarget.name === "servicePrice"
    ) {
      if (val !== "-") {
        val = parseFloat(event.currentTarget.value);
        val = isNaN(val) ? "" : val;
      }
      if (
        event.currentTarget.value[event.currentTarget.value.length - 1] === "."
      ) {
        event.currentTarget.value = val.toString() + ".";
      } else {
        event.currentTarget.value = val.toString();
      }
      if (event.currentTarget.name === "servicePrice") {
        docService.materialsCost = docService.laborCost = docService.tradeMargin = 0;
      }
    }
    const newDs = handleInputChange(event, docService);
    DocService.recalculatePrice(newDs, this.state.model.vatRate);
    const index = this.state.model.estimate_services.indexOf(docService);
    let newModel = { ...this.state.model };
    newModel.estimate_services.splice(index, 1, newDs);
    this.setState({ model: newModel });
  }

  /**
   * add all services in package in selected categ
   * @param event
   * @param pkg
   */
  private async selectPackage(event: React.MouseEvent, pkg: Package) {
    event.preventDefault();
    const response = await get(
      `${UrlEnum.servicePackages}/services/${pkg.id}/${this.state.model.id}/${this.state.model.type}/${this.state.selectedCateg.name}/-`
    );
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
    }
    for (let s of response) {
      DocService.recalculatePrice(s, this.state.model.vatRate);
    }
    let newestimate = this.state.model;
    newestimate.estimate_services = newestimate.estimate_services.concat(
      response
    );
    this.setState({ model: newestimate, selectServicesOpen: false });
    return false;
  }

  /**
   * select all services
   */
  private selectAll(event: React.MouseEvent<HTMLInputElement>) {
    const estimateServices: Array<DocService> = [];
    for (let is of this.state.model.estimate_services) {
      let newIs = { ...is };
      newIs.selected = event.currentTarget.checked;
      estimateServices.push(newIs);
    }
    let m = { ...this.state.model };
    m.estimate_services = estimateServices;
    this.setState({
      model: m,
      selectedServices:
        event.currentTarget.checked && estimateServices.length > 0,
    });
  }

  /**
   * select and add a service
   */
  private async selectService(event: React.MouseEvent, serviceInfo: any) {
    event.preventDefault();
    const response = await get(`${UrlEnum.services}/${serviceInfo.id}`);
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
    }

    response.serviceQuantity = 1;
    DocService.recalculatePrice(response, this.state.model.vatRate);
    let newModel = this.state.model;

    let docService: DocService = Service.toDocService(response);
    //set category to selected category
    docService.serviceCategName = this.state.selectedCateg.name;
    newModel.estimate_services.push(docService);
    this.setState({ model: newModel });
    return false;
  }

  /**
   * select and add a article
   */
  private selectArticle(event: React.MouseEvent, article: Article): boolean {
    event.preventDefault();
    let estimate = { ...this.state.model };

    let docService: DocService = new DocService();
    docService.serviceName = article.name;
    docService.serviceQuantity = 1;
    docService.servicePrice = article.price;
    docService.totalServicePrice = article.price;
    docService.serviceUm = article.um;
    docService.serviceCategName = this.state.selectedCateg.name;

    estimate.estimate_services.push(docService);
    this.setState({ model: estimate });
    return false;
  }

  /**
   *
   */
  private async handleChangeOpen(indexService: number) {
    this.setState({ open: !this.state.open, indexService: indexService });
  }
  private async handleSaveService(confirm: boolean) {
    if (!confirm) {
      this.setState({ open: false });
      return;
    }
    await this.save(true);
    const docService = this.state.model.estimate_services[
      this.state.indexService
    ];
    const model = {
      id: docService.id,
      companyId: this.cachedDataSingleton.get("company").id,
    };

    let url = !isNaN(docService.id)
      ? `${UrlEnum.services}/updateFrom/${docService.id}/${model.companyId}`
      : `${UrlEnum.services}/updateFrom/${model.companyId}`;
    let data = await post(url, model);
    if (data.errors) {
      this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      this.setState({ open: false });
      return;
    }
    if (docService.initialServiceId === 0) {
      let newModel = Object.assign(this.state.model);
      newModel.estimate_services.forEach((service: any) => {
        if (service.id === docService.id) service.initialServiceId = data;
      });

      this.setState({ model: newModel });
      this.setState({ loading: true }, () => this.save(true));
    }
    this.setState({ open: false });
  }
  private servicesByCategs() {
    let categs: any = {};
    let categsServ: any = {};
    let estimateTotalNoVat = 0;
    let index = 0;
    for (let service of this.state.model.estimate_services) {
      service.servicePrice = service.servicePrice || 0;
      estimateTotalNoVat +=
        parseFloat(service.servicePrice.toString()) * service.serviceQuantity;
      DocService.recalculatePrice(service, this.state.model.vatRate);
      const indexService = this.state.model.estimate_services.indexOf(service);
      const serviceView = (
        <EditDocService
          handleSelectChange={this.handleDocServiceSelectChange.bind(this)}
          handleInputChange={this.handleDocServiceInputChange.bind(this)}
          handleChangeOpen={this.handleChangeOpen.bind(this)}
          key={index++}
          model={service}
          indexService={indexService}
          width={this.props.width}
          vatRate={this.state.model.vatRate}
          disabled={this.state.model.status.id !== 1}
        />
      );
      if (!categs[service.serviceCategName]) {
        categs[service.serviceCategName] = [serviceView];
        categsServ[service.serviceCategName] = [service];
      } else {
        categs[service.serviceCategName].push(serviceView);
        categsServ[service.serviceCategName].push(service);
      }
    }
    // do not set state in render
    let estimate = this.state.model;
    estimate.totalNoVat = estimateTotalNoVat;
    estimate.totalWithVat = EstimateModel.getTotals(
      this.state.model
    ).totalWithVat;
    return [categs, categsServ];
  }

  /**
   *
   */
  private generateInvoice() {
    const selectedServices: Array<DocService> = this.getSelectedServices();
    if (!selectedServices || selectedServices.length === 0) {
      this.props.setOpenMessage(lang.get("pleaseselectServices") + ".");
      return;
    }
    const url =
      UrlEnum.orderTickets + "/fromOrderTicket/invoice/" + this.state.model.id;

    this.generateDoc(url, "invoice", null, null);
  }

  private generateProject() {
    if (this.state.model.folder) {
      this.props.history.push(
        LocalUrlEnum.projects + "/" + this.state.model.folder.id
      );
      return;
    }
    const url =
      UrlEnum.orderTickets + "/fromOrderTicket/project/" + this.state.model.id;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(lang.get("newFolder") + "?")) {
      this.generateDoc(url, DocTypes.project, null, null);
    }
  }

  private generateOrderTicket(
    docType: string | null,
    projectType: string | null,
    folderId: number | null = null
  ) {
    const url =
      UrlEnum.estimates +
      "/fromEstimate/" +
      this.state.model.id +
      (projectType === "new" ? "/new" : folderId !== 0 ? "/" + folderId : "");
    this.generateDoc(url, docType, projectType, folderId, () => {
      this.setState({ generateInProject: null });
      document.location.reload();
    });
  }

  /**
   *
   */
  private generateSubcontractorOrderTicket(subcontractorId: number) {
    const url =
      UrlEnum.orderTickets +
      "/fromOrderTicket/subcontractorOrderTicket/" +
      this.state.model.id +
      "/" +
      subcontractorId;
    this.generateDoc(url, DocTypes.subcontractorOrderTicket, null, null, () => {
      document.location.reload();
    });
  }

  /**
   *
   */
  private async generateDoc(
    url: string,
    docType: string | null,
    projectType: string | null,
    folderId: number | null = null,
    onFinish: Function | null = null
  ) {
    const selectedServices: Array<DocService> = this.getSelectedServices();
    const serviceIds: Array<number> = selectedServices.map((s) => s.id);
    let method = "PUT";
    if (docType !== DocTypes.orderTicket) {
      method = "POST";
    }
    const response = await post(url, { serviceIds: serviceIds }, null, method);
    if (response.errors) {
      const errors =
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.setOpenMessage(errors);
      return;
    }
    if (docType === DocTypes.project) {
      // set id before redirect so when auto save kicks
      // in it does not delete the folder id
      this.setState(
        { model: { ...this.state.model, fk_FolderId: response.id } },
        () => {
          const pushUrl =
            "/" +
            docType?.toLowerCase() +
            "s/" +
            (response.id ? response.id : response);
          this.props.history.push(pushUrl);
          if (onFinish) {
            onFinish();
          }
        }
      );
    } else {
      const pushUrl =
        "/" +
        docType?.toLowerCase() +
        "s/" +
        (response.id ? response.id : response);
      this.props.history.push(pushUrl);
      if (onFinish) {
        onFinish();
      }
    }
  }

  /**
   * generate new doc options
   */
  private renderGenerateMenuByType() {
    let r = null;
    switch (this.props.type) {
      case DocTypes.estimate:
        r = (
          <ListItem
            button
            onClick={() => {
              const selectedServices: Array<DocService> = this.getSelectedServices();
              if (!selectedServices || selectedServices.length === 0) {
                this.props.setOpenMessage(
                  lang.get("pleaseselectServices") + "."
                );
                return;
              }
              this.setState({ generateInProject: DocTypes.orderTicket });
              this.setState({ openOrderTicketModal: true });
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={lang.get("orderTicket")} />
          </ListItem>
        );
        break;
      case DocTypes.orderTicket:
        r = (
          <Fragment>
            <ListItem button onClick={() => this.generateInvoice()}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("invoice")} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                const selectedServices: Array<DocService> = this.getSelectedServices();
                if (!selectedServices || selectedServices.length === 0) {
                  this.props.setOpenMessage(
                    lang.get("pleaseselectServices") + "."
                  );
                  return;
                }
                this.setState({
                  generateInProject: DocTypes.subcontractorOrderTicket,
                });
              }}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("subcontractorOrderTicket")} />
            </ListItem>
            <ListItem button onClick={() => this.generateProject()}>
              <ListItemIcon>
                <FolderSpecialIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("folder")} />
            </ListItem>
            <VatAttestation
              company={this.cachedDataSingleton.get("company")}
              client={this.state.model.client}
              setSmallMessage={this.props.showSmallMessage}
              docType={lang.get("orderTicket")}
              docData={{
                series: this.state.model.series,
                no: this.state.model.estimateNo,
                worksiteAddress: Address.stringify(
                  this.state.model.site_address
                ),
                date: moment(
                  this.state.model.created_at,
                  Config.momentUSDateFormatWithTime
                ).format(Config.momentEUDateFormat),
              }}
            />
          </Fragment>
        );
        break;
      default:
        return null;
    }
    return r;
  }

  private renderBackToDocumentsByType() {
    let r = null;
    switch (this.props.type) {
      case DocTypes.estimate:
        r = (
          <Link to={LocalUrlEnum.estimates}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("estimates")} />
            </ListItem>
          </Link>
        );
        break;
      case DocTypes.orderTicket:
        r = (
          <Link to={LocalUrlEnum.orderTickets}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("orderTickets")} />
            </ListItem>
          </Link>
        );
        break;
      case DocTypes.subcontractorOrderTicket:
        r = (
          <Link to={LocalUrlEnum.subcontractorordertickets}>
            <ListItem button>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText primary={lang.get("subcontractorOrderTicket")} />
            </ListItem>
          </Link>
        );
        break;
      default:
        return null;
    }
    return r;
  }

  private renderHeader() {
    if (this.props.width === "xs" || this.props.width === "sm") {
      return (
        <Grid container classes={{ root: "docServiceWrapper servicesHeader" }}>
          <Grid item>
            <input
              type="checkbox"
              onClick={this.selectAll.bind(this)}
              style={{ marginRight: 20, color: theme.palette.header?.main }}
            />
          </Grid>
          <Grid item lg={5} md={4} sm={5} xs={8}>
            {lang.get("name")}
          </Grid>
          <Grid item>{lang.get("options")}</Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container classes={{ root: "docServiceWrapper servicesHeader" }}>
          <Grid item>
            <input
              type="checkbox"
              onClick={this.selectAll.bind(this)}
              style={{ marginRight: 20, color: theme.palette.header?.main }}
            />
          </Grid>
          <Grid item lg={5} md={4} sm={3} xs={6}>
            {lang.get("name")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("measureUnit")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("quantity")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("unitPrice")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("value")}
          </Grid>
          <Grid item md={1} xs={1}>
            {lang.get("vat")}
          </Grid>
          <Grid item>{lang.get("options")}</Grid>
        </Grid>
      );
    }
  }

  /**
   *
   * @param manualSave
   */
  async save(manualSave: boolean) {
    //validate general info
    if (this.generalInfoFormRef) {
      const valid = await this.generalInfoFormRef.current.isFormValid();
      if (!valid) {
        this.generalInfoFormRef.current.submit();
        this.setState({ loading: false });
        return false;
      }
    }
    if (!this.state.model.client || this.state.model.client.id <= 0) {
      this.setState({ loading: false });
      return false;
    }

    // transform estimate to any to change estimateDate from moment to string
    let data: any = { ...this.state.model } as any;
    // data.estimateDate = moment.isMoment(this.state.model.estimateDate)
    // 	? this.state.model.estimateDate.format(Config.momentUSDateFormat)
    // 	: this.state.model.estimateDate;

    const response = await post(
      UrlEnum.estimates + "/" + (this.state.model.id || 0),
      data
    );

    // this.props.showSmallMessage(lang.get("saved"));
    if (response.errors) {
      const errors =
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.setOpenMessage(errors);
      this.setState({ loading: false });
      return;
    }
    let newestimate: EstimateModel = { ...this.state.model };
    // update model values with new values from server
    // but ignore estimate_services
    const keys = Object.keys(newestimate);
    for (const key of keys) {
      if (
        response[key] &&
        key !== "startDate" &&
        key !== "endDate" &&
        key !== "client" &&
        key !== "site_address"
      ) {
        (newestimate as any)[key] = response[key];
      }
      if (key === "estimate_services") {
        const services = newestimate.estimate_services.filter(
          (element: any) => element.isNew === true
        );
        let index;
        for (let i = 0; i < services.length; i++) {
          index = response.estimate_services.findIndex(
            (element: any) => element.serviceName === services[i].serviceName
          );
          if (index >= 0) response.estimate_services[index].isNew = true;
        }
        (newestimate as any)[key] = response[key];
      }
    }
    this.setState({ model: newestimate, loading: false });
    if (manualSave) {
      this.props.showSmallMessage(lang.get("saved"));
    }
    // change url according to type
    let url = LocalUrlEnum.estimates;
    switch (this.props.type) {
      case DocTypes.orderTicket:
        url = LocalUrlEnum.orderTickets;
        break;
      case DocTypes.subcontractorOrderTicket:
        url = LocalUrlEnum.subcontractorordertickets;
        break;
      default:
        url = LocalUrlEnum.estimates;
    }
    this.props.history.push(url + "/" + newestimate.id);

    // enable autosave
    if (!this.saveInterval) {
      if (this.state.model.status.id === 1) {
        this.saveInterval = setInterval(() => {
          this.saveEstimate();
        }, Config.autoSaveDocTimeout);
      }
    }
  }

  /**
   *
   */
  saveEstimate() {
    if (this.state.loading) return;
    let manualSave: boolean = false;
    if (arguments && arguments[0]) {
      manualSave = true;
    }
    this.setState({ loading: true }, () => this.save(manualSave));
  }

  /**
   * save estimate before leaving
   */
  async saveEstimateMin() {
    if (!this.state.model.id) return;

    let data: any = { ...this.state.model } as any;
    data.estimateDate = moment.isMoment(this.state.model.estimateDate)
      ? this.state.model.estimateDate.format(Config.momentUSDateFormat)
      : this.state.model.estimateDate;

    post(UrlEnum.estimates + "/" + (this.state.model.id || 0), data);
  }

  /**
   *
   */
  componentWillMount() {
    this.locationListenerUnlisten = this.props.history.listen(
      (location: any, action: any) => {
        if (
          this.state.model.status.id === 1 &&
          location.pathname !== this.pathname
        ) {
          this.saveEstimateMin();
          clearInterval(this.saveInterval);
          this.locationListenerUnlisten();
        }
      }
    );
  }

  /**
   * change status
   */
  async changeEstimateState() {
    if (this.state.model.status !== null) {
      let nextState = this.state.model.status;
      if (this.state.model.status.id === Statuses.Active.id) {
        nextState = Statuses.Inactive;
      } else {
        nextState = Statuses.Active;
      }

      const url = `${UrlEnum.estimates}/changeState/${this.state.model.id}/${nextState.id}`;
      const response = await get(url);
      if (response.errors) {
        alert(response.errors);
        return;
      }
      let newEstimate = { ...this.state.model };
      newEstimate.status = nextState;
      this.setState({ model: newEstimate });
    }
  }

  /**
   * blur payment percent
   * @param e
   */
  onPaymentPercentBlur(e: React.FocusEvent<HTMLInputElement>, index: number) {
    if (!this.state.model.paymentPhases) return;
    let newModel = { ...this.state.model };
    let pf = newModel.paymentPhases;
    let value = parseInt(e.target.value);
    if (value > 100) {
      value = 100;
    }
    if (value < 0) {
      value = 0;
    }

    pf[index].percent = value;
    const nextIndex = index === pf.length - 1 ? 0 : index + 1;
    let nextPercent = 100;
    for (let i = 0; i < pf.length; i++) {
      if (i === nextIndex) continue;
      nextPercent -= pf[i].percent;
    }
    pf[nextIndex].percent = nextPercent;

    this.setState({ model: newModel });
  }

  /**
   *
   * @param e
   */
  onPaymentChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    if (!this.state.model.paymentPhases) return;
    let newModel = { ...this.state.model };
    let pf = newModel.paymentPhases;
    if (e.target.name === "percent") {
      pf[index].percent = parseInt(e.target.value);
    } else {
      pf[index].text = e.target.value;
    }

    this.setState({ model: newModel });
  }

  /**
   * add payment phase for order ticket
   * @param e
   */
  onAddPaymentPhase(e: React.MouseEvent) {
    if (!this.state.model.paymentPhases) return;
    let newModel = { ...this.state.model };
    newModel.paymentPhases.push({
      text: "-",
      percent: 0,
    });
    this.setState({ model: newModel });
  }

  /**
   * add services from data imported from xml
   * @param data
   */
  getImportData(data: any) {
    let newModel = { ...this.state.model };
    if (!data || data.length === 0) return;
    const { data: importData } = data;
    for (let val of importData) {
      let newService = new DocService();
      newService.serviceCategName = this.state.selectedCateg.name;
      newService.serviceName = val.name;
      newService.servicePrice = val.price;
      newService.serviceQuantity = val.quantity;
      newService.serviceUm = val.um;
      newModel.estimate_services.push(newService);
    }
    this.setState({ model: newModel, importOpen: false });
    this.props.showSmallMessage(lang.get("success"), StatusEnum.SUCCESS);
  }

  /**
   * remove payment phase from order ticket
   * @param e
   */
  onRemovePaymentPhase(e: React.MouseEvent, index: number) {
    if (!this.state.model.paymentPhases) return;
    let newModel = { ...this.state.model };
    newModel.paymentPhases.splice(index, 1);
    this.setState({ model: newModel });
  }

  /**
   * save payment phases
   */
  async onSavePayments() {
    if (!this.state.model.paymentPhases) return;
    const url =
      UrlEnum.orderTickets + "/changePaymentPhases/" + this.state.model.id;
    const response: any = post(url, {
      paymentPhases: this.state.model.paymentPhases,
    });
    if (response.errors) {
      this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
    } else {
      this.props.showSmallMessage(lang.get("saved"));
    }
  }

  /**
   *  render the trade margin ui
   */
  renderChangeServicesTradeMargin() {
    return (
      <div style={{ marginTop: 10 }}>
        <label>
          {lang.get("tradeMargin")}: &nbsp;
          <Input
            style={{ width: 60 }}
            type="number"
            value={this.state.globalTradeMargin}
            onChange={this.changeTradeMarginForAllServices.bind(this)}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </label>
      </div>
    );
  }

  /**
   * change all trade margins
   */
  changeTradeMarginForAllServices(e: React.ChangeEvent<HTMLInputElement>) {
    const tm = parseInt(e.target.value);
    this.setState({ globalTradeMargin: tm });

    let newEstimateServices = [];
    for (let ds of this.state.model.estimate_services) {
      let newDs = { ...ds };
      newDs.tradeMargin = tm;
      DocService.recalculatePrice(newDs, this.state.model.vatRate);
      newEstimateServices.push(newDs);
    }
    let newModel = { ...this.state.model };
    newModel.estimate_services = newEstimateServices;
    this.setState({ model: newModel });
  }

  renderCategoriesSelect() {
    return (
      <div style={{ padding: 10 }}>
        <label>{lang.get("categories")}</label>
        <Button
          color="inherit"
          onClick={(event: React.MouseEvent) =>
            this.setState({ serviceCategsAnchor: event.currentTarget })
          }
        >
          {this.state.selectedCateg.name}
          <ArrowDropDownIcon fontSize="small" />
        </Button>
        <Menu
          className="serviceCategs"
          id="serviceCategsMenu"
          anchorEl={this.state.serviceCategsAnchor}
          keepMounted={true}
          open={Boolean(this.state.serviceCategsAnchor)}
          onClose={() => this.setState({ serviceCategsAnchor: null })}
        >
          <div>
            <HierarchicalList
              models={this.serviceCategories}
              childrenLabel="children_categ"
              foreignKey="fk_ParentCategId"
              onClick={(event: React.MouseEvent, model: any) =>
                this.setState({
                  selectedCateg: model,
                  serviceCategsAnchor: null,
                })
              }
            />
          </div>
        </Menu>
      </div>
    );
  }

  /**
   *
   */
  firstSaved() {
    if (this.state.model.estimate_services.length === 0) {
      this.handleAddService(this);
    }
  }
  renderProjectLink() {
    if (!this.state.model.folder) return null;
    return (
      <small>
        <Link
          className="link"
          to={LocalUrlEnum.projects + "/" + this.state.model.folder?.id}
        >
          {lang.get("folder")}: {this.state.model.folder?.folderNo}
        </Link>
      </small>
    );
  }

  renderModal() {
    return (
      <Dialog open={this.state.open}>
        <h1>Hello</h1>
      </Dialog>
    );
  }
  handleOnCloseOrderTicketModal() {
    this.setState({ openOrderTicketModal: false });
  }
  /**
   *
   */
  render() {
    // if we have params id and no model id, estimate is not fetched from the server
    if (this.matchParamsId > 0 && this.state.model.id === 0) return "";

    // const width = this.useWidth();
    const [categs, categsServ] = this.servicesByCategs();
    const { classes } = this.props;
    return (
      <DrawerLayout
        open={this.state.drawerOpen}
        drawerWidth={!isMobileView ? 300 : "100%"}
        handleDrawerClose={() =>
          this.setState({ drawerOpen: !this.state.drawerOpen })
        }
        drawerChildren={
          <Fragment>
            <List>
              {this.renderBackToDocumentsByType()}
              <Divider />
              <ListItem
                button
                disabled={!this.state.model.id}
                onClick={(event: React.MouseEvent) =>
                  this.setState({ previewOpen: true })
                }
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("previewSend")} />
              </ListItem>
              {this.state.previewOpen ? (
                <DashboardContext.Consumer>
                  {(context) => (
                    <PreviewDocument
                      open={this.state.previewOpen}
                      onClose={() => this.setState({ previewOpen: false })}
                      type={this.state.model.type}
                      services={[categsServ]}
                      setOpenMessage={context.setOpenMessage}
                      setSmallMessage={context.setSmallMessage}
                      totals={[EstimateModel.getTotals(this.state.model)]}
                      id={this.state.model.id}
                      currency={this.state.model.currency}
                      docData={[
                        {
                          series: this.state.model.series.toString(),
                          no: this.state.model.estimateNo,
                          date: moment(
                            this.state.model.created_at,
                            Config.momentUSDateFormat
                          ).format(Config.momentEUDateFormat),
                          worksiteAddress: Address.stringify(
                            this.state.model.site_address
                          ),
                          startDate: this.state.model.startDate
                            ? this.state.model.startDate.format(
                                Config.momentEUDateFormat
                              )
                            : null,
                          endDate: this.state.model.endDate
                            ? this.state.model.endDate.format(
                                Config.momentEUDateFormat
                              )
                            : null,
                          title: this.state.model.title,
                          description: this.state.model.description,
                          additionalInfo: this.state.model.additionalInfo || "",
                          client: this.state.model.client,
                          folder: this.state.model.folder,
                          totalNoVat: this.state.model.totalNoVat,
                        },
                      ]}
                      children={[null]}
                      paymentPhases={
                        this.state.model.type.toLocaleLowerCase().trim() ===
                        DocTypes.orderTicket.toLocaleLowerCase().trim()
                          ? [this.state.model.paymentPhases]
                          : []
                      }
                    />
                  )}
                </DashboardContext.Consumer>
              ) : (
                ""
              )}
              {this.state.model.type.toLocaleLowerCase().trim() ===
              DocTypes.orderTicket.toLocaleLowerCase().trim() ? (
                <>
                  <ListItem
                    button
                    disabled={!this.state.model.id}
                    onClick={(event: React.MouseEvent) =>
                      this.setState({ paymentsOpen: true })
                    }
                  >
                    <ListItemIcon>
                      <LocalAtmIcon />
                    </ListItemIcon>
                    <ListItemText primary={lang.get("payments")} />
                  </ListItem>
                  <OrderTicketPayments
                    open={this.state.paymentsOpen}
                    payments={this.state.model.paymentPhases}
                    totalWithVat={this.state.model.totalWithVat}
                    onPercentBlur={this.onPaymentPercentBlur.bind(this)}
                    onChange={this.onPaymentChange.bind(this)}
                    onAddPaymentPhase={this.onAddPaymentPhase.bind(this)}
                    onRemovePaymentPhase={this.onRemovePaymentPhase.bind(this)}
                    onClose={(e: React.MouseEvent) =>
                      this.setState({ paymentsOpen: false })
                    }
                    onSave={this.onSavePayments.bind(this)}
                  />
                </>
              ) : null}

              <Divider />

              <ListItem>
                <ListItemText primary={lang.get("generate")} />
              </ListItem>
              {this.renderGenerateMenuByType()}

              {/* import excel */}
              <ListItem
                button
                disabled={!this.state.model.id}
                onClick={(event: React.MouseEvent) =>
                  this.setState({ importOpen: true })
                }
              >
                <ListItemIcon>
                  <MicrosoftExcel width={25} />
                </ListItemIcon>
                <ListItemText primary={lang.get("import")} />
              </ListItem>
              <ImportExcelDialog
                open={this.state.importOpen}
                onClose={() => this.setState({ importOpen: false })}
                getData={this.getImportData.bind(this)}
              />

              <ListItem>
                <DocStatus
                  size="small"
                  className={classes.statusBtn}
                  status={this.state.model.status}
                  onChangeStatus={this.changeEstimateState.bind(this)}
                />
              </ListItem>
            </List>
          </Fragment>
        }
        menu={
          <>
            <Button
              disabled={this.state.model.status.id !== 1}
              color="inherit"
              onClick={(event: React.MouseEvent) =>
                this.setState({ selectServicesOpen: true })
              }
              classes={{ label: classes.buttonLabel }}
            >
              <MoveToInboxIcon />
              {lang.get("selectServices")}
            </Button>

            {this.state.selectServicesOpen ? (
              <SelectServices
                open={this.state.selectServicesOpen}
                onClose={() => this.setState({ selectServicesOpen: false })}
                onSelectPackage={this.selectPackage.bind(this)}
                onSelectService={this.selectService.bind(this)}
                onSelectArticle={this.selectArticle.bind(this)}
              />
            ) : (
              ""
            )}

            <Button
              disabled={this.state.model.status.id !== 1}
              color="inherit"
              onClick={this.handleAddService.bind(this)}
              classes={{ label: classes.buttonLabel }}
            >
              <AddIcon />
              {lang.get("new") + " " + lang.get("service")}
            </Button>
            {this.state.selectedServices ? (
              <Button
                disabled={
                  !this.state.selectedServices ||
                  this.state.model.status.id !== 1
                }
                color="inherit"
                onClick={this.handleDeleteService.bind(this)}
                classes={{ label: classes.buttonLabel }}
              >
                <DeleteIcon />
              </Button>
            ) : null}

            {this.renderCategoriesSelect()}
            <Typography style={{ flexGrow: 1 }}></Typography>
            <Button
              disabled={this.state.model.status.id !== 1 || this.state.loading}
              className={classes.saveBtn}
              style={{
                color: theme.palette.textColorSecondary?.main,
                background: theme.palette.successColor?.main,
              }}
              variant="contained"
              size="large"
              onClick={this.saveEstimate.bind(this)}
            >
              {lang.get("save")}
            </Button>
          </>
        }
      >
        <Fragment>
          <div style={{ display: "flex" }}>
            <h2>
              {lang.get(this.props.type)} {this.renderProjectLink()}
            </h2>
            <span style={{ marginTop: 9 }}>
              <span>{lang.get("from")}: </span>
              <DatePicker
                disabled={!this.state.editCreatedAt}
                required={true}
                disableToolbar
                variant="inline"
                name="created_at"
                value={this.state.model.created_at}
                format={Config.momentEUDateFormat}
                onChange={(md) => this.handleDateChange("created_at", md)}
              />
              <IconButton
                aria-label="toggle edit create date"
                onClick={(e) => {
                  this.setState({ editCreatedAt: !this.state.editCreatedAt });
                }}
              >
                <CreateIcon />
              </IconButton>
            </span>
          </div>
          <div>
            <ValidatorForm
              ref={this.generalInfoFormRef}
              instantValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <EstimateGeneralInfo
                key={this.state.model.id}
                estimate={this.state.model}
                onChange={this.handleChange.bind(this)}
                onDateChange={this.handleDateChange.bind(this)}
                onClientChange={this.handleClientChange.bind(this)}
                onSubcontractorChange={this.handleSubcontractorChange.bind(
                  this
                )}
              ></EstimateGeneralInfo>
            </ValidatorForm>
            {this.state.model.id > 0 ? (
              <div>
                <Paper style={{ marginTop: 30 }}>
                  <div style={{ display: "flex" }}>
                    {this.renderCategoriesSelect()}
                    {this.renderChangeServicesTradeMargin()}
                  </div>
                  {this.renderHeader()}
                  {Object.keys(categs).map((categName, index) => (
                    <Fragment key={index}>
                      <h3>
                        {index + 1}. {categName}
                      </h3>
                      {categs[categName].length > 1 ? (
                        categs[categName].map((value: any, key: any) => {
                          return (
                            <div>
                              <p
                                style={{
                                  position: "absolute",
                                  marginTop: 15,
                                  marginLeft: 10,
                                }}
                              >
                                {index + 1}.{key + 1}{" "}
                              </p>
                              {value}
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          {" "}
                          <p
                            style={{
                              position: "absolute",
                              marginTop: 15,
                              marginLeft: 10,
                            }}
                          >
                            {index + 1}.1{" "}
                          </p>
                          {categs[categName]}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Paper>
                {this.firstSaved()}
                <DocTotals doc={this.state.model} />
              </div>
            ) : (
              ""
            )}
          </div>
          <GenerateInProject
            open={this.state.openOrderTicketModal}
            onClose={this.handleOnCloseOrderTicketModal.bind(this)}
            onResult={(
              docType: string,
              projectType: string | null,
              selectedProjectId: number | null
            ) =>
              this.generateOrderTicket(docType, projectType, selectedProjectId)
            }
            docType={this.state.generateInProject}
          />
          <SubcontractorsDialog
            open={
              this.state.generateInProject === DocTypes.subcontractorOrderTicket
                ? true
                : false
            }
            onResult={(subcontractorId: number) =>
              this.generateSubcontractorOrderTicket(subcontractorId)
            }
          />
        </Fragment>
        {this.state.open ? (
          <ConfirmDialog
            open={this.state.open}
            text={lang.get("saveService")}
            onResult={(result) => this.handleSaveService(result)}
          />
        ) : (
          ""
        )}
      </DrawerLayout>
    );
  }
}

export default withWidth()(withStyles(styles, { withTheme: true })(Estimate));
