import { Fragment, MouseEvent, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Delete, ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Collapse,
  ListItem,
  TextField,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import Lang from "../lang";
import { ContentCopy } from "@mui/icons-material";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  card: {
    borderRadius: 0,
    width: "100%",
  },
  addIcon: {
    margin: "2px 5px",
    color: "#455a64",
    cursor: "pointer",
  },
  expandIcon: {
    color: "#455a64",
    fontSize: 24,
    cursor: "pointer",
  },
  collapseContent: {
    backgroundColor: "#F8F8F8",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  basePopupPaper: {
    padding: "12px 16px",
    margin: "8px",
    borderRadius: "8px",
    border: `1px solid #EEEEEE`,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 8px rgb(0 0 0 / 0.1)",
    fontWeight: 500,
    fontSize: "0.875rem",
    minWidth: "300px",
    maxwidth: "300px",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  select: {
    width: "80%",
    marginRight: "10px",
  },
}));

type ShowExpensesProps = {
  data: { month: number; expenses: any[] };
  months: string[];
  saveData: (monthId: any, data: any) => void;
  actualBudget?: {
    monthId: number;
    total_cost: string;
  } | null;
  copyExpenseToAnotherMonth: (
    expenses: {
      expense: string;
      value: number;
    },

    month: any
  ) => void;
};

export default function ShowExpenses(props: ShowExpensesProps) {
  const lang = Lang.getInstance();
  const classes = useStyles();
  const [showExpenses, setShowExpenses] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const [selectedMonths, setSelectedMonths] = useState<[] | number[]>([]);
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  /**
   *
   * @param event
   * @returns
   */
  const handleShowExpenses = (event: any) => {
    event.preventDefault();
    setShowExpenses(!showExpenses);
    return false;
  };

  /**
   *
   * @param event
   */
  const handleOnChange = (event: any, key: any) => {
    let newData: any = Object.assign({}, props.data);
    newData.expenses[key][event.target.name] = event.target.value;
    props.saveData(newData.month, newData);
  };

  /**
   *
   * @param event
   * @param data
   */
  const handleFormSubmit = (event: any, data: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.copyExpenseToAnotherMonth(data, selectedMonths);
    setSelectedMonths([]);
    handleClick(event);
  };

  /**
   * Add new expense
   */
  const addNewExpense = () => {
    let monthExpenses = Object.assign({}, props.data);
    monthExpenses.expenses.push({
      expense: "",
      value: 0,
    });
    props.saveData(props.data.month, monthExpenses);
    if (!showExpenses) {
      setShowExpenses(true);
    }
  };

  /**
   *
   * @param index
   */
  const deleteExpense = (index: any) => {
    let monthExpenses = Object.assign({}, props.data);
    monthExpenses.expenses.splice(index, 1);
    props.saveData(props.data.month, monthExpenses);
  };

  /**
   *
   * @param month
   * @returns
   */
  const calculateMonthTotal = () => {
    let total = 0;
    if (props.data.expenses && props.data.expenses.length !== 0) {
      props.data.expenses.forEach((data: any) => {
        //check if value is a number
        if (isNaN(data.value) || data.value === "") return;
        total += parseFloat(data.value);
      });
    }
    return total;
  };

  /**
   *
   * @returns
   */
  const renderCopyButton = (data: { expense: string; value: number }) => {
    return (
      <>
        <IconButton>
          <Tooltip title={lang.get("copy")} onClick={handleClick}>
            <ContentCopy />
          </Tooltip>
        </IconButton>
        <BasePopup placement={"bottom-end"} id={id} open={open} anchor={anchor}>
          <form
            id="show-expenses-form"
            onSubmit={(event) => {
              handleFormSubmit(event, data);
            }}
            className={classes.basePopupPaper}
          >
            <div className={classes.select}>
              <InputLabel id="demo-simple-select-label">
                {lang.get("chooseMonth")}
              </InputLabel>
              <Select
                required
                fullWidth
                multiple={true}
                value={selectedMonths}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={lang.get("chooseMonth")}
                onChange={(e: any) => {
                  if (e.target.value === null || e.target.value === undefined)
                    return;
                  //set selected month array
                  setSelectedMonths(e.target.value);
                }}
              >
                {props.months.map((month, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {month}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Button variant="contained" form="show-expenses-form" type="submit">
              {lang.get("confirm")}
            </Button>
          </form>
        </BasePopup>
      </>
    );
  };

  /**
   *
   * @returns
   */
  const displayExpenses = () => {
    if (props.data.expenses && props.data.expenses.length !== 0) {
      return props.data.expenses.map((data: any, key: any) => {
        return (
          <Grid container spacing={3} key={key}>
            <Grid item xs={12} md={5} lg={6} xl={6}>
              <TextField
                label={lang.get("expense")}
                name="expense"
                variant="outlined"
                value={data.expense}
                fullWidth
                required
                onChange={(e) => handleOnChange(e, key)}
              />
            </Grid>
            <Grid item xs={12} md={5} lg={5} xl={5}>
              <TextField
                label={lang.get("value")}
                name="value"
                variant="outlined"
                type="number"
                required
                value={data.value}
                fullWidth
                onChange={(e) => handleOnChange(e, key)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              lg={1}
              xl={1}
              className={classes.buttonGrid}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "10px",
                }}
              >
                {renderCopyButton(data)}
                <IconButton>
                  <Tooltip
                    title={lang.get("delete")}
                    onClick={() => deleteExpense(key)}
                  >
                    <Delete
                      fontSize="default"
                      color={
                        props.data.expenses && props.data.expenses?.length === 0
                          ? "disabled"
                          : "error"
                      }
                    />
                  </Tooltip>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        );
      });
    }
  };

  return (
    <ListItem className={classes.listItem}>
      <Card className={classes.card} key={props.data.month}>
        <CardHeader
          title={
            <Typography variant="body1">
              {props.months[props.data.month]}
            </Typography>
          }
          subheader={
            <>
              <Typography variant="body2">
                {lang.get("estimatedCosts")}: {calculateMonthTotal()}
              </Typography>
              <Typography variant="body2">
                {props.actualBudget && props.actualBudget.total_cost
                  ? `${lang.get("actualCosts")}: ${
                      props?.actualBudget?.total_cost
                        ? parseFloat(props?.actualBudget?.total_cost).toFixed(2)
                        : "0.00"
                    }`
                  : ""}
              </Typography>
            </>
          }
          action={
            <>
              <AddIcon
                className={classes.addIcon}
                onClick={() => {
                  addNewExpense();
                }}
              />
              {showExpenses ? (
                <ExpandLess
                  className={classes.expandIcon}
                  onClick={(event) => handleShowExpenses(event)}
                />
              ) : (
                <ExpandMore
                  className={classes.expandIcon}
                  onClick={(event) => handleShowExpenses(event)}
                />
              )}
            </>
          }
        />

        <div className={classes.collapseContent}>
          <Collapse in={showExpenses} unmountOnExit timeout={100}>
            <CardContent>{displayExpenses()}</CardContent>
          </Collapse>
        </div>
      </Card>
    </ListItem>
  );
}
