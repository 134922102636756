
import Config from '../Utils/Config'
import { get } from '../Utils/Utils';

class ApiLayer {
    constructor() {
        this.isVatVerified = 0;
        this.stepKey = 0;
        this.apikey = Config.apiLayerKey[this.stepKey];
        this.vatNo="";

    };

    handleApiKey() {
        if (this.stepKey >= Config.apiLayerKey.length - 1)
            return false;
        this.stepKey++;
        this.apikey = Config.apiLayerKey[this.stepKey];
        return true;
    };
    getInfoFromApi(vatNo, success, err) {
            get(Config.apiLayerUrl + "?access_key=" + this.apikey + "&vat_number=" + vatNo, { 'Content-Type': 'text/plain' }).then(function (data) {
                if (data.error) {
                     if (this.handleApiKey()) {
                        this.getInfoFromApi(vatNo, success, err);
                        // console.log(this.stepKey);
                        return;
                    } else {
                        success(data);
                        return;
                    }
                }
                success(data);

            }.bind(this)).catch(function(response){
                err(response);
            });

    }
}

export default ApiLayer;