import React from "react";
import Lang from "../lang";
import FormComponent from "./FormComponent";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CachedDataSingleton from "../cachedDataSingleton";
import MuiPhoneInput from "material-ui-phone-number";
import { withStyles } from "@material-ui/core/styles";
import VatNo from "../Components/VatNo";
import { GoogleMapsUtils, StatusEnum } from "../Utils/Utils";
import scriptLoader from "react-async-script-loader";
import { Address } from "../Models/Models";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import FileExplorerMin from "../Components/FileExplorerMin";
import SubcontractorsEmployees from "../Pages/SubcontractorsEmployees";
import { UrlEnum } from "../Utils/Utils";
import MessageDialog from "../Components/MessageDialog";
import ApiLayer from "../Utils/ApiLayer";
import Alert from "@material-ui/lab/Alert";
import Config from "../Utils/Config";
import SubcontractorContract from "../DocumentComponents/SubcontractorContract";

const styles = (theme) => ({
  fullWidth: {
    width: "100%",
  },
  noMargin: {
    margin: 0,
  },
  noPaddingTop: {
    paddingTop: "0 !important",
  },
  gMap: {
    height: 520,
  },
  expandSubcontractors: {
    boxShadow: "none",
  },
  expandEmployees: {
    background: "#e8eef5",
    boxShadow: "none",
    paddingTop: 5,
    paddingBottom: 5,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    height: "50px !important",
    minHeight: "auto !important",
  },
  expandHeader: {
    margin: "0 !important",
    padding: 0,
  },
});

class EditSubcontrators extends FormComponent {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.lang = Lang.getInstance();
    this.cachedData = CachedDataSingleton.getInstance();
    this.mapWrapperRef = React.createRef();
    this.editedModel = null;
    this.state.expanded = true;
    this.state.messageAlert = false;
    this.state.loading = false;
    this.state.messageSucces = false;
    this.state.textMessage = "";
    this.isVatVerified = 0;
    this.apiLayer = new ApiLayer();
  }

  handleExpendedChange() {
    this.setState({ expanded: !this.state.expanded });
  }

  /**
   * props have changed
   * @param {Object} nextProps
   * @param {Object} prevState
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.model && nextProps.model.id !== prevState.model.id) {
      return { model: nextProps.model };
    }
    return null;
  }

  /**
   * if editModel is available set as model
   */
  componentDidUpdate() {
    let update = false;
    if (this.context.editedModel) {
      if (
        (this.editedModel &&
          this.editedModel.id !== this.context.editedModel.id) ||
        !this.editedModel
      ) {
        update = true;
        this.editedModel = this.context.editedModel;
      }
      if (update) {
        this.setState({ model: this.editedModel }, () => {
          this.locate();
        });
      }
    }
  }

  handleCloseMessage() {
    if (this.isVatVerified === 1) {
      this.setState({ messageAlert: false, loading: false });
    } else {
      this.setState({ messageAlert: false, loading: false });
    }
  }

  /**
   * update vat no
   * @param {Object} data
   */
  vatNoBlur(data) {
    let model = Object.assign({}, this.state.model);
    model.vatNo = data;
    this.setState({ model: model }, () => {
      this.getApiLayerData();
    });
    this.getApiLayerData();
  }
  /**
   *
   */
  getApiLayerData() {
    /**
     * Verify if more than 2 request are made or Vat no is shorter than 11
     */
    if (
      this.state.model.id ||
      this.isVatVerified > 2 ||
      this.state.model.vatNo.length < 11
    ) {
      return;
    }
    /**
     * Make loading circle vissible
     */
    this.setState({ loading: true });
    this.apiLayer.getInfoFromApi(
      this.state.model.vatNo,
      function (data) {
        this.isVatVerified++;
        /**
         * Check first if vatNo is write OK and after put second message to complet manually the fields
         */
        if (data.valid === false) {
          this.setState({ messageAlert: true });
          if (this.isVatVerified === 1)
            this.setState({
              textMessage:
                this.lang.get("vatNo") +
                " " +
                this.lang.get("invalid") +
                "! " +
                this.lang.get("tryAgain") +
                ".",
            });
          if (this.isVatVerified === 2)
            this.setState({ textMessage: this.lang.get("insertCompanyInfo") });
          return;
        }
        /**
         * Complete data from Api
         */

        let m = this.state.model;
        m.name = data.company_name ? data.company_name : "";
        let str = data.company_address ? data.company_address : "";
        let sir = str.split("\n");
        const address = sir[0];
        const indexSpace = sir[1].indexOf(" ");
        let code = sir[1].substr(0, indexSpace);
        let city = sir[1].substr(indexSpace).trim();
        m.address.address = address;
        m.address.code = code;
        m.address.city = city;
        /**
         * Succese message for 7 seconds
         */
        this.setState({ loading: false, messageSucces: true });
        const renderTimeout = 7 * 1000;
        setTimeout(() => {
          this.setState({ messageSucces: false });
        }, renderTimeout);
      }.bind(this),
      function (response) {
        /**
         * Check for any error on API
         */

        this.setState({
          loading: false,
          textMessage: this.lang.get("insertCompanyInfo"),
          messageAlert: true,
        });
      }.bind(this)
    );
  }

  /**
   * Change phone number
   * @param {string} value
   */
  handlePhoneNoChange(value) {
    let model = Object.assign({}, this.state.model);
    model.mobile = value;
    this.setState({ model: model });
  }

  handleAddressBlur(event) {
    this.locate();
  }

  /**
   * get current location
   */
  locate() {
    if (this.gmu) {
      var that = this;
      if (
        this.state.model.id === 0 &&
        this.state.model.address.address === ""
      ) {
        this.gmu
          .getCurrentLocation()
          .catch(() => {
            this.gmu.getCurrentLocationGAapi().then((args) => {
              if (args && args.location) {
                that.setLocatedAddress(
                  args.location.lat,
                  args.location.lng,
                  true
                );
              }
            });
          })
          .then((position) => {
            if (position && position.coords) {
              that.setLocatedAddress(
                position.coords.latitude,
                position.coords.longitude,
                true
              );
            }
          });
      } else {
        const addressStr = Address.stringify(this.state.model.address);
        this.gmu.geolocateAddress(addressStr).then((data) => {
          if (data && data.results.length > 0) {
            if (data.results[0].geometry.location_type !== "APPROXIMATE") {
              that.setLocatedAddress(
                data.results[0].geometry.location.lat,
                data.results[0].geometry.location.lng,
                true
              );
            } else {
              this.props.showSmallMessage(
                this.lang.get("addressNotFound"),
                StatusEnum.ERROR
              );
            }
          }
        });
      }
    }
  }

  /**
   * reverse geolocate address from latlng, save the located address for further ussage
   * @param lat
   * @param lng
   */
  setLocatedAddress(lat, lng, setCenter) {
    this.addMapsMarker(lat, lng, setCenter);
    this.gmu
      .geocodeLatLng(lat, lng)
      .catch((args) => {
        console.log(args);
      })
      .then((results) => {
        if (results.length < 1) {
          this.setState({
            errors: this.lang.get("address") + " " + this.lang.get("notFound"),
          });
          return;
        }

        let address = {};
        for (let opt of results[0].address_components) {
          switch (opt.types[0]) {
            case "street_number":
              if (!address.address) {
                address.address = opt.long_name || "";
              } else {
                address.address += " " + opt.long_name || "";
              }
              break;
            case "route":
              if (!address.address) {
                address.address = opt.long_name || "";
              } else {
                address.address = opt.long_name + " " + address.address || "";
              }
              break;
            case "locality":
              address.city = opt.long_name || "";
              break;
            case "country":
              let country = this.cachedData
                .get("countries")
                .find(
                  (country) =>
                    country.name.toUpperCase() === opt.long_name.toUpperCase()
                );
              if (country) {
                address.fk_countryId = country.id;
              }
              break;
            case "postal_code":
              address.code = opt.long_name || "";
              break;
            default:
              break;
          }
        }
        if (!address.code) {
          address.code = "";
        }
        let model = this.state.model;
        model.address = address;
        this.setState({ model: model });
      });
  }

  /**
   * add maps marker if we find the address or show warning
   * @param {number} lat
   * @param {number} lng
   * @param {boolean} setCenter
   */
  addMapsMarker(lat, lng, setCenter) {
    var that = this;
    if (this.marker) {
      this.marker.setMap(this.gmu.map);
      this.gmu.moveMarker(this.marker, lat, lng);
    } else {
      this.marker = this.gmu.addMarker(lat, lng);
    }
    // @ts-ignore
    // eslint-disable-next-line no-undef
    google.maps.event.clearListeners(this.gmu.map, "click");
    this.gmu.map.addListener("click", function (event) {
      let position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      that.marker.setPosition(position);
      that.setLocatedAddress(position.lat, position.lng, false);
    });

    if (setCenter === undefined || (setCenter && setCenter === true)) {
      this.gmu.map.setCenter(this.marker.getPosition());
    }
    return this.marker;
  }

  /**
   * render method
   */
  render() {
    if (this.props.isScriptLoaded) {
      if (!this.gmu) {
        this.gmu = new GoogleMapsUtils({
          parent: this.mapWrapperRef,
        });
        this.locate();
      }
    }

    const { classes } = this.props;
    const countries = this.cachedData.get("countries");

    return (
      <div style={{ padding: 20 }}>
        <Accordion
          className={classes.expandSubcontractors}
          expanded={this.state.expanded}
          onChange={this.handleExpendedChange.bind(this)}
        >
          <AccordionSummary
            classes={{ content: classes.expandHeader }}
            expandIcon={<ExpandMoreIcon fontSize={"large"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.expandEmployees}
          >
            <Typography>
              {this.lang.get("subcontractor") + ": " + this.state.model.name}
            </Typography>
          </AccordionSummary>
          <br /> <br />
          <ValidatorForm onSubmit={this.submit.bind(this)}>
            <Grid container spacing={6}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.noPaddingTop}
              >
                {/**VatNo */}

                <VatNo
                  onBlur={this.vatNoBlur.bind(this)}
                  value={this.state.model.vatNo}
                ></VatNo>

                {/** name  */}
                <TextValidator
                  label={this.lang.get("name")}
                  className={classes.fullWidth}
                  name="name"
                  value={this.state.model.name}
                  onChange={this.handleInputChange.bind(this)}
                  validators={["required"]}
                  errorMessages={[this.lang.get("fieldRequired")]}
                />

                {/** phone number  */}
                <MuiPhoneInput
                  className={classes.fullWidth}
                  defaultCountry="be"
                  required={true}
                  id="mobile"
                  onlyCountries={Config.phonePrefixCountries}
                  label={this.lang.get("mobile")}
                  onChange={this.handlePhoneNoChange.bind(this)}
                  name="mobile"
                  value={this.state.model.mobile}
                  regions={"europe"}
                  validators={["minNumber:5"]}
                  enableLongNumbers
                />

                {/** email  */}
                <TextValidator
                  className={classes.fullWidth}
                  label={this.lang.get("email")}
                  onChange={this.handleInputChange.bind(this)}
                  name="emails"
                  value={this.state.model.emails}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    this.lang.get("fieldRequired"),
                    this.lang.get("emailNotValid"),
                  ]}
                />

                {/** alertinfo  */}
                <TextField
                  variant="filled"
                  name="alertInfo"
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("alertInfo")}
                  className={classes.fullWidth}
                  value={this.state.model.alertInfo || ""}
                />

                <br />
                <br />
                <Divider />
                <br />
                <br />

                {/** address  */}
                <TextField
                  name="address.address"
                  onBlur={this.handleAddressBlur.bind(this)}
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("address")}
                  className={classes.fullWidth}
                  value={this.state.model.address.address}
                />

                {/** city  */}
                <TextField
                  onBlur={this.handleAddressBlur.bind(this)}
                  name="address.city"
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("city")}
                  className={classes.fullWidth}
                  value={this.state.model.address.city}
                />

                {/** code  */}
                <TextField
                  name="address.code"
                  onBlur={this.handleAddressBlur.bind(this)}
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("code")}
                  className={classes.fullWidth}
                  value={this.state.model.address.code}
                />

                {/** country  */}
                <FormControl className={classes.fullWidth}>
                  <InputLabel>{this.lang.get("country")}</InputLabel>
                  <Select
                    onBlur={this.handleAddressBlur.bind(this)}
                    id="address.country"
                    value={this.state.model.address.fk_countryId}
                    onChange={this.handleSelectChange.bind(this)}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        value={country.id}
                        id="address.fk_countryId"
                        key={country.id}
                      >
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <br />
                </FormControl>

                {this.state.messageSucces ? (
                  <Alert severity="success">
                    {this.lang.get("vatNo") + " OK"}
                  </Alert>
                ) : (
                  ""
                )}
                <br />
                <br />

                {super.render()}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.noPaddingTop}
              >
                <div
                  id="mapsWrapped"
                  ref={this.mapWrapperRef}
                  className={classes.gMap}
                ></div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <SubcontractorContract
                  company={this.cachedData.get("company")}
                  subcontractor={this.state.model}
                />
              </Grid>
            </Grid>
          </ValidatorForm>
          {this.state.model.id > 0 ? (
            <FileExplorerMin
              files={this.state.model.files}
              url={UrlEnum.subcontractorFiles}
              deleteUrl={UrlEnum.subcontractorDeleteFiles}
              id={this.state.model.id}
            />
          ) : (
            ""
          )}
        </Accordion>
        {this.state.model.id > 0 ? (
          <Accordion
            className={classes.expandSubcontractors}
            expanded={!this.state.expanded}
            onChange={this.handleExpendedChange.bind(this)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize={"large"} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.expandEmployees}
            >
              <Typography className={classes.heading}>
                {this.lang.get("employees")}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.noPaddingTop}
              >
                <SubcontractorsEmployees
                  subcontractor={this.state.model}
                ></SubcontractorsEmployees>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          ""
        )}
        <MessageDialog
          open={this.state.messageAlert}
          text={this.state.textMessage}
          onResult={this.handleCloseMessage.bind(this)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  scriptLoader(
    "https://maps.google.com/maps/api/js?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&language=en"
  )(EditSubcontrators)
);
