import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { post } from "../Utils/Utils";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress, Button } from "@material-ui/core";
import Lang from "../lang";
import theme from "../Theme/Theme";
import CachedDataSingleton from '../cachedDataSingleton';
import {validEmail} from "../RegExp/regexp";
import { CompanyOptionsEnum } from "../Utils/Utils";

class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
      errors: null,
      success: null,
      renderMessages: true,
      loading: false,
    };
    this.lang = Lang.getInstance();
		this.cachedDataSingleton = CachedDataSingleton.getInstance();
  }

  /**
   * change the state according to the name of the input
   * @param {Object} event
   */
  handleInputChange(event) {
    const name = event.currentTarget.name || event.currentTarget.id;
    const nameParts = name.split(".");
    let model = Object.assign({}, this.state.model);
    let value = event.target.value;
    if (nameParts.length === 1) {
      model[nameParts[0]] = value;
    } else {
      let submodel = model;
      for (let i = 0; i < nameParts.length - 1; i++) {
        submodel = submodel[nameParts[i]];
      }
      submodel[nameParts[nameParts.length - 1]] = value;
    }
    if (name === "email")
    {
      // const validEmail =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;///^\w\-\.\w+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
      if (validEmail.test(value))
        console.log("da");
      else
      console.log("nu");
    }

    this.setState({ model: model });
  }

  /**
   * get value from select
   * @param {Object} event
   */
  handleSelectChange(event) {
    this.handleInputChange(event);
  }

  /**
   * send data to server
   */
  async submit(e, newUrl) {
    e.preventDefault();
    this.setState({ loading: true, success:null, errors:null});

    let url =
      this.state.model.id || this.state.model.id === 0
        ? `${this.props.url}/${this.state.model.id}`
        : this.props.url;
    url = newUrl ? newUrl : url;

    let data = await post(url, this.state.model);

    // hide messages after delay
    const renderTimeout = 10 * 1000;
    setTimeout(() => {
      this.setState({ renderMessages: false });
    }, renderTimeout);

    if (data.errors) {
      this.setState({
        errors:
          data.errors instanceof Array
            ? data.errors.join("<br/>")
            : data.errors,
        renderMessages: true,
        loading: false,
      });
      return;
    }
    let model = this.state.model;

    let company=this.cachedDataSingleton.get("company");
    company.company_options.forEach((element) => {
      if(element.option === CompanyOptionsEnum.TRADEMARGIN)
        element.value= model.tradeMargin;
    });
    this.cachedDataSingleton.setKey("company", company);

    // update model values with new values from server
    for (const key in model) {
      if (data[key] && model[key] !== data[key]) {
        if (typeof model[key] === "number") {
          model[key] = parseFloat(data[key]);
        } else {
          model[key] = data[key];
        }
      }
    }
    this.setState(
      { success: true, renderMessages: true, loading: false, model: model },
      () => {
        if (this.props.submitCallback) {
          this.props.submitCallback(model);
        }
      }
    );
  }

  /**
   * default render
   * render existing errors and other common data
   */
  render() {
    const styles = {
      alert: {
        marginTop: 10,
      },
      loading: {
        width: 20,
        height: 20,
        marginLeft: 10,
      },
    };

    let message = (
      <Button
        style={{
          background: theme.palette.header?.main,
          color: theme.palette.textColorSecondary?.main,
        }}
        variant="contained"
        disabled={this.state.loading}
        type="submit"
      >
        {this.lang.get("save")}
        {this.state.loading ? (
          <CircularProgress color="secondary" style={styles.loading} />
        ) : (
          ""
        )}
      </Button>
    );
    if (this.state.renderMessages) {
      if (this.state.success) {
        message = (
          <Fragment>
            {message}
            <Alert severity="success" style={styles.alert}>
              {this.lang.get("success")}
            </Alert>
          </Fragment>
        );
      }
      if (this.state.errors) {
        message = (
          <Fragment>
            {message}
            <Alert severity="error" style={styles.alert}>
              {this.state.errors}
            </Alert>
          </Fragment>
        );
      }
    }
    return message;
  }
}

FormComponent.prototypes = {
  url: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  submitCallback: PropTypes.func,
};

export default FormComponent;
