import { makeStyles } from "@material-ui/core";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

type Position = {
  x: number, y: number
}
type DraggableProps = {
  initialPos: Position,
  width: number,
  height: number,
  parentOffset:Position,
  children?: any,
  ref?: any
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    border:"1px solid #ccc"
  }
}));

function Draggable(props: DraggableProps, ref:any) {

  useImperativeHandle(ref, () => ({
    getPosition: () => {
      return state;
    }
  }));

  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [dragging , setDragging] = useState(false);

  const [state, setState] = useState({
    position: {
      x: props.initialPos.x,
      y: props.initialPos.y
    },
    rel: {
      x: 0,
      y: 0
    }
  });

  useEffect(()=>{
    if(dragging){

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('touchmove', onTouchMove);

    }
    return ()=>{
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onTouchMove);

    }
  },[dragging]);

  function onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (e.button !== 0) return;

    const ref = wrapperRef.current;
    if(e.currentTarget !== wrapperRef.current) return false;
    if (!ref) return;
    // @ts-ignore
    var pos = ref.getBoundingClientRect();
    const newState = {
      ...state,
      rel: {
        x: e.pageX - pos.left,
        y: e.pageY - pos.top
      }
    };
    setState(newState);
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  }
  function onTouchDown(e: React.TouchEvent<HTMLElement>) {
    // if (e.button !== 0) return;

    const ref = wrapperRef.current;
    if(e.currentTarget !== wrapperRef.current) return false;
    if (!ref) return;
    // @ts-ignore
    var pos = ref.getBoundingClientRect();
    const newState = {
      ...state,
      rel: {
        x: e.changedTouches[0].pageX -pos.left,
        y: e.changedTouches[0].pageY -pos.top
      }
    };
    setState(newState);
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseUp(e: MouseEvent) {
    setDragging(false);
    document.removeEventListener('mousemove', onMouseMove)
    e.stopPropagation();
    e.preventDefault();
  }
  function onTouchUp(e: TouchEvent) {
    setDragging(false);
    document.removeEventListener('touchmove', onTouchMove)
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseMove(e: MouseEvent) {
    if (!dragging) return;
    const x = e.clientX - state.rel.x - props.parentOffset.x;
    const y = e.clientY - state.rel.y - props.parentOffset.y;
    setState({
      ...state,
      position: {
        x: x,
        y: y
      }
    });

    e.stopPropagation()
    e.preventDefault()
  }
  function onTouchMove(e: TouchEvent) {
    if (!dragging) return;
    const x = e.changedTouches[0].clientX -state.rel.x - props.parentOffset.x;
    const y = e.changedTouches[0].clientY -state.rel.y - props.parentOffset.y;
    setState({
      ...state,
      position: {
        x: x,
        y: y
      }
    });

    e.stopPropagation()
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchstart', onTouchUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchstart', onTouchUp);
    }
  }, []);

  return (
    <div
      onMouseDown={onMouseDown}
      onTouchStart={onTouchDown}
      ref={wrapperRef}
      className={classes.wrapper}
      style={{
        width: props.width,
        height: props.height,
        left: state.position.x,
        top: state.position.y
      }}>
      {props.children}
    </div>
  )

}


export default forwardRef(Draggable)
