import React, { Fragment } from "react";
import {
  Cost,
  Estimate,
  Invoice,
  Project as ProjectModel,
  Supplier,
} from "../../Models/Models";
import Lang from "../../lang";
import {
  Theme,
  createStyles,
  withStyles,
  Paper,
  Grid,
  AppBar,
  Tabs,
  Tab,
  withWidth,
  Button,
} from "@material-ui/core";
import {
  CostTypeEnum,
  DocTypes,
  get,
  post,
  UrlEnum,
  UserRolesEnum,
} from "../../Utils/Utils";
import moment from "moment";
import Config from "../../Utils/Config";
import theme from "../../Theme/Theme";
import InvoiceListComplete from "../../DocumentComponents/InvoiceListComplete";
import TabPanel from "../TabPanel";
import SwipeableViews from "react-swipeable-views";
import EstimateListComplete from "../../DocumentComponents/EstimateListComplete";
import TableComponent from "../TableComponent";
import CachedDataSingleton from "../../cachedDataSingleton";
import { checkUserRoles } from "../../Utils/CheckUserRoles";
import EditCosts from "../../DocumentComponents/EditCosts";

const lang = Lang.getInstance();

const styles = (theme: Theme) =>
  createStyles({
    maxWidthFull: {
      maxWidth: "100%",
    },
    listHeader: {
      backgroundColor: "transparent",
      color: "#000",
    },
    extensionPanel: {
      boxShadow: "none",
      borderBottom: "1px solid #ccc",
    },
    invoiceTotals: {
      padding: 10,
      backgroundColor: "rgb(237, 208, 183)",
    },
    otTotals: {
      padding: 10,
      backgroundColor: "rgb(246, 151, 135)",
    },
    costsTotals: {
      padding: 10,
      backgroundColor: "rgb(244, 232, 132)",
    },
    laborCost: {
      padding: 10,
      backgroundColor: "rgb(200, 232, 132)",
    },
    profit: {
      padding: 10,
      backgroundColor: "rgb(94, 164, 255)",
      color: "#fff",
      borderRadius: 3,
    },
    paperCell: {
      marginBottom: 25,
    },
    unpaidBtn: {
      top: 0,
      backgroundColor: theme.palette.error.light,
      padding: 7,
    },
    monthlyBtn: {
      top: 0,
      backgroundColor: theme.palette.warning.light,
      padding: 7,
    },
    workforceLabel: {
      top: 0,
      backgroundColor: theme.palette.info.light,
      padding: 7,
    },
    workforce: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.info.light,
      },
    },
    purchaseLabel: {
      top: 0,
      backgroundColor: theme.palette.successColor?.main,
      padding: 7,
    },
    purchase: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.header?.main,
      },
    },
    monthly: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.warning.light,
      },
    },
    unpaid: {
      backgroundColor: theme.palette.error.light,
    },
    paid: {
      backgroundColor: theme.palette.success.light,
    },
    statusBtn: {
      width: "100%",
    },
    administrativeLabel: {
      top: 0,
      padding: 7,
      borderStyle: "solid",
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderWidth: 0.5,
      borderColor: "#DCDCDC",
      flexGrow: 0.1,
    },
  });

type DocumentProps = {
  theme: Theme;
  classes: any;
  showSmallMessage: Function;
  userRoles: any;
};

type DocumentState = {
  invoicesTabValue: number;
  invoices: Array<Invoice>;
  proformas: Array<Invoice>;
  creditNotes: Array<Invoice>;
  orderTickets: Array<Estimate>;
  orderTicketsTabValue: number;
  estimates: Array<Estimate>;
  subcontractorOrderTickets: Array<Estimate>;
  model: ProjectModel;
  editedCost: Cost | null;
  data: Object;
  sort: string;
  order: string;
};

class DocumentsTables extends React.PureComponent<
  DocumentProps,
  DocumentState
> {
  suppliers: Supplier[];
  cachedData: any;
  startDate: string;
  endDate: string;
  costsUrl: string;

  constructor(props: DocumentProps) {
    super(props);
    this.state = {
      editedCost: null,
      invoices: [],
      proformas: [],
      creditNotes: [],
      orderTickets: [],
      invoicesTabValue: 0,
      orderTicketsTabValue: 0,
      estimates: [],
      subcontractorOrderTickets: [],
      model: new ProjectModel(),
      data: {},
      sort: "docDate",
      order: "desc",
    };
    this.cachedData = CachedDataSingleton.getInstance();
    this.suppliers = this.cachedData.get("suppliers");

    let unpaid = 0;
    let monthly = 0;
    let type = "";

    const crtDate = new Date();
    this.startDate = crtDate.getUTCFullYear() + "-01-01";
    this.endDate = moment().format(Config.momentUSDateFormat);
    if (type === "")
      this.costsUrl = `${UrlEnum.folderCosts}/${this.startDate}/${this.endDate}/${unpaid}/${monthly}`;
    else
      this.costsUrl = `${UrlEnum.folderCosts}/${this.startDate}/${this.endDate}/${unpaid}/${monthly}/${type}`;
  }

  async getData() {
    const perPage = 10;
    const page = 1;

    try {
      let promises = [];
      if (checkUserRoles([UserRolesEnum.ADMIN, UserRolesEnum.ESTIMATES])) {
        promises = [
          get(
            `${UrlEnum.invoices}/${DocTypes.invoice}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.invoices}/${DocTypes.proforma}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.invoices}/${DocTypes.creditNote}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.estimates}/${DocTypes.estimate}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.estimates}/${DocTypes.orderTicket}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.estimates}/${DocTypes.subcontractorOrderTicket}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
        ];
      } else {
        promises = [
          get(
            `${UrlEnum.invoices}/${DocTypes.invoice}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.invoices}/${DocTypes.proforma}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
          get(
            `${UrlEnum.invoices}/${DocTypes.creditNote}/${this.startDate}/${this.endDate}?q=&page=${page}&per_page=${perPage}`
          ),
        ];
      }
      Promise.all(promises).then((values: any) => {
        if (values.length === 6)
          this.setState({
            invoices: values[0].items,
            proformas: values[1].items,
            creditNotes: values[2].items,
            estimates: values[3].items,
            orderTickets: values[4].items,
            subcontractorOrderTickets: values[5].items,
          });
        else if (values.length === 3)
          this.setState({
            invoices: values[0].items,
            proformas: values[1].items,
            creditNotes: values[2].items,
          });
      });
    } catch (ex) {
      console.log("error" + ex);
    }
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * change paid cost status
   * @param cost
   */
  async setPaidCost(cost: Cost) {
    const nextState = cost.paid > 0 ? false : true;
    const response = await post(
      UrlEnum.folderCosts + "/setPaid/" + cost.id + "/" + nextState,
      {}
    );
    if (response.errors) return;
    cost.paid = nextState ? 1 : 0;
    let newModel = { ...this.state.model }; //create duplicate
    if (newModel.costs) {
      let editedCost = newModel.costs.find((el) => el.id === cost.id);
      if (editedCost) {
        editedCost.paid = cost.paid;
        this.setState({ model: newModel });
      }
    }
  }

  private renderInvoices() {
    const { classes } = this.props;
    let invoicesTotals = {
      totalWithVat: 0,
      paid: 0,
      toPay: 0,
    };
    let proformasTotals = 0;
    let creditNotesTotals = 0;
    for (const doc of this.state.invoices) {
      invoicesTotals.totalWithVat += doc.totalWithVat;
      invoicesTotals.paid += doc.totalPaid;
      invoicesTotals.toPay += doc.totalWithVat - doc.totalPaid;
    }
    for (const doc of this.state.proformas) {
      proformasTotals += doc.totalWithVat;
    }
    for (const doc of this.state.creditNotes) {
      creditNotesTotals += doc.totalWithVat;
    }
    return (
      <Grid item md={6} sm={12} xs={12}>
        {/** INVOICES */}

        <Paper className={classes.paperCell}>
          <AppBar
            position="relative"
            style={{ backgroundColor: theme.palette.header?.main }}
          >
            <Tabs
              aria-label="invoiceTabs"
              value={this.state.invoicesTabValue}
              style={{ color: theme.palette.textColorSecondary?.main }}
              onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
                this.setState({ invoicesTabValue: newValue });
              }}
            >
              <Tab
                label={lang.get("invoices")}
                aria-controls="invoices"
                style={{ minWidth: "90px" }}
              />
              <Tab
                label={lang.get("proformas")}
                aria-controls="proofrmas"
                style={{ minWidth: "90px" }}
              />
              <Tab
                label={lang.get("creditNotes")}
                aria-controls="creditNotes"
                style={{ minWidth: "90px" }}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews axis={"x"} index={this.state.invoicesTabValue}>
            <TabPanel value={0} index={0}>
              <InvoiceListComplete
                isDashboard={true}
                select={true}
                invoices={this.state.invoices ? this.state.invoices : null}
                showSmallMessage={this.props.showSmallMessage}
                type={DocTypes.invoice}
                key={this.state.invoices?.length}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
              />
              <br />
              <Grid container>
                <Grid item md={4}>
                  <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                  {invoicesTotals.totalWithVat.toFixed(Config.noOfDecimals)}
                </Grid>
                <Grid item md={4}>
                  <strong>{lang.get("paid")}</strong>: &nbsp;
                  {invoicesTotals.paid.toFixed(Config.noOfDecimals)}
                </Grid>
                <Grid item md={4}>
                  <strong>{lang.get("leftToPay")}</strong>: &nbsp;
                  {invoicesTotals.toPay.toFixed(Config.noOfDecimals)}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={1} index={1}>
              <InvoiceListComplete
                isDashboard={true}
                select={true}
                invoices={this.state.proformas ? this.state.proformas : null}
                showSmallMessage={this.props.showSmallMessage}
                key={this.state.proformas?.length}
                type={DocTypes.proforma}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
              />
              <br />
              <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
              {proformasTotals}
            </TabPanel>
            <TabPanel value={2} index={2}>
              <InvoiceListComplete
                isDashboard={true}
                select={true}
                invoices={
                  this.state.creditNotes ? this.state.creditNotes : null
                }
                showSmallMessage={this.props.showSmallMessage}
                key={this.state.creditNotes?.length}
                type={DocTypes.creditNote}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
              />
              <br />
              <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
              {creditNotesTotals}
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Grid>
    );
  }
  private renderEstimates() {
    const { classes } = this.props;
    let estimatesTotals = {
      totalWithVat: 0,
    };
    let otTotals = 0;
    let sotTotals = 0;
    for (const doc of this.state.estimates) {
      estimatesTotals.totalWithVat += doc.totalWithVat;
    }

    for (const doc of this.state.orderTickets) {
      otTotals += doc.totalWithVat;
    }
    for (const doc of this.state.subcontractorOrderTickets) {
      sotTotals += doc.totalWithVat;
    }

    return (
      <Grid item md={6} sm={12} xs={12}>
        {/** Order tickets */}
        <Paper className={classes.paperCell}>
          <AppBar position="relative">
            <Tabs
              aria-label="orderTicketsTabs"
              value={this.state.orderTicketsTabValue}
              style={{
                backgroundColor: theme.palette.header?.main,
                color: theme.palette.textColorSecondary?.main,
              }}
              onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
                this.setState({ orderTicketsTabValue: newValue });
              }}
            >
              <Tab
                classes={{ root: classes.maxWidthFull }}
                label={lang.get("estimates")}
                style={{ minWidth: "50px" }}
              />
              <Tab
                label={lang.get("orderTickets")}
                style={{ minWidth: "80px" }}
              />
              <Tab
                classes={{ root: classes.maxWidthFull }}
                label={lang.get("subcontractorOrderTickets")}
                style={{ minWidth: "70px" }}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews axis={"x"} index={this.state.orderTicketsTabValue}>
            <TabPanel value={0} index={0}>
              <EstimateListComplete
                isDashboard={true}
                estimates={this.state.estimates ? this.state.estimates : null}
                showSmallMessage={this.props.showSmallMessage}
                type={DocTypes.estimate}
                key={this.state.estimates?.length}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
                select
              />
              <br />
              <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
              {estimatesTotals.totalWithVat}
            </TabPanel>
            <TabPanel value={1} index={1}>
              <EstimateListComplete
                isDashboard={true}
                estimates={
                  this.state.orderTickets ? this.state.orderTickets : null
                }
                showSmallMessage={this.props.showSmallMessage}
                type={DocTypes.orderTicket}
                key={this.state.orderTickets?.length}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
                select
              />
              <br />
              <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
              {otTotals}
            </TabPanel>
            <TabPanel value={2} index={2}>
              <EstimateListComplete
                isDashboard={true}
                estimates={
                  this.state.subcontractorOrderTickets
                    ? this.state.subcontractorOrderTickets
                    : null
                }
                showSmallMessage={this.props.showSmallMessage}
                type={DocTypes.estimate}
                key={this.state.subcontractorOrderTickets?.length}
                classes={{
                  header: classes.listHeader,
                  extensionPanel: classes.extensionPanel,
                }}
                select
              />
              <br />
              <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
              {sotTotals}
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Grid>
    );
  }
  private renderCosts() {
    const { classes } = this.props;
    let costsTableColumns = [
      {
        label: lang.get("supplier"),
        name: "supplierName",
        customRender: (cost: Cost) => {
          const supplier = this.suppliers.find(
            (supplier: Supplier) => supplier.id === cost.fk_SupplierId
          );
          if (supplier) return supplier.name;
          return "";
        },
      },
      { label: lang.get("amount"), name: "amount" },
      { label: lang.get("description"), name: "description" },
      {
        label: lang.get("paid"),
        name: "paid",
        customRender: (cost: Cost) => {
          return (
            <div>
              <Button
                className={cost.paid < 1 ? classes.unpaid : classes.paid}
                onClick={() => this.setPaidCost(cost)}
              >
                {cost.paid < 1 ? lang.get("unpaid") : lang.get("paid")}
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <Grid item md={6} sm={12} xs={12}>
        {/** COSTS */}
        <Paper className={classes.paperCell}>
          <p>&nbsp;</p>
          <h3>{lang.get("costs")}</h3>
          <TableComponent
            isDashboard={true}
            url={this.costsUrl}
            columns={costsTableColumns}
            editedModels={[this.state.editedCost]}
            editCallback={(costModel: Cost) =>
              this.setState({ editedCost: costModel })
            }
            search={false}
            sort={this.state.sort}
            order={this.state.order}
            rowClassCallback={(cost: Cost) => {
              let crtClasses = "";
              if (
                cost.paymentDeadline &&
                moment(
                  cost.paymentDeadline,
                  Config.momentUSDateFormat
                ).isBefore(moment()) &&
                cost.paid < 1
              ) {
                crtClasses += classes.unpaid;
              }
              if (
                (cost.isMonthly && cost.isMonthly > 0) ||
                cost.monthlyCostId
              ) {
                crtClasses += ` ${classes.monthly}`;
              }
              if (cost.type === CostTypeEnum.PURCHASE) {
                crtClasses += ` ${classes.purchase}`;
              }
              if (cost.type === CostTypeEnum.WORKFORCE) {
                crtClasses += ` ${classes.workforce}`;
              }
              return crtClasses;
            }}
            customHeaderContent={
              <Grid container>
                <Grid item md={8}>
                  <div style={{ alignSelf: "flex-start", display: "flex" }}>
                    <label
                      className={`${classes.unpaidBtn} ${classes.infoLabel}`}
                    >
                      {lang.get("unpaid")}
                    </label>
                    <label
                      className={`${classes.monthlyBtn} ${classes.infoLabel}`}
                    >
                      {lang.get("monthly")}
                    </label>
                    <label
                      className={`${classes.workforceLabel} ${classes.infoLabel}`}
                    >
                      {lang.get("workforce")}
                    </label>
                    <label
                      className={`${classes.purchaseLabel} ${classes.infoLabel}`}
                    >
                      {lang.get("purchase")}
                    </label>
                    <label
                      className={`${classes.administrativeLabel} ${classes.infoLabel}`}
                    >
                      {lang.get("administrative")}
                    </label>
                  </div>
                </Grid>
              </Grid>
            }
          />
          {this.state.editedCost ? (
            <EditCosts
              model={this.state.editedCost}
              open={this.state.editedCost !== null}
              onClose={() => {
                this.setState({ editedCost: null });
              }}
              submitCallback={(model: Cost) =>
                this.setState({ editedCost: model })
              }
              showSmallMessage={this.props.showSmallMessage}
              hideClient={true}
            ></EditCosts>
          ) : null}
        </Paper>
      </Grid>
    );
  }

  render() {
    if (this.props.userRoles)
      return this.props.userRoles.map((role: any, index: any) => {
        switch (role.role) {
          case UserRolesEnum.ADMIN:
            return (
              <div key={`${index}_admin`}>
                <Fragment>
                  <Grid container spacing={3}>
                    {this.renderInvoices()}
                    {this.renderEstimates()}
                  </Grid>
                  <Grid container spacing={3}>
                    {this.renderCosts()}
                  </Grid>
                </Fragment>
              </div>
            );

          case UserRolesEnum.INVOICING:
            return (
              <div key={`${index}_invoicing`}>
                <Fragment>
                  <Grid container spacing={3}>
                    {this.renderInvoices()}
                    {this.renderCosts()}
                  </Grid>
                </Fragment>
              </div>
            );
          case UserRolesEnum.ESTIMATES:
            return (
              <div key={`${index}_estimates`}>
                <Fragment>
                  <Grid container spacing={3}>
                    {this.renderEstimates()}
                  </Grid>
                </Fragment>
              </div>
            );
          default:
            return null;
        }
      });
    return null;
  }
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(DocumentsTables)
);
