import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { menuOptionFilteredByRole } from "../../Utils/MenuOptionsFilteredByRole";
import Lang from "../../lang";
import HomeIcon from "@material-ui/icons/Home";
import theme from "../../Theme/Theme";

const lang = Lang.getInstance();

type MenuListProps = {
  color?: string;
  menuButtonClick: any;
  isMobile: any;
};
const useStyles = makeStyles((theme) => ({
  li: {
    paddingTop: "5px",
    paddingBottom: "5px",
    background: "#ffffff00",
    transition: "background .20s ease-out",
    "&:hover": {
      background: "#ffffff12",
      transition: "background .20s ease-in",
      borderLeft: "2px solid rgb(253, 187, 0)",
    },
  },
  title: {
    paddingTop: "2px",
    paddingBottom: "10px",
  },
}));
export default function MenuList(props: MenuListProps) {
  const menuOptions: any = menuOptionFilteredByRole();
  const textColor = props.color ? props.color : "inherit";
  const classes = useStyles();
  return (
    <List>
      <Link to="/" onClick={props.isMobile ? props.menuButtonClick : null}>
        <ListItem className={classes.li} button>
          <ListItemIcon>
            <HomeIcon
              style={{
                color: theme.palette.iconDefault?.main,
                width: "25px",
                height: "25px",
              }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: textColor }}
            primary={lang.get("dashboard")}
          />
        </ListItem>
      </Link>
      <Divider
        style={{ background: theme.palette.textColor?.main, opacity: 0.2 }}
      />

      {menuOptions.PROJECTS ? (
        <Link
          to={menuOptions.PROJECTS.link}
          onClick={props.isMobile ? props.menuButtonClick : null}
        >
          <ListItem
            className={classes.li}
            button
            key={menuOptions.PROJECTS.label}
          >
            <ListItemIcon>{menuOptions.PROJECTS.icon}</ListItemIcon>

            <ListItemText
              style={{ color: textColor }}
              primary={menuOptions.PROJECTS.label}
            />
          </ListItem>
        </Link>
      ) : null}
      <Divider
        style={{ background: theme.palette.textColor?.main, opacity: 0.2 }}
      />
      <ListItem className={classes.title}>
        <p style={{ color: textColor, fontWeight: "bold", opacity: 0.5 }}>
          {lang.get("documents").toUpperCase()}
        </p>
      </ListItem>

      {menuOptions.DOCUMENTS
        ? Object.keys(menuOptions.DOCUMENTS).map((key: string) => (
            <Link
              key={key}
              to={menuOptions.DOCUMENTS[key].link}
              onClick={props.isMobile ? props.menuButtonClick : null}
            >
              <ListItem
                className={classes.li}
                button
                key={menuOptions.DOCUMENTS[key].label}
              >
                <ListItemIcon style={{ color: textColor }}>
                  {menuOptions.DOCUMENTS[key].icon}
                </ListItemIcon>

                <ListItemText
                  style={{ color: textColor }}
                  primary={menuOptions.DOCUMENTS[key].label}
                />
              </ListItem>
            </Link>
          ))
        : null}
      <Divider
        style={{ background: theme.palette.textColor?.main, opacity: 0.2 }}
      />

      <ListItem className={classes.title}>
        <p style={{ color: textColor, fontWeight: "bold", opacity: 0.5 }}>
          {lang.get("hr").toUpperCase()}
        </p>
      </ListItem>
      {menuOptions?.PEOPLE &&
        Object.keys(menuOptions.PEOPLE).map((key: string) => (
          <Link
            key={key}
            to={menuOptions.PEOPLE[key].link}
            onClick={props.isMobile ? props.menuButtonClick : null}
          >
            <ListItem
              className={classes.li}
              button
              key={menuOptions.PEOPLE[key].label}
            >
              <ListItemIcon>{menuOptions.PEOPLE[key].icon}</ListItemIcon>
              <ListItemText
                style={{ color: textColor }}
                primary={menuOptions.PEOPLE[key].label}
              />
            </ListItem>
          </Link>
        ))}
      <Divider
        style={{
          background: theme.palette.textColor?.main,
          opacity: 0.2,
          margin: "10px 0px",
        }}
      />

      {menuOptions?.MORE &&
        Object.keys(menuOptions.MORE).map((key: string) => (
          <Link
            key={key}
            to={menuOptions.MORE[key].link}
            onClick={props.isMobile ? props.menuButtonClick : null}
          >
            <ListItem
              className={classes.li}
              button
              key={menuOptions.MORE[key].label}
            >
              <ListItemIcon>{menuOptions.MORE[key].icon}</ListItemIcon>
              <ListItemText
                style={{ color: textColor }}
                primary={menuOptions.MORE[key].label}
              />
            </ListItem>
          </Link>
        ))}
      <Divider
        style={{ background: theme.palette.textColor?.main, opacity: 0.2 }}
      />
      {menuOptions.COMPANYOVERVIEW ? (
        <Link
          to={menuOptions.COMPANYOVERVIEW.companyOverview.link}
          onClick={props.isMobile ? props.menuButtonClick : null}
        >
          <ListItem
            className={classes.li}
            button
            key={menuOptions.COMPANYOVERVIEW.companyOverview.label}
          >
            <ListItemIcon>
              {menuOptions.COMPANYOVERVIEW.companyOverview.icon}
            </ListItemIcon>

            <ListItemText
              style={{ color: textColor }}
              primary={menuOptions.COMPANYOVERVIEW.companyOverview.label}
            />
          </ListItem>
        </Link>
      ) : null}
    </List>
  );
}
