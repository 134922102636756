import { Button, Divider, Menu, MenuItem, makeStyles } from "@material-ui/core";
import { useState } from "react";
import Lang from "../lang";
import theme from "../Theme/Theme";
import { DocTypes } from "../Utils/Utils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    minWidth: 181,
  },
}));
export default function TagsTextEditor(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (tag) => {
    setAnchorEl(null);
    props.addTags(tag);
  };

  function getBasicMenu() {
    return (
      <>
        <MenuItem onClick={() => handleClose("clientName")} disableRipple>
          {lang.get("clientName")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("clientAddress")} disableRipple>
          {lang.get("clientAddress")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("clientVatNo")} disableRipple>
          {lang.get("clientVatNo")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("companyName")} disableRipple>
          {lang.get("companyName")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("companyAddress")} disableRipple>
          {lang.get("companyAddress")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("totalWithVat")} disableRipple>
          {lang.get("totalWithVat")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("restToPay")} disableRipple>
          {lang.get("restToPay")}
        </MenuItem>
      </>
    );
  }
  function getProjectsDetails() {
    return (
      <>
        <Divider />
        <MenuItem onClick={() => handleClose("date")} disableRipple>
          {lang.get("date")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("projectNo")} disableRipple>
          {lang.get("folders")} {lang.get("number")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("projectDate")} disableRipple>
          {lang.get("folders")} {lang.get("date")}
        </MenuItem>
      </>
    );
  }

  function getEstimateMenu() {
    return (
      <>
        <Divider />
        <MenuItem onClick={() => handleClose("siteAddress")} disableRipple>
          {lang.get("siteAddress")}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleClose("estimateStartDate")}
          disableRipple
        >
          {lang.get("estimate")} {lang.get("startDate")}
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => handleClose("estimateEndDate")} disableRipple>
          {lang.get("estimate")} {lang.get("endDate")}
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => handleClose("estimateNo")} disableRipple>
          {lang.get("estimate")} {lang.get("number")}
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => handleClose("estimateDate")} disableRipple>
          {lang.get("estimate")} {lang.get("date")}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => handleClose("estimateTotalNoVat")}
          disableRipple
        >
          {lang.get("estimate")} {lang.get("totalNoVat")}
        </MenuItem>
      </>
    );
  }
  function getOrderTicketsMenu() {
    return (
      <>
        <Divider />
        <MenuItem onClick={() => handleClose("siteAddress")} disableRipple>
          {lang.get("siteAddress")}
        </MenuItem>{" "}
        <Divider />
        <MenuItem
          onClick={() => handleClose("orderTicketStartDate")}
          disableRipple
        >
          {lang.get("orderTicket")} {lang.get("startDate")}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleClose("orderTicketEndDate")}
          disableRipple
        >
          {lang.get("orderTicket")} {lang.get("endDate")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("orderTicketNo")} disableRipple>
          {lang.get("orderTicket")} {lang.get("number")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleClose("orderTicketDate")} disableRipple>
          {lang.get("orderTicket")} {lang.get("date")}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleClose("orderTicketTotalNoVat")}
          disableRipple
        >
          {lang.get("orderTicket")} {lang.get("totalNoVat")}
        </MenuItem>
      </>
    );
  }
  function getMenuItems() {
    switch (props.docType) {
      case DocTypes.invoice:
        return (
          <>
            {getBasicMenu()}
            {getProjectsDetails()}
          </>
        );
      case DocTypes.liability:
      case DocTypes.notice:
        return getBasicMenu();
      case DocTypes.estimate:
        return (
          <>
            {getBasicMenu()}
            {getEstimateMenu()}
            {getProjectsDetails()}
          </>
        );
      case DocTypes.orderTicket:
      case "companyContract":
        return (
          <>
            {getBasicMenu()}
            {getOrderTicketsMenu()}
            {getProjectsDetails()}
          </>
        );
      default:
        return getBasicMenu();
    }
  }
  return (
    <>
      <Button
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        style={{
          minWidth: 181,
          backgroundColor: theme.palette.textColorSecondary?.main,
          color: "rgba(0, 0, 0, 0.50)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          fontSize: 13,
          margin: "10px 10px 10px 0px",
          minHeight: 43,
          marginTop: -4,
          marginLeft: 5,
        }}
      >
        {lang.get("additionalData")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("")}
        elevation={0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.menuPaper }}
      >
        {getMenuItems()}
      </Menu>
    </>
  );
}
