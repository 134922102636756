import React, { Fragment, PureComponent, RefObject, MouseEvent } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
    withStyles,
    DialogActions,
    DialogContent,
    Theme,
    Button,
    IconButton,
    createStyles,
} from "@material-ui/core";
import Webcam from "react-webcam";
import Lang from "../lang";
import CloseIcon from "@material-ui/icons/Close";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Document as PdfDocument, Page, View, Image as PdfImage, BlobProvider } from '@react-pdf/renderer';
import {Document, Page as DocumentPage} from 'react-pdf'
import moment from 'moment';
import { httpDelete, post, UrlEnum } from "../Utils/Utils";


let lang = Lang.getInstance();

type TakePhotoDialogProps = {
    open: boolean;
    handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
    onClose: (e: React.MouseEvent | null) => void;
    classes: any;
    onFileReady:(file:File | null)=>void;
};

type TakePhotoDialogState = {
    width: number;
    height: number;
    imagesArray: Array<any>;
    step: string;
    activeIndex: number;
    crop: any;
    cropStyle: object;
    pdfDocument: any;
    pdfPath: string;
};

class TakePhotoDialog extends PureComponent<
    TakePhotoDialogProps,
    TakePhotoDialogState
    > {
    webcamRef: RefObject<Webcam>;
    contentWrapperRef: RefObject<HTMLDivElement>;
    stateMachine: { photo: string; crop: string; preview: string; };
    imageCrops: Array<any>;
    pdfFile:File | null;

    constructor(props: TakePhotoDialogProps) {
        super(props);

        this.stateMachine = {
            photo: "photo",
            crop: "crop",
            preview: "preview",
        };

        this.state = {
            width: 720,
            height: 1280,
            imagesArray: [],
            step: this.stateMachine.photo,
            activeIndex: 0,
            crop: {
                height: 400,
                width: 300,
                x: 50,
                y: 50,
            },
            cropStyle: {},
            pdfDocument: null,
            pdfPath: ""
        };

        this.imageCrops = [];
        this.webcamRef = React.createRef();
        this.contentWrapperRef = React.createRef();
        this.pdfFile= null;
    }

    /**
     * set the width and height of webcam
     */
    componentDidMount() {
        if (this.state.width === 720) this.setState({ width: 721 });
    }

    componentDidUpdate() {
        const rect = this.contentWrapperRef.current?.getBoundingClientRect();
        if (!rect) return;
        if (parseInt(rect.width.toString()) !== this.state.width) {
            const width = parseInt(rect.width.toString());
            const height = parseInt(rect.height.toString());

            let crop = this.state.crop;
            crop.x = width/2-this.state.crop.width/2;
            crop.y = height/2-this.state.crop.height/2;
            this.setState({
                width: width,
                height: height,
                crop:crop
            });
        }
    }

    /**
     * save a screenshot of the webcam
     * */
    takePicture(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        const imageSrc = this.webcamRef.current?.getScreenshot();
        let imgs = this.state.imagesArray.slice();
        imgs.push(imageSrc);
        this.setState({ imagesArray: imgs });

        return false;
    }

    /**
     * image loaded for crop
     */
    onImageLoaded(image: any) {
        const aspectRatio = this.state.height / image.height;

        this.setState({
            cropStyle: {
                transform: `scale(${aspectRatio},${aspectRatio})
                )}px)`,
            },
        });
    }

    /**
     * triggered when resize finished
     * @param crop
     * @param pixelCrop
     */
    onCropComplete(crop: any, pixelCrop: any) {
        this.imageCrops[this.state.activeIndex] = crop;
    }

    /**
     * click next button
     */
    nextButtonClick() {
        if (this.state.step === this.stateMachine.photo) {
            this.imageCrops = [];
            for (let i = 0; i < this.state.imagesArray.length; i++) {
                this.imageCrops.push(this.state.crop);
            }
            this.setState({
                step: this.stateMachine.crop,
            });
        }
        if (this.state.step === this.stateMachine.crop) {
            // get cropped images
            let promises = [];
            for (let i = 0; i < this.state.imagesArray.length; i++) {
                const image = this.state.imagesArray[i];
                const crop = this.imageCrops[i];
                const fileName = "page_" + i;
                promises.push(this.getCroppedImg(image, crop, fileName));

            }
            Promise.all(promises).then((values) => {
                // get the pdf document
                const document = this.renderDocument(values);
                // go to preview step
                this.setState({
                    step: this.stateMachine.preview,
                    pdfDocument: document
                });
            });

        }
    }

    /**
     * delete an image from the list
     * @param index
     */
    deleteImage(index: number) {
        let newImageList = this.state.imagesArray.slice();
        newImageList.splice(index, 1);
        const newIndex =
            index === this.state.activeIndex ? 0 : this.state.activeIndex;
        this.setState({ imagesArray: newImageList, activeIndex: newIndex });
    }

    /**
     * click on an image in the list
     * @param index
     */
    clickImage(index: number) {
        this.setState({ activeIndex: index, crop: this.imageCrops[index] });
    }

    /**
     * @param {HTMLImageElement} image - Image File Object
     * @param {Object} crop - crop Object
     * @param {String} fileName - Name of the returned file in Promise
     */
    getCroppedImg(image: any, crop: any, fileName: string) {
        const img = new Image();
        img.src = image;
        return new Promise((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const scaleX = img.naturalWidth / img.width;
                const scaleY = img.naturalHeight / img.height;
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
                if (!ctx) return null;

                ctx.drawImage(
                    img,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                resolve(canvas.toDataURL());
            };
        });
    }

    /**
     * return the pdf document
     * @param images
     */
    renderDocument(images: Array<any>) {
        return (
            <PdfDocument>
                {
                    images.map((image, index) =>
                        <Page key={index}>
                            <View>
                                <PdfImage src={image} />
                            </View>
                        </Page>
                    )
                }
            </PdfDocument>
        );
    }

    /**
     * convert blob to file
     * @param theBlob
     * @param fileName
     */
    public blobToFile(ab: any, fileName:string): File{
        var f = new File([ab], fileName, {type:"application/pdf", lastModified:moment().unix()});
        return f;
    }

    /**
     * get pdf blob
     * @param params
     */
    private pdfBlob(params: any) {
        let blob:Blob = params.blob;
        let loading:boolean = params.loading;
        if(loading) return null;

        blob.arrayBuffer().then((ab:ArrayBuffer)=>{
            const fileName = "scan_"+moment().unix()+".pdf";
            this.pdfFile = this.blobToFile(ab,fileName);

            //send pdf to server for preview
            let fd = new FormData();
            fd.append("file", this.pdfFile);
            if(!this.state.pdfPath){
                post(UrlEnum.postTempFiles,fd).then((response:any)=>{
                    if(response.errors) return;
                    this.setState({pdfPath:response.path});
                });
            }
        });

        return null;
    }

    private onFinishClick(){
        this.props.onFileReady(this.pdfFile);
        this.props.onClose(null);
        httpDelete(UrlEnum.postTempFiles,{filePath:this.state.pdfPath});
    }

    /**
     *
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogContent id="photoDialogContent" style={{padding:0}}>
                    <IconButton className={classes.closeBtn} onClick={this.props.onClose}>
                        <CloseIcon />
                    </IconButton>

                    {/*  PHOTO  */}
                    {this.state.step === this.stateMachine.photo ? (
                        <div
                            id="cameraWrapper"
                            ref={this.contentWrapperRef}
                            className={classes.cameraWrapper}
                        >
                            <Webcam
                                audio={false}
                                width={this.state.width}
                                height={this.state.height}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    facingMode:"environment"
                                }}
                            />
                            <IconButton
                                onClick={this.takePicture.bind(this)}
                                color="secondary"
                                className={classes.takePhotoButton}
                            >
                                <CameraAltIcon />
                            </IconButton>
                        </div>
                    ) : null}

                    {/*  CROP  */}
                    {this.state.step === this.stateMachine.crop ? (
                        <ReactCrop
                            src={this.state.imagesArray[this.state.activeIndex]}
                            crop={this.state.crop}
                            style={this.state.cropStyle}
                            className={classes.crop}
                            onChange={(crop) => {
                                this.setState({ crop: crop });
                            }}
                            onImageLoaded={this.onImageLoaded.bind(this)}
                            onComplete={this.onCropComplete.bind(this)}
                        />
                    ) : null}

                    {/*  PREVIEW  */}
                    {
                        this.state.step === this.stateMachine.preview ?
                        <Fragment>
                            {
                                this.state.pdfPath ?
                                <div className={classes.alignMiddle} style={{width:this.state.width-100 }}>
                                    <Document file={this.state.pdfPath}>
                                        {this.state.imagesArray.map((img, index)=>
                                            <DocumentPage
                                            width={this.state.width-100}
                                            pageNumber={index+1} />
                                        )}
                                    </Document>
                                </div>
                                :
                                <BlobProvider document={this.state.pdfDocument}>
                                    {this.pdfBlob.bind(this)}
                                </BlobProvider>
                            }
                        </Fragment>
                            : null
                    }
                </DialogContent>
                <DialogActions>
                    {
                        this.state.step === this.stateMachine.preview ?
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onFinishClick.bind(this)}
                            >
                                {lang.get("finish")}
                            </Button>
                            :
                            <Fragment>
                                <div className={classes.previewWrapper}>
                                    {this.state.imagesArray.map((img, index) => (
                                        <div
                                            key={index}
                                            className={`${classes.thumb} ${index === this.state.activeIndex ? classes.selected : null
                                                }`}
                                        >
                                            <IconButton
                                                className={classes.imageDeleteBtn}
                                                color="primary"
                                                onClick={() => this.deleteImage(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <img
                                                src={img}
                                                alt="preview"
                                                style={{ height: "100%" }}
                                                onClick={() => this.clickImage(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div style={{width:100}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.step === this.stateMachine.photo}
                                    onClick={() => this.setState({ step: this.stateMachine.photo })}
                                >
                                    {"< " + lang.get("back")}
                                </Button>
                                <p>&nbsp;</p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.nextButtonClick.bind(this)}
                                    disabled={this.state.imagesArray.length === 0}
                                >
                                    {lang.get("next")+ " >"}
                                </Button>
                                </div>
                            </Fragment>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        previewWrapper: {
            height: 200,
            overflowX: "auto",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
        },
        cameraWrapper: {
            width: "100%",
            height: "100%",
            margin: "0 auto",
            position: "relative",
        },
        takePhotoButton: {
            borderRadius: "50%",
            width: 80,
            height: 80,
            border: "3px solid #ff0000",
            backgroundColor: "#ffffff",
            margin: "0 auto 0 auto",
            position: "absolute",
            left: "calc(50% - 40px)",
            bottom: 50,
        },
        thumb: {
            height: 190,
            margin: "0px 7px",
            position: "relative",
        },
        closeBtn: {
            position: "absolute",
            right: 25,
            zIndex: 100,
        },
        crop: {
            "& .ReactCrop__drag-handle": {
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: "#555555",
                marginLeft: -15,
                marginTop: -15,
                marginBottom: -15,
            },
            "& .ReactCrop__drag-handle:after": {
                border: "none",
                backgroundColor: "transparent",
            },
            "& .ord-e, .ord-ne, .ord-se": {
                right: -15,
            },
        },
        selected: {
            border: "3px solid",
            borderColor: theme.palette.header?.main,
        },
        imageDeleteBtn: {
            position: "absolute",
            right: 0,
            top: 0,
        },
        viewer: {
            width: "100%",
            height: "100%"
        },
        alignMiddle:{
            margin: "0 auto",
        }
    });

export default withStyles(styles, { withTheme: true })(TakePhotoDialog);
