import Lang from "../lang";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { createRef, useEffect, useState } from "react";
import {
  isMobile,
  get,
  UrlEnum,
  StatusEnum,
  fromStringTemplate,
} from "../Utils/Utils";
import { DatePicker } from "@material-ui/pickers";
import Config from "../Utils/Config";
import {
  Address,
  Client,
  CustomContentModel,
  Project,
  PVDefinitiveReceptionState,
} from "../Models/Models";
import TextEditor from "../Components/TextEditor";
import FormSubmitButton from "../Forms/FormSubmitButton";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import moment from "moment";
import PreviewPDF from "./PreviewPDF";
import { AssignmentTurnedIn } from "@material-ui/icons";
import PVReceptionHeader from "../DocumentTemplates/PVReceptionHeader";
import ReactDOMServer from "react-dom/server";
import CachedDataSingleton from "../cachedDataSingleton";

const lang = Lang.getInstance();
let submitFunction: any = null;

type PVDefinitiveReceptionProps = {
  company: any;
  client: Client | null;
  setSmallMessage: Function;
  project: Project;
  leftToPay: number | string;
};

const cachedData = CachedDataSingleton.getInstance();

function PVDefinitiveReception(props: PVDefinitiveReceptionProps) {
  const mainCompany = cachedData.get("company");
  const classes = useStyles();
  const [state, setState] = useState(new PVDefinitiveReceptionState());
  const [htmlContent, setHtmlContent] = useState("");
  const [open, setOpen] = useState(false);
  const textEditorRef: any = createRef();
  const [customContent, setCustomContent] = useState<CustomContentModel>(
    new CustomContentModel()
  );
  const [loading, setLoading] = useState(true);
  let url = UrlEnum.pdf.replace("{type}", "pvDefinitiveReception");
  url = url.replace("{id}", "0");

  /**
   *
   */
  useEffect(() => {
    get(UrlEnum.documentTemplates + "/pvDefinitiveReception").then(
      (response) => {
        if (response.errors) {
          alert(response.errors);
          setLoading(false);
          return;
        }
        if (response) {
          if (response.customText) {
            setCustomContent({
              customText: JSON.parse(response.customText),
              appearance: response.appearance,
              color: response.color,
            });
          } else {
            setCustomContent(new CustomContentModel());
          }
        }
        setLoading(false);
      }
    );
  }, []);

  /**
   *
   * @param prop
   * @param value
   */
  function onChange(prop: any, value: any) {
    setState({ ...state, [prop]: value });
  }

  /**
   *
   * @param e
   * @returns
   */
  async function submit(e: any) {
    e.preventDefault();
    setOpen(false);
    if (submitFunction) {
      let data = {
        customText: textEditorRef.current
          ? JSON.stringify(textEditorRef.current.getContent())
          : "",
      };
      setCustomContent({
        ...customContent,
        customText: textEditorRef.current
          ? textEditorRef.current.getContent()
          : null,
      });
      await submitFunction(data);
    }
    generatePVDefinitiveReception();
    return false;
  }

  /**
   *
   */
  const generatePVDefinitiveReception = async () => {
    const html = await renderPVDefinitiveReception(
      props.company,
      props.client,
      props.project
    );
    let header = ReactDOMServer.renderToString(
      <PVReceptionHeader
        appearance={customContent?.appearance}
        company={mainCompany}
        color={customContent?.color}
      />
    );
    if (html !== undefined) {
      const content = ReactDOMServer.renderToString(
        <>
          <div dangerouslySetInnerHTML={{ __html: header }}></div>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </>
      );
      let htmlContent: any = document.createElement("div");
      htmlContent.innerHTML = content;
      const htmlContentString = `<html>
				<head>
					<meta charset='UTF-8'/>
					<style>
            @import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            html{
              font-family:Roboto,Arial, sans-serif;
            },
					</style>
				</head>
				<body>${htmlContent.innerHTML}</body></html>`;
      setHtmlContent(htmlContentString);
    }
  };

  /**
   *
   * @param company
   * @param client
   * @param project
   * @returns
   */
  async function renderPVDefinitiveReception(
    company: { name: string; id: number; address: any },
    client: Client | null,
    project: Project
  ) {
    const response = await get(
      `${UrlEnum.documentTemplates}/pvDefinitiveReception/${company.id}`
    );
    if (response.errors) return "";
    if (Object.keys(response).length === 0) {
      props.setSmallMessage(lang.get("confirmContract"), StatusEnum.ERROR);
      return;
    }
    const generateWarrantyString = () => {
      const labels = [];

      if (state.omniumWarranty)
        labels.push(`<p>- ${lang.get("omniumWarranty")}</p>`);
      if (state.regularWarranty)
        labels.push(`<p>- ${lang.get("regularWarranty")}</p>`);
      if (state.digressiveWarranty)
        labels.push(`<p>- ${lang.get("digressiveWarranty")}</p>`);
      return labels.join("") || "";
    };
    let contentJson = JSON.parse(response.customText);
    const contentState = convertFromRaw(contentJson);
    let customHtml = stateToHTML(contentState);
    customHtml = fromStringTemplate(customHtml, {
      folderNo: project?.folderNo ? project?.folderNo : "",
      year: moment().format("YYYY"),
      title: client?.title ? client?.title : "",
      clientName: client?.name ? client?.name : "",
      clientAddress: client?.address ? Address.stringify(client?.address) : "",
      companyName: company?.name ? company?.name : "",
      warranty: generateWarrantyString(),
      date: state.date
        ? moment(state.date).format(Config.momentEUDateFormat)
        : moment().format(Config.momentEUDateFormat),
      workSiteAddress: Address.stringify(project?.site_address),
      restToPay: props?.leftToPay || "",
    });
    return customHtml;
  }

  /**
   *
   * @returns
   */
  function renderPVOptions() {
    return (
      <Grid container spacing={3} style={{ marginBottom: 20 }}>
        <Grid item xs={12} md={12} xl={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          xl={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <FormControlLabel
            value="omniumWarranty"
            control={
              <Checkbox
                checked={state.omniumWarranty}
                onChange={(e) => {
                  onChange("omniumWarranty", e.target.checked);
                }}
              />
            }
            label={lang.get("omniumWarranty")}
            labelPlacement="end"
          />
          <FormControlLabel
            value="regularWarranty"
            control={
              <Checkbox
                checked={state.regularWarranty}
                onChange={(e) => {
                  onChange("regularWarranty", e.target.checked);
                }}
              />
            }
            label={lang.get("regularWarranty")}
            labelPlacement="end"
          />
          <FormControlLabel
            value="digressiveWarranty"
            control={
              <Checkbox
                onChange={(e) => {
                  onChange("digressiveWarranty", e.target.checked);
                }}
                checked={state.digressiveWarranty}
              />
            }
            label={lang.get("digressiveWarranty")}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} md={12} xl={6}>
          <DatePicker
            className={classes.datePicker}
            openTo="year"
            fullWidth
            format={Config.momentEUDateFormat}
            variant="inline"
            views={["year", "month", "date"]}
            label={lang.get("date")}
            value={state.date}
            onChange={(event) => {
              onChange("date", event);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * Close the modal
   */
  function closeModal() {
    setOpen(false);
    setState(new PVDefinitiveReceptionState());
  }

  return (
    <>
      <ListItem button onClick={() => setOpen(true)}>
        <ListItemIcon>
          <AssignmentTurnedIn />
        </ListItemIcon>
        <ListItemText primary={lang.get("pvDefinitiveReception")} />
      </ListItem>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Dialog
            fullScreen={isMobile()}
            maxWidth="lg"
            open={open}
            onClose={closeModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <div style={{ flexGrow: 2 }}>
                {lang.get("pvDefinitiveReception")}
              </div>
            </DialogTitle>
            <DialogContent style={{}}>
              <>
                {renderPVOptions()}
                <TextEditor
                  height={450}
                  fontSize={15}
                  docType="pvDefinitiveReception"
                  initialState={customContent.customText}
                  ref={textEditorRef}
                />
              </>
            </DialogContent>
            <DialogActions>
              <Grid style={{ margin: 13 }}>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={"pvDefinitiveReception"}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={
                      UrlEnum.documentTemplates + "/" + "pvDefinitiveReception"
                    }
                  />
                </form>
              </Grid>
            </DialogActions>
          </Dialog>
          {htmlContent && (
            <PreviewPDF
              open={htmlContent.length > 0}
              onClose={() => {
                setHtmlContent("");
                window.location.reload();
              }}
              htmlContent={htmlContent}
              footerHtml={""}
              url={`${url}/${props?.project?.id || null}`}
              setSmallMessage={props.setSmallMessage}
              emailData={{ to: "", subject: "", message: "" }}
            />
          )}
        </>
      )}
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  datePicker: {
    "& .MuiInputBase-root": {
      border: "1px solid #ced4da", // Add border color
      borderRadius: "4px", // Add border radius
      padding: "8px", // Add padding for better appearance
    },
    "& .Mui-focused": {
      borderColor: theme.palette.primary.main, // Change color on focus
    },
    "& .MuiInputBase-input": {
      padding: "8px", // Adjust padding inside the input
    },
  },
}));

export default PVDefinitiveReception;
