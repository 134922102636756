import React from "react";
import { ResponsiveLine } from "@nivo/line";
import Lang from "../../../lang";
import CachedDataSingleton from "../../../cachedDataSingleton";
import { InputLabel} from "@material-ui/core";
import { graphColors, monthsName } from "../../../Utils/Utils";
import WithChartsFilters from "./WithChartsFilters";

type OverviewInvoicesCostsProfitProps = {
  overview: any;
  startMonth: number;
  endMonth: number;
  selectedStartYear: number;
  selectedEndYear: number;
};
type Coordinates = {
  x: any;
  y: any;
};

function OverviewInvoicesCostsProfit(props: OverviewInvoicesCostsProfitProps) {
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = props.overview ? props.overview : cachedData.get("overview");
  const invoices = overview.invoices;
  const costs = overview.costs;

  let invoiceData: {
    id: string | number;
    color: string;
    data: Array<Coordinates>;
  } = {
    id: lang.get("invoices") + " - Euro",
    color: graphColors().colors.invoices,
    data: []
  };
  let costData: {
    id: string | number;
    color: string;
    data: Array<Coordinates>;
  } = {
    id: lang.get("costs") + " - Euro",
    color: graphColors().colors.costs,
    data: []
  };
  let profitData: {
    id: string | number;
    color: string;
    data: Array<Coordinates>;
  } = {
    id: lang.get("profit") + " - Euro",
    color: graphColors().colors.profit,
    data: []
  };

  /**
   *
   * @returns Returns an array with the data formatted for a Line chart
   */
  function formatChartData() {
    let graphData = [];
    let index = props.startMonth ? props.startMonth : 0;
    const invoicesLength =
      12 * (props.selectedEndYear - props.selectedStartYear) +
      props.endMonth +
      1;

    for (index; index < invoicesLength; index++) {
      const coords: Coordinates = {
        x:
          monthsName()[
            index < 12 ? index : index < 24 ? index - 12 : index % 12
          ] +
          " " +
          (index < 12
            ? props.selectedStartYear
            : props.selectedStartYear + Math.floor(index / 12)),
        y:
          invoices && invoices.months && invoices.months[index]
            ? invoices.months[index]
            : null
      };
      invoiceData.data.push(coords);
    }
    index = props.startMonth ? props.startMonth : 0;
    for (index; index < invoicesLength; index++) {
      const coords: Coordinates = {
        x:
          monthsName()[
            index < 12 ? index : index < 24 ? index - 12 : index % 12
          ] +
          " " +
          (index < 12
            ? props.selectedStartYear
            : props.selectedStartYear + Math.floor(index / 12)),
        y:
          costs && costs.months && costs.months[index]
            ? costs.months[index]
            : null
      };
      costData.data.push(coords);
    }

    index = props.startMonth ? props.startMonth : 0;
    for (index; index < invoicesLength; index++) {
      const coords: Coordinates = {
        x:
          monthsName()[
            index < 12 ? index : index < 24 ? index - 12 : index % 12
          ] +
          " " +
          (index < 12
            ? props.selectedStartYear
            : props.selectedStartYear + Math.floor(index / 12)),
        y:
          invoices && invoices.months[index] && costs && costs.months[index]
            ? invoices.months[index] - costs.months[index]
            : null
      };
      profitData.data.push(coords);
    }

    graphData.push(invoiceData);
    graphData.push(costData);
    graphData.push(profitData);
    return graphData;
  }
  const chartData = formatChartData();
  return (
    <>
      <InputLabel
        style={{
          color: "#000",
          marginBottom: 5,
          fontSize: 15,
          justifyContent: "center",
          alignContent: "center",
          display: "flex"
        }}
      >
        {lang.get("overview") +
          " - " +
          lang.get("invoices") +
          ", " +
          lang.get("costs") +
          ", " +
          lang.get("profit")}
      </InputLabel>
          {/** CHART */}
          <div style={{ height: 300, marginBottom: 10 }}>
            <ResponsiveLine
              colors={[
                graphColors().colors.invoices,
                graphColors().colors.costs,
                graphColors().colors.profit
              ]}
              data={chartData}
              margin={{ top: 10, right: 110, bottom: 100, left: 70 }}
              axisTop={null}
              axisRight={null}
              curve="cardinal"
              xScale={{
                type: "point"
              }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false
              }}
              axisBottom={{
                tickRotation: 45,
                legend: lang.get("date"),
                legendOffset: 90,
                legendPosition: "middle"
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: lang.get("amount"),
                legendOffset: -60,
                legendPosition: "middle"
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 90,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
            ></ResponsiveLine>
          </div>
    </>
  );
}

export default WithChartsFilters(OverviewInvoicesCostsProfit,"costProfit");
