import React, { Fragment } from "react";
import Lang from "../lang";
import {
  withStyles,
  FormControlLabel,
  Checkbox,
  Theme,
  createStyles,
  Button,
} from "@material-ui/core";
import moment from "moment";
import Config from "../Utils/Config";
import SearchBar from "../Components/SearchBar";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { Link, useParams } from "react-router-dom";
import DrawerLayout from "../Pages/DrawerLayout";
import theme from "../Theme/Theme";
const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    drawerContent: {
      padding: "0 25px",
    },
  });

const lang = Lang.getInstance();

type DocumentsLayoutProps = {
  onChange: (
    startDate: string,
    endDate: string,
    search: string,
    page: number
  ) => void;
  onChangeRecurrent?: (recurrent: boolean) => void;
  classes: any;
  isMobile: boolean;
  totalDocCount?: number;
  title?: string;
  drawerChildren?: JSX.Element;
  newDocLink: string;
  newDocClick?: (event: any) => void;
  type: string;
  pagination?: boolean;
  match: any;
  startDate?: string;
  endDate?: string;
  search?: string;
  page?: number;
  perPage?: number;
  hideAppbar?: boolean;
  params?: any;
};

type DocumentsLayoutState = {
  drawerOpen: boolean;
  recurrent: boolean;
  startDate: string;
  endDate: string;
  search: string;
  page: number;
  perPage: number;
};

/**
 *
 */
class DocumentsLayoutClass extends React.Component<
  DocumentsLayoutProps,
  DocumentsLayoutState
  > {
  crtDate: Date;
  lang: any;
  state: DocumentsLayoutState;
  classes: any;

  /**
   * constructor
   * @param {Object} props
   */
  constructor(props: DocumentsLayoutProps) {
    super(props);
    this.crtDate = new Date();
    this.classes = this.props.classes;
    const crtMonth = this.crtDate.getMonth();
    const crtDateString = moment().format(Config.momentUSDateFormat);

    const startDate =
      this.props.startDate ?
        this.props.startDate :
        this.props.params.startDate ?
          this.props.params.startDate :
          crtMonth > 6 ? (this.crtDate.getUTCFullYear()) + "-01-01" : (this.crtDate.getUTCFullYear() - 1) + "-01-01"

    const endDate =
      this.props.endDate ? this.props.endDate :
        this.props.params.endDate ?
          this.props.params.endDate : crtDateString;

    this.state = {
      drawerOpen: !this.props.isMobile,
      recurrent: false,
      startDate: startDate,
      endDate: endDate,
      search: this.props.search || "",
      page: this.props.page || 1,
      perPage: this.props.perPage || 50,
    };
    this.lang = Lang.getInstance();
  }



  /**
   * open or close left side menu
   */
  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: any) {
    if (prevProps.startDate !== this.state.startDate) {
      this.props.onChange(
        this.state.startDate,
        this.state.endDate,
        this.state.search,
        this.state.page
      );
    }
    if (prevProps.endDate !== this.state.endDate) {
      this.props.onChange(
        this.state.startDate,
        this.state.endDate,
        this.state.search,
        this.state.page
      );
    }

  }

  /**
   *
   */
  onChangeStartDate(momentDate: MaterialUiPickersDate) {

    if (momentDate === null) return false;
    const md = momentDate as moment.Moment;

    if (!md.isValid()) return false;
    if (md.isAfter(moment(this.state.endDate))) return false;
    this.setState({ startDate: md.format(Config.momentUSDateFormat) });
    return false;
  }

  /**
   *
   */
  onChangeEndDate(momentDate: MaterialUiPickersDate) {
    if (momentDate === null) return false;
    const md = momentDate as moment.Moment;

    if (!md.isValid()) return false;
    if (md.isBefore(moment(this.state.startDate))) return false;
    this.setState({ endDate: md.format(Config.momentUSDateFormat) });
    return false;
  }

  /**
   * search change
   */
  onSearchChange(event: React.FormEvent<HTMLInputElement>): any {
    const target = event.target as HTMLInputElement;
    this.setState({ search: target.value });
  }

  /**
   *
   */
  searchSubmit(e: React.FormEvent): any {
    e.preventDefault();
    this.props.onChange(
      this.state.startDate,
      this.state.endDate,
      this.state.search,
      this.state.page
    );
  }

  /**
   * show recurrent invoices
   * @param {*} event
   */
  handleRecurrentChange(event: React.FormEvent<HTMLInputElement>): any {
    const target = event.target as HTMLInputElement;
    this.setState({ recurrent: target.checked }, () => {
      if (!this.props.onChangeRecurrent) return;
      this.props.onChangeRecurrent(this.state.recurrent);
    });
  }

  /**
   * change pagination
   * @param {*} event
   * @param {*} value
   */
  changePage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ page: value }, () => {
      if (!this.props.onChange) return;
      this.props.onChange(
        this.state.startDate,
        this.state.endDate,
        this.state.search,
        this.state.page
      );
    });
  }

  renderByType() {
    switch (this.props.type) {
      case "invoice":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.recurrent}
                style={{ color: theme.palette.header?.main }}
                onChange={this.handleRecurrentChange.bind(this)}
                name="recurrent"
              />
            }
            label={this.lang.get("recurrentInvoice")}
          />
        );
      default:
        return null;
    }
  }

  renderDrawerChildren() {
    return (
      <div className={this.classes.drawerContent}>
        <h4>{this.lang.get("filter")}</h4>
        <SearchBar
          onChange={this.onSearchChange.bind(this)}
          onSubmit={this.searchSubmit.bind(this)}
          value={this.state.search}
        />
        <p></p>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.props.onChange(
              this.state.startDate,
              this.state.endDate,
              this.state.search,
              this.state.page
            );
            return false;
          }}
        >
          <KeyboardDatePicker
            autoOk={true}
            allowKeyboardControl
            disableToolbar
            variant="inline"
            name="startDate"
            label={this.lang.get("startDate")}
            value={this.state.startDate}
            className={this.classes.textField}
            format={Config.momentEUDateFormat}
            // onAccept={(date: MaterialUiPickersDate) => {
            //   const md = date as moment.Moment;
            //   this.props.onChange(
            //     this.state.startDate,
            //     this.state.endDate,
            //     this.state.search,
            //     this.state.page
            //   );
            // }}
            onChange={this.onChangeStartDate.bind(this)}
            InputAdornmentProps={{
              position: "start",
              variant: "outlined",
            }}
          />
        </form>
        <p></p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onChange(
              this.state.startDate,
              this.state.endDate,
              this.state.search,
              this.state.page
            );
            return false;
          }}
        >
          <KeyboardDatePicker
            autoOk={false}
            allowKeyboardControl
            disableToolbar
            variant="inline"
            name="endDate"
            label={this.lang.get("endDate")}
            value={this.state.endDate}
            className={this.classes.textField}
            format={Config.momentEUDateFormat}
            // onAccept={(date: MaterialUiPickersDate) => {
            //   const md = date as moment.Moment;
            //   this.props.onChange(
            //     this.state.startDate,
            //     this.state.endDate,
            //     this.state.search,
            //     this.state.page
            //   );
            // }}
            InputAdornmentProps={{
              position: "start",
              variant: "outlined",
            }}
            onChange={this.onChangeEndDate.bind(this)}
          />
        </form>
        <p></p>
        {this.renderByType()}
        {this.props.drawerChildren ? this.props.drawerChildren : ""}
      </div>
    );
  }

  /**
   * render method
   */
  render() {
    const addBtnLabel = `${lang.get("add")} ${this.props.title}`;
    return (
      <>
        <DrawerLayout
          drawerChildren={this.renderDrawerChildren()}
          open={this.state.drawerOpen}
          handleDrawerClose={this.toggleDrawer.bind(this)}
          title={this.props.title}
          drawerWidth={300}
          menu={
            <>
              <Button color="inherit">
                <Link
                  to={this.props.newDocLink}
                  onClick={
                    this.props.newDocClick
                      ? this.props.newDocClick
                      : (e: any) => { }
                  }
                >
                  <ControlPointIcon />
                  &nbsp; {this.props.isMobile ? null : addBtnLabel}
                </Link>
              </Button>
            </>
          }
        >
          <>
            {this.props.children}
            <p></p>
            {this.props.hideAppbar || this.props.pagination === false ? null : (
              <Pagination
                count={Math.ceil(
                  (this.props.totalDocCount ? this.props.totalDocCount : 0) /
                  this.state.perPage
                )}
                page={this.state.page}
                onChange={this.changePage.bind(this)}
              />
            )}
            <p></p>
          </>
        </DrawerLayout>
      </>
    );
  }
}

function DocumentsLayout(props: DocumentsLayoutProps) {
  const params = useParams();
  return (
    <DocumentsLayoutClass {...props} params={params} />
  )
}

export default withStyles(styles)(DocumentsLayout);
