import { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import { Service } from "../Models/Models";
import {
  TextField,
  Collapse,
  withStyles,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import Lang from "../lang";
import FormSubmitButton from "../Forms/FormSubmitButton";
import { UrlEnum } from "../Utils/Utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";

class EditService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
      openService: props.open || false,
    };
    this.submitFunction = null;
    this.lang = Lang.getInstance();
  }

  /**
   * click on the body of the service
   * @param {*} e
   */
  handleOpenService(e) {
    e.preventDefault();
    this.setState({ openService: !this.state.openService });
    return false;
  }

  /**
   *
   * @param {*} event
   */
  handleInputChange(event) {
    const name = event.currentTarget.name || event.currentTarget.id;
    let model = Object.assign({}, this.state.model);
    let value = event.target.value;
    model[name] = value;
    model.totalPrice = Service.calculateTotalServicePrice(model);

    // just because it exists on the server
    model.price = model.totalPrice;
    this.setState({ model: model });

    return false;
  }

  /**
   *
   * @param {*} service
   */
  submitCallBack(service) {
    this.props.onEdit(service);
  }

  submit() {
    if (this.submitFunction) {
      return this.submitFunction(this.state.model);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div style={{ flexGrow: 1, marginTop: 12 }}>
          <div
            className={classes.listItemTextWrapper}
            onClick={this.handleOpenService.bind(this)}
          >
            <ListItemText
              primary={this.state.model.name}
              classes={{ primary: classes.listItemText }}
              style={{ marginTop: 0 }}
            />
            <span className={classes.price}>
              {this.lang.get("totalNoVat")}: {this.state.model.totalPrice}
            </span>
          </div>
          <Collapse
            in={this.state.openService}
            unmountOnExit
            className={classes.serviceFormWrapper}
          >
            <ValidatorForm onSubmit={this.submit.bind(this)}>
              <TextValidator
                label={this.lang.get("name")}
                fullWidth
                name="name"
                value={this.state.model.name}
                onChange={this.handleInputChange.bind(this)}
                validators={["required"]}
              />

              <TextField
                name="description"
                onChange={this.handleInputChange.bind(this)}
                label={this.lang.get("description")}
                fullWidth
                value={this.state.model.description}
              />

              {/** materialsCost  */}
              <TextField
                type="number"
                name="materialsCost"
                onChange={this.handleInputChange.bind(this)}
                label={this.lang.get("materialsCost")}
                fullWidth
                value={this.state.model.materialsCost}
              />

              {/** laborCost  */}
              <TextField
                type="number"
                required
                name="laborCost"
                onChange={this.handleInputChange.bind(this)}
                label={this.lang.get("laborCost")}
                fullWidth
                value={this.state.model.laborCost}
              />

              {/** tradeMargin  */}
              <TextField
                type="number"
                name="tradeMargin"
                required
                onChange={this.handleInputChange.bind(this)}
                label={this.lang.get("tradeMargin")}
                fullWidth
                value={this.state.model.tradeMargin}
              />

              {/** um  */}
              <TextField
                name="um"
                required
                onChange={this.handleInputChange.bind(this)}
                label={this.lang.get("measureUnit")}
                fullWidth
                value={this.state.model.um}
              />
              <br />
              <br />
              <FormSubmitButton
                getSubmit={(fct) => (this.submitFunction = fct)}
                submitCallback={this.submitCallBack.bind(this)}
                url={UrlEnum.services}
              />
            </ValidatorForm>
          </Collapse>
        </div>
        <IconButton onClick={this.handleOpenService.bind(this)}>
          {!this.state.openService ? <ExpandMoreIcon /> : <ExpandLess />}
        </IconButton>
      </>
    );
  }
}

const styles = (theme) => ({
  listItemText: {
    fontSize: "10pt",
    textTransform: "capitalize",
    marginLeft: 10,
    marginTop: 0,
    marginBottom: 10,
    display: "block",
    flexGrow: 0,
  },
  listItemTextWrapper: {
    display: "flex",
    width: "100%",
  },
  serviceFormWrapper: {
    // backgroundColor:"#e8eef5",
    padding: 15,
  },
  price: {
    marginLeft: 10,
    marginRight: 10,
    textAlign: "right",
  },
});

EditService.propTypes = {
  service: PropTypes.object,
  open: PropTypes.bool,
  model: PropTypes.object.isRequired,
  url: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditService);
