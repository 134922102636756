import { useReducer, useState, ChangeEvent, useEffect } from 'react';
import { TextField, makeStyles, Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { TimeTrackingTypeValue, TIMETRAKING_TYPES, TimeTrackingTypeEnum } from './NewManualTimetracking';
import Lang from "../../lang";
import { Client, Estimate, Project, Subcontractor, Employee } from '../../Models/Models';
import CachedDataSingleton from "../../cachedDataSingleton";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Config from '../../Utils/Config';
import moment from 'moment';
import { get, StatusEnum, UrlEnum } from '../../Utils/Utils';
import theme from "../../Theme/Theme";
type TrimetrackingReportFormProps = {
    showSmallMessage: (message: string, type?: string) => void;
    onSubmit: (data: any) => void;
};

//module consts
const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();
const useStyles = makeStyles((theme: any) => ({
    spacing: {
        marginTop: 15
    }
}));


/**
 *
 * @param props
 */
function TimetrackingReportForm(props: TrimetrackingReportFormProps) {
    let clients = cachedData.get("clients");

    const subcontractors = cachedData.get("subcontractors");
    const classes = useStyles(props);

    const [timeTrackingType, setTypeTrackingType] = useState(TimeTrackingTypeEnum.project);
    const [selectedSubcontractor, setSelectedSubcontractor] = useState<Subcontractor | undefined>(undefined);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
    let startDateInitialVal = moment();
    startDateInitialVal.set({
        date: 1
    });

    const [startEndDate, setStartEndDate] = useState({
        startDate: startDateInitialVal,
        endDate: moment()
    });
    const [orderTickets, setOrderTickets] = useState([]);
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [pause, setPause] = useState("");
    const [address, setAddress] = useState("");

    /**
     * get initial ordertickets
     */
    const getOrdertickets = async () => {
        const url = `${UrlEnum.estimates}/orderticket?page=1&per_page=25`;
        const response = await get(url);
        if (response.errors) {
            props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
            return;
        }
        response.items.unshift(new Estimate()); //add new empty estimate for autocomplete reset
        setOrderTickets(response.items);
    };

    /**
     * get projects
     */
    const getProjects = async () => {
        const startPrjDate = startEndDate.endDate.clone().subtract(5, "years");
        const url = `${UrlEnum.projects}/${startPrjDate.format(Config.momentUSDateFormat)}/${startEndDate.endDate.format(Config.momentUSDateFormat)}?page=1&per_page=1000`;
        const response = await get(url);
        if (response.errors) {
            props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
            return;
        }
        //add new empty Project for autocomplete reset
        let emptyPrj = new Project();
        emptyPrj.client = new Client();
        response.items.unshift(emptyPrj);
        setProjects(response.items);
    };

    /**
     * get employees
     */
    const getEmployees = async () => {
        if (!selectedSubcontractor) return;
        const url = `${UrlEnum.subcontractors}/employees/${selectedSubcontractor?.id}`;
        const response = await get(url);
        if (response.errors) {
            props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
            return;
        }
        if (response && response.length > 0) {
            setEmployees(response);
        }
    };

    const getCompanyOptions = async () => {
        const url = UrlEnum.companyOptions;
        const response = await get(url);
        if (!response.errors) {
            setPause(response.timeTrackingPause);
        }
    };

    // get initial data
    useEffect(() => {
        getOrdertickets();
        getProjects();
        getCompanyOptions();
        clients.unshift(new Client()); //add new empty client for autocomplete reset
    }, []);

    // get projects when start or end date changes
    useEffect(() => {
        getProjects();
    }, [startEndDate.endDate]);

    // get employees when selectedSubcontractor changes
    useEffect(() => {
        getEmployees();
    }, [selectedSubcontractor]);

    const initialState: TimeTrackingTypeValue = {
        client: null,
        project: null,
        orderTicket: null
    };

    const reducer = (state: TimeTrackingTypeValue, action: { type: TIMETRAKING_TYPES, value: any; }): TimeTrackingTypeValue => {
        switch (action.type) {
            case TIMETRAKING_TYPES.PROJECT:
                return { client: null, project: action.value, orderTicket: null };
            case TIMETRAKING_TYPES.ORDER_TICKET:
                return { client: null, project: null, orderTicket: action.value };
            case TIMETRAKING_TYPES.CLIENT:
                return { client: action.value, project: null, orderTicket: null };
            default: return { client: null, project: null, orderTicket: null };
        }
    };

    function renderByType() {
        switch (timeTrackingType) {
            case TimeTrackingTypeEnum.project:
                return (<Autocomplete
                    fullWidth
                    id="project"
                    options={projects}
                    value={timeTrackingTypeValues.project}
                    getOptionLabel={(project: any) =>
                        project.client.name + (project.title ? " (" + project.title + ")" : " " + (project.description || ""))
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={lang.get("folder")} />
                    )}
                    onChange={(event: ChangeEvent<{}>, value: any) => {
                        if (value === null) dispatch({ type: TIMETRAKING_TYPES.PROJECT, value: projects[0] });
                        dispatch({ type: TIMETRAKING_TYPES.PROJECT, value: value });

                    }}
                />);
            case TimeTrackingTypeEnum.orderTicket:
                return (<Autocomplete
                    fullWidth
                    id="orderTicket"
                    options={orderTickets}
                    value={timeTrackingTypeValues.orderTicket}
                    getOptionLabel={(ot: Estimate) =>
                        ot.client.id ?
                            ot.client.name + " (" + ot.estimateNo + ")"
                            : ""
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={lang.get("orderTicket")} />
                    )}
                    onChange={(event: ChangeEvent<{}>, value: any) => {
                        if (value === null) dispatch({ type: TIMETRAKING_TYPES.ORDER_TICKET, value: orderTickets[0] });
                        dispatch({ type: TIMETRAKING_TYPES.ORDER_TICKET, value: value });
                    }}
                />);
            case TimeTrackingTypeEnum.client:
                return (<Autocomplete
                    fullWidth
                    id="client"
                    options={clients}
                    value={timeTrackingTypeValues.client}
                    getOptionLabel={(client: Client) => client.name}
                    renderInput={(params) => (
                        <TextField {...params} label={lang.get("client")} />
                    )}
                    onChange={(event: ChangeEvent<{}>, value: any) => {
                        if (value === null) dispatch({ type: TIMETRAKING_TYPES.CLIENT, value: clients[0] });
                        dispatch({ type: TIMETRAKING_TYPES.CLIENT, value: value });
                    }}
                />);
            default: return null;
        }
    }

    const [timeTrackingTypeValues, dispatch] = useReducer(reducer, initialState);

    const handleChangeTimeTrackingType = (event: any, value: string) => {
        setTypeTrackingType(value);
    };

    /**
     * send data to report component
     */
    const submitFilters = () => {
        const data: any = {
            startDate: startEndDate.startDate.format(Config.momentUSDateFormat),
            endDate: startEndDate.endDate.format(Config.momentUSDateFormat)
        }
        if (selectedSubcontractor) {
            data.subcontractor = selectedSubcontractor.id;
            if (selectedEmployee) {
                data.employee = selectedEmployee.id;
            }
        }
        if (timeTrackingTypeValues) {
            if (timeTrackingTypeValues.project) {
                data.project = timeTrackingTypeValues.project.id;
            }

            if (timeTrackingTypeValues.orderTicket) {
                data.orderTicket = timeTrackingTypeValues.orderTicket.id;
            }

            if (timeTrackingTypeValues.client) {
                data.client = timeTrackingTypeValues.client.id;
            }
        }
        if (address) {
            data.address = address;
        }
        if (pause) {
            data.pause = pause;
        }
        props.onSubmit(data);
    };

    return (
        <>
            <Grid container spacing={7}>
                <Grid item md={6} sm={6} xs={12}>

                    <KeyboardDatePicker
                        fullWidth
                        label={lang.get("startDate")}
                        value={startEndDate.startDate}
                        onChange={(date) => {
                            if (!date) return false;
                            setStartEndDate({ ...startEndDate, startDate: date as moment.Moment });
                        }
                        }
                        format={Config.momentEUDateFormat}
                    />

                    <KeyboardDatePicker
                        style={{ marginTop: 10, marginBottom: 10 }}
                        fullWidth
                        label={lang.get("endDate")}
                        value={startEndDate.endDate}
                        onChange={(date) => {
                            if (!date) return false;
                            setStartEndDate({ ...startEndDate, endDate: date as moment.Moment })
                        }
                        }
                        format={Config.momentEUDateFormat}
                    />

                    <div className={classes.spacing}>
                        <ToggleButtonGroup
                            value={timeTrackingType}
                            exclusive
                            onChange={handleChangeTimeTrackingType}
                            aria-label="text alignment"
                        >
                            <ToggleButton value={TimeTrackingTypeEnum.project} aria-label="left aligned">
                                {lang.get("folder")}
                            </ToggleButton>
                            <ToggleButton value={TimeTrackingTypeEnum.orderTicket} aria-label="left aligned">
                                {lang.get("orderTicket")}
                            </ToggleButton>
                            <ToggleButton value={TimeTrackingTypeEnum.client} aria-label="left aligned">
                                {lang.get("client")}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {renderByType()}
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <Autocomplete
                        fullWidth
                        id="subcontractors"
                        options={subcontractors}
                        value={selectedSubcontractor}
                        getOptionLabel={(subcontractor: any) => subcontractor.name}
                        renderInput={(params) => (
                            <TextField {...params} label={`${lang.get("subcontractor")}/${lang.get("company_employees")}`} />
                        )}
                        onChange={(event: ChangeEvent<{}>, value: any) => {
                            if (value === null) return;
                            setSelectedSubcontractor(value);
                        }}
                    />
                    <Autocomplete
                        fullWidth
                        id="employee"
                        options={employees}
                        value={selectedEmployee}
                        getOptionLabel={(employee: any) => employee.name}
                        renderInput={(params) => (
                            <TextField {...params} label={lang.get("subcontractors_employees")} />
                        )}
                        onChange={(event: ChangeEvent<{}>, value: any) => {
                            if (value === null) return;
                            setSelectedEmployee(value);
                        }}
                    />
                    <TextField
                        style={{ marginTop: 35 }}
                        fullWidth
                        label={`${lang.get("dailyBreak")}(${lang.get("minutes")})`}
                        name="pause"
                        value={pause}
                        type="number"
                        // onChange={(e) => setPause(parseInt(e.target.value))}
                        onChange={(e) => setPause(e.target.value)}

                    />

                    <TextField
                        fullWidth
                        label={lang.get("address")}
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </Grid>
            </Grid>
            <br />
            <Button variant="contained" style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}
                onClick={submitFilters}
            >
                {lang.get("filter")}
            </Button>
        </>
    );
}

export default TimetrackingReportForm;