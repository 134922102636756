import React, { Fragment, useState, useEffect } from "react";
import Lang from "../lang";
import DrawerLayout from "./DrawerLayout";
import MessageDialog from "../Components/MessageDialog";
import {
  ListItem,
  List,
  makeStyles,
  CircularProgress,
  Grid,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Tooltip,
} from "@material-ui/core";
import InvoiceHeader from "../DocumentTemplates/InvoiceHeader";
import Document from "../DocumentTemplates/Document";
import CachedDataSingleton from "../cachedDataSingleton";
import { Client, DocService } from "../Models/Models";
import Items from "../DocumentTemplates/Items";
import InvoiceFooter from "../DocumentTemplates/InvoiceFooter";
import clsx from "clsx";
import TextEditor from "../Components/TextEditor";
import {
  UrlEnum,
  get,
  DocTypes,
  isMobile,
  LocalUrlEnum,
  readCookie,
  UserRolesEnum,
} from "../Utils/Utils";
import FormSubmitButton from "../Forms/FormSubmitButton";
import Receipt from "../DocumentTemplates/Receipt";
import EstimateHeader from "../DocumentTemplates/EstimateHeader";
import EstimateFooter from "../DocumentTemplates/EstimateFooter";
import Totals from "../DocumentTemplates/Totals";
import AdditionalInfoWrapper from "../DocumentTemplates/EstimateAdditionalInfo";
import Config from "../Utils/Config";
import moment from "moment";
import LiabilityHeader from "../DocumentTemplates/Liabilitys/LiabilityHeader";
import LiabilityFooter from "../DocumentTemplates/Liabilitys/LiabilityFooter";
import NoticeHeader from "../DocumentTemplates/Notices/NoticeHeader";
import LiabilityItems from "../DocumentTemplates/Liabilitys/LiabilityItems";
import SimpleHeader from "../DocumentTemplates/SimpleHeader";
import CodeIcon from "@material-ui/icons/Code";
import PVReceptionHeader from "../DocumentTemplates/PVReceptionHeader";
import {
  adminDocItems,
  estimatesDocItems,
  invoicingDocItems,
} from "../Utils/Constants";

const colors = {
  blue: "#b7c3f7",
  green: "#9af5a2",
  orange: "#ffd778",
  purple: "#ffa8d8",
  gray: "#c9c9c9",
};

const docImages = {
  [DocTypes.invoice]: [
    "/images/model1.jpg",
    "/images/model2.jpg",
    "/images/model3.jpg",
    "/images/model4.jpg",
  ],
  [DocTypes.estimate]: [
    "/images/modelEstimate1.png",
    "/images/modelEstimate2.png",
    "/images/modelEstimate3.png",
    "/images/modelEstimate4.jpg",
  ],
  [DocTypes.orderTicket]: [
    "/images/modelEstimate1.png",
    "/images/modelEstimate2.png",
    "/images/modelEstimate3.png",
    "/images/modelEstimate4.jpg",
  ],
};

const useStyles = makeStyles((theme) => ({
  activeModel: {
    border: "2px solid " + theme.palette.header?.main,
  },
  listItemText: {
    fontSize: "10pt",
    textTransform: "capitalize",
  },
  swatch: {
    display: "inline-block",
    width: 25,
    height: 25,
    border: "1px solid #ccc",
    margin: 5,
    "& a": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  loading: {
    display: "block",
    margin: "0 auto",
    marginTop: 20,
  },
  color1: {
    background: colors.blue,
  },
  color2: {
    background: colors.green,
  },
  color3: {
    background: colors.orange,
  },
  color4: {
    background: colors.purple,
  },
  color5: {
    background: colors.gray,
  },
  styleImage: {
    maxWidth: 120,
    boxShadow: "2px 2px 4px #555",
    display: "block",
    margin: "0 auto",
  },
  li: { display: "block" },
  docWrapper: {
    boxShadow: "0px 0px 8px #999",
  },
  appBar: {
    left: 340,
    top: 64,
    padding: 0,
    transition: theme.transitions.create("left, width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "calc(100% - 300px)",
    [theme.breakpoints.down("md")]: {
      top: 56,
    },
  },
  appBarShift: {
    transition: theme.transitions.create("left, width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: 0,
    width: "100%",
  },
  designP: {
    marginLeft: 20,
  },
  formSubmitButton: {
    display: "block",
    width: "100%",
  },
  generalConditionsToolTipContainer: {
    width: 400,
    margin: "-20px auto 20px auto",
    border: "1px solid #ccc",
    borderRadius: 5,
  },
}));

const lang = Lang.getInstance();

const cachedData = CachedDataSingleton.getInstance();
let submitFunction = null;

export default function DocumentTemplates() {
  const [message, setMessage] = useState(null);
  const [appearance, setAppearance] = useState(0);
  const [color, setColor] = useState(colors.orange);
  const [docType, setDocType] = useState(DocTypes.invoice);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile());

  const [customTextTop, setCustomTextTop] = useState(null);
  const [customText, setCustomText] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const classes = useStyles();
  const textEditorRefTop = React.createRef();
  const textEditorRef = React.createRef();

  const company = cachedData.get("company");
  // const subscription = cachedData.get("subscription");
  const cookie = readCookie("userRoles");
  const userRoles = JSON.parse(cookie);

  /**
   *
   */
  useEffect(() => {
    get(UrlEnum.documentTemplates + "/" + docType).then((response) => {
      if (response.errors) {
        alert(response.errors);
        setLoading(false);
        return;
      }
      if (response) {
        if (response.customText) {
          setColor(response.color);
          setAppearance(response.appearance);
          setCustomText(JSON.parse(response.customText));
        } else {
          setCustomText(null);
        }
        if (response.customTextTop) {
          setCustomTextTop(JSON.parse(response.customTextTop));
        } else {
          setCustomTextTop(null);
        }
      }
      setLoading(false);
    });
  }, [docType]);

  /**
   *
   */
  function _changeAppearance(e, val) {
    e.preventDefault();
    setAppearance(val);
    return false;
  }

  /**
   *
   */
  function _changeColor(e, val) {
    e.preventDefault();
    const keys = Object.keys(colors);
    setColor(colors[keys[val]]);
    return false;
  }

  /**
   *
   */
  function submit(e) {
    e.preventDefault();
    if (submitFunction) {
      let data = {
        appearance: appearance,
        color: color,
        customText: textEditorRef.current
          ? JSON.stringify(textEditorRef.current.getContent())
          : "",
      };
      if (textEditorRefTop.current) {
        data.customTextTop = textEditorRefTop.current
          ? JSON.stringify(textEditorRefTop.current.getContent())
          : "";
      }
      submitFunction(data);
    }
    return false;
  }

  // Helper function to render image links
  const renderImageLink = (src, alt, appearanceIndex) => (
    <a onClick={(e) => _changeAppearance(e, appearanceIndex)} href="/">
      <img
        alt={alt}
        src={src}
        className={clsx(
          classes.styleImage,
          appearance === appearanceIndex && classes.activeModel
        )}
      />
    </a>
  );

  /**
   *
   */
  function _renderDocPreview() {
    // Get images for the current docType, defaulting to an empty array if no images are found
    const images = docImages[docType] || [];

    // If no images exist for the docType (like for receipts), return null
    if (images.length === 0) return null;

    // Render the Grid structure
    return (
      <Grid container>
        {images.slice(0, 2).map((src, index) => (
          <Grid item sm={6} key={`left-${index}`}>
            {renderImageLink(src, `model${index}`, index)}
            <br />
          </Grid>
        ))}
        {images.slice(2, 4).map((src, index) => (
          <Grid item sm={6} key={`right-${index}`}>
            {renderImageLink(src, `model${index + 2}`, index + 2)}
            <br />
          </Grid>
        ))}
      </Grid>
    );
  }

  /**
   *
   * @returns
   */
  function renderColorSwatches() {
    const colors = ["color1", "color2", "color3", "color4", "color5"];
    return (
      <div>
        {colors.map((color, index) => (
          <span key={color} className={`${classes[color]} ${classes.swatch}`}>
            <a href="/" onClick={(e) => _changeColor(e, index)}>
              &nbsp;
            </a>
          </span>
        ))}
      </div>
    );
  }

  /**
   *
   * @param {*} docItemsArray
   * @returns
   */
  function renderDocItems(docItemsArray) {
    return docItemsArray.map((item) => (
      <ListItem
        key={item.label}
        button
        onClick={() => setDocType(DocTypes?.[item.type])}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={lang.get(item.label)} />
      </ListItem>
    ));
  }

  /**
   * render links
   */
  function _renderOptions() {
    const invoicingRole = userRoles.find(
      (role) => role.role === UserRolesEnum.INVOICING
    );
    const estimatesRole = userRoles.find(
      (role) => role.role === UserRolesEnum.ESTIMATES
    );
    const adminRole = userRoles.find(
      (role) => role.role === UserRolesEnum.ADMIN
    );
    let role;
    if (adminRole) role = UserRolesEnum.ADMIN;
    else {
      if (estimatesRole) role = UserRolesEnum.ESTIMATES;
      else {
        if (invoicingRole) role = UserRolesEnum.INVOICING;
        else role = "";
      }
    }
    switch (role) {
      case UserRolesEnum.ADMIN:
        return (
          <div>
            <List>{renderDocItems(adminDocItems)}</List>
            <Divider />
            <p className={classes.designP}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>{renderColorSwatches()}</ListItem>
              <ListItem>
                <form onSubmit={submit} className={classes.formSubmitButton}>
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      case UserRolesEnum.INVOICING:
        return (
          <div>
            <List>{renderDocItems(invoicingDocItems)}</List>
            <Divider />
            <p className={classes.designP}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>{renderColorSwatches()}</ListItem>
              <ListItem>
                <form onSubmit={submit} className={classes.formSubmitButton}>
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      case UserRolesEnum.ESTIMATES:
        return (
          <div>
            <List>{renderDocItems(estimatesDocItems)}</List>

            <Divider />
            <p className={classes.designP}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>{renderColorSwatches()}</ListItem>
              <ListItem>
                <form onSubmit={submit} className={classes.formSubmitButton}>
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * render template links
   */
  function _renderDrawerChildren() {
    return <Fragment>{_renderOptions()}</Fragment>;
  }

  /**
   *
   */
  function _renderDocument() {
    switch (docType) {
      case DocTypes.invoice:
        return renderInvoiceTemplate();
      case DocTypes.liability:
        return renderLiabilityTemplate();
      case DocTypes.receipt:
        return renderReceipt();
      case DocTypes.estimate:
        return renderEstimateTemplate();
      case DocTypes.orderTicket:
        return renderOrderTicketTemplate();
      case DocTypes.notice:
        return renderNoticeTemplate();
      case DocTypes.generalConditions:
        return renderGeneralConditions();
      case DocTypes.vatAttestation:
        return renderVatAttestation();
      case DocTypes.companyContract:
        return renderContract();
      case DocTypes.pvTemporarReception:
        return renderPvTemporarReception();
      case DocTypes.pvDefinitiveReception:
        return renderPvDefinitiveReception();
      case DocTypes.subcontractorContract:
        return renderSubcontractorContract();
      default:
        return null;
    }
  }

  /**
   *
   * @returns
   */
  function renderReceipt() {
    return (
      <Document className={classes.docWrapper}>
        <Receipt number={0} date={new Date()} total={0} />
      </Document>
    );
  }

  /**
   * render invoice template
   */
  function renderInvoiceTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <InvoiceHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          docType={docType}
        />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
          color={color}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.invoice}
          initialState={customText}
          ref={textEditorRef}
        />

        <InvoiceFooter company={company} />
      </Document>
    );
  }

  /**companyContract
   *
   * @returns
   */
  function renderLiabilityTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <LiabilityHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
        />
        <br />
        <TextEditor
          docType={DocTypes.liability}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        {<LiabilityItems items={[new DocService()]} color={color} />}
        <br />
        <br />
        <TextEditor
          docType={DocTypes.liability}
          initialState={customText}
          ref={textEditorRef}
        />
        <br />
        <LiabilityFooter company={company} />
      </Document>
    );
  }

  /**
   *
   * @returns
   */
  function renderNoticeTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <NoticeHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
        />
        <br />
        <TextEditor
          docType={DocTypes.notice}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        {<Items items={[new DocService()]} color={color} />}
        <br />
        <br />
        <TextEditor
          docType={DocTypes.notice}
          initialState={customText}
          ref={textEditorRef}
        />
        <br />
        <LiabilityFooter company={company} />
      </Document>
    );
  }

  /**
   * render estimate template
   */
  function renderEstimateTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <EstimateHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          workSiteAddress={lang.get("siteAddress")}
          title={lang.get("title")}
          description={lang.get("description")}
          additionalInfo={lang.get("additionalInfo")}
          docType={docType}
        />
        <AdditionalInfoWrapper totalNoVat={0} />
        <br />
        <TextEditor
          docType={DocTypes.estimate}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          color={color}
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.estimate}
          initialState={customText}
          ref={textEditorRef}
        />

        <EstimateFooter company={company} />
      </Document>
    );
  }

  /**
   * render estimate template
   */
  function renderOrderTicketTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <EstimateHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          workSiteAddress={lang.get("siteAddress")}
          title={lang.get("title")}
          docType={docType}
        />
        <AdditionalInfoWrapper
          totalNoVat={0}
          startDate={moment().format(Config.momentEUDateFormat)}
          endDate={moment().format(Config.momentEUDateFormat)}
        />
        <br />
        <TextEditor
          docType={DocTypes.orderTicket}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          color={color}
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.orderTicket}
          initialState={customText}
          ref={textEditorRef}
        />

        <EstimateFooter company={company} />
      </Document>
    );
  }

  /**
   *
   */
  function copyTextToClipboard(link) {
    navigator.clipboard.writeText(link);
    setIsLinkCopied(true);
  }
  function renderGeneralConditions() {
    const embedLink = `${document.location.protocol}//${document.location.host}${LocalUrlEnum.doc}/${company.id}/${docType}`;
    return (
      <>
        <div className={classes.generalConditionsToolTipContainer}>
          <Tooltip
            title={isLinkCopied ? lang.get("linkCopied") : lang.get("copy")}
          >
            <Button onClick={() => copyTextToClipboard(embedLink)}>
              <CodeIcon /> {lang.get("embed")}
            </Button>
          </Tooltip>
          &nbsp;&nbsp;{" "}
          <a href={embedLink} target="_blank" rel="noreferrer">
            {embedLink}
          </a>
        </div>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.generalConditions}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   */
  function renderVatAttestation() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.vatAttestation}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   */
  function renderContract() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType="companyContract"
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   * @returns
   */
  function renderPvTemporarReception() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <PVReceptionHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.pvTemporarReception}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   * @returns
   */
  function renderPvDefinitiveReception() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <PVReceptionHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.pvDefinitiveReception}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   * @returns
   */
  function renderSubcontractorContract() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <TextEditor
            docType={DocTypes.subcontractorContract}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  return (
    <Fragment>
      <DrawerLayout
        drawerChildren={_renderDrawerChildren()}
        open={drawerOpen}
        handleDrawerClose={() => setDrawerOpen(!drawerOpen)}
        drawerWidth={300}
      >
        {loading ? (
          <CircularProgress className={classes.loading} color="secondary" />
        ) : (
          <Fragment>
            {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: !drawerOpen
                })}
              >
                <Toolbar variant="dense">
                  <Button
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    <MenuIcon />
                  </Button>
                </Toolbar>
              </AppBar> */}
            <br />
            {_renderDocument()}
          </Fragment>
        )}
      </DrawerLayout>
      <MessageDialog
        open={message ? true : false}
        text={message || ""}
        onResult={() => setMessage(null)}
      />
    </Fragment>
  );
}
