import React from "react";
import PropTypes from "prop-types";
import Lang from "../lang";
const lang = Lang.getInstance();

const styles = {
  wrapper: {
    background: "#eee",
    padding: 15,
    margin: 0,
    fontSize: 11,
    marginBottom: "-30px ! important" ,
  },
  link: {
    color: "#438ed9",
  },
};

export default function InvoiceFooter(props) {
  return (
    <div style={styles.wrapper} id="footer">
      {props.company.web ? (
        <span>
          <strong>{lang.get("webPage")}</strong>: {props.company.web}
        </span>
      ) : (
        ""
      )}
      {props.company.mobile ? (
        <span>
          <strong>&nbsp;&nbsp;{lang.get("mobile")}</strong>:{" "}
          {props.company.mobile}
        </span>
      ) : (
        ""
      )}
      <span style={{ marginLeft: 50 }}>
        {lang.get("generatedWith")}:{" "}
        <a href="https://cronoptimal.be" style={styles.link}>
          www.cronoptimal.be
        </a>
      </span>
      <span
        id="pageNum"
        style={{ float: "right", width: 25, display: "inline-block" }}
      ></span>
    </div>
  );
}

InvoiceFooter.propTypes = {
  company: PropTypes.object.isRequired,
};
