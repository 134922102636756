import Grid from "@material-ui/core/Grid";
import * as React from "react";
import {
  withStyles,
  Button,
  InputAdornment,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Lang from "../lang";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Redirect } from "react-router";
import { RemoveRedEye } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../Images/logo/cronotimal.svg";
import { LoginModel } from "../Models/Models";
import { Typography } from "@material-ui/core";
import { post, UrlEnum, checkMailConfirmed, isLoggedIn } from "../Utils/Utils";
import ReCAPTCHA from "react-google-recaptcha";
import theme from "../Theme/Theme";
let lang = Lang.getInstance();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: LoginModel,
      isLoggedIn: false,
      isCheckingLogin: true,
      submitted: false,
      wizard: false,
      passwordIsMasked: true,
      errors: null,
    };
  }

  componentDidMount() {
    const isLoggedInValue = isLoggedIn();
    this.setState({
      isLoggedIn: isLoggedInValue,
      isCheckingLogin: false, // Set to false once check is done
    });
    //check if user id logged in redirect to home
  }

  // componentDidMount() {
  //   // @ts-ignore
  //   if (this.props.isScriptLoaded) {
  //     if (!this.grecaptcha) {
  //       this.grecaptcha = window.grecaptcha;
  //       this.grecaptcha.ready(function () {
  //         this.grecaptcha
  //           .execute("6LeCctMUAAAAAEtemzRz002ys0im8ZpEebJLGwE8", {
  //             action: "homepage",
  //           })
  //           .then(function (token) {
  //             console.log(token);
  //           });
  //       });
  //     }
  //   }
  // }

  // componentWillUnmount() {
  //   // @ts-ignore
  //   this.grecaptcha = window.grecaptcha = null;
  // }

  /**
   *
   */

  handleChange = (event) => {
    const loginModel = this.state.model;
    loginModel[event.target.name] = event.target.value;
    this.setState({ loginModel });
  };
  onChange = (value, event) => {
    const loginModel = this.state.model;
    loginModel["g_recaptcha_response"] = value;
    this.setState({ loginModel });
  };

  /**
   * Server validation
   */
  handleSubmit = async () => {
    const confirmed = await checkMailConfirmed(this.state.model.username);
    if (!confirmed) {
      this.setState({ errors: lang.get("emailNotConfirmed") });
      return;
    }
    post(UrlEnum.login, this.state.model, { credentials: "include" }).then(
      function (data) {
        if (data.errors) {
          this.setState({ errors: lang.get("wrongUserOrPassword") });
        } else {
          let expires = new Date(
            Date.now() + 3600 * 1000 * 24 * 15
          ).toUTCString();

          document.cookie = "accept_notification = false;";
          // set cookie user
          document.cookie = `username=${data.userName}; expires=${expires}; path=/`;
          // set cookie user roles
          document.cookie = `userRoles=${JSON.stringify(
            data.user_roles
          )}; expires=${expires}; path=/`;
          document.cookie = `userData=${JSON.stringify({
            email: data.email,
            phone: data.mobile,
            fk_companyid: data.fk_companyid,
          })}; expires=${expires}; path=/`;

          if (data.fk_companyid && parseFloat(data.fk_companyid) > 0) {
            this.setState({ submitted: true });
          } else if (data && data !== 0 && Number.isInteger(data)) {
            window.location.href = `/getPackage/${data}`;
          } else {
            window.location.href = "/wizard";
            //this.setState({wizard: true});
          }
        }
      }.bind(this)
    );
  };

  /**
   *
   */
  handleOnClick = () => {
    this.setState({ redirect: true });
  };

  /**
   * shows or hides the password
   */
  togglePasswordMask = () => {
    this.setState((prevState) => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  /**
   * render the view
   */
  render() {
    const classes = this.props.classes;
    const {
      submitted,
      passwordIsMasked,
      isLoggedIn,
      isCheckingLogin,
    } = this.state;
    if (isCheckingLogin) {
      // Optionally, display a loading message or spinner while checking
      return (
        <div
          style={{
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              marginBottom: 20,
              padding: 10,
              marginRight: 20,
            }}
          />
          <FormHelperText>{lang.get("loading")}</FormHelperText>
        </div>
      );
    }

    if (isLoggedIn) {
      // Redirect to home page if the user is already logged in
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        {submitted ? <Redirect push to="/" /> : ""}
        {/* {wizard ? <Redirect push to="/wizard" /> : ""} */}
        <CssBaseline />
        <div className={classes.root}>
          <div className={classes.container}>
            <Paper
              className={classes.paper}
              style={{ paddingLeft: "50px", paddingRight: "50px" }}
            >
              <div className={classes.grid}>
                <div style={{ textAlign: "center" }} className={classes.logo}>
                  <img style={{ width: "120px" }} src={logo} alt="logo" />
                  <h2 className={classes.container}>{lang.get("login")}</h2>
                </div>
              </div>
              <ValidatorForm
                onSubmit={this.handleSubmit}
                instantValidate={false}
              >
                <TextValidator
                  fullWidth
                  required={true}
                  id="username"
                  label={lang.get("email")}
                  onChange={this.handleChange}
                  name="username"
                  value={this.state.model.username}
                  validators={["required"]}
                  errorMessages={[lang.get("fieldRequired")]}
                />
                <TextValidator
                  fullWidth
                  required={true}
                  id="password"
                  label={lang.get("password")}
                  onChange={this.handleChange}
                  name="password"
                  type={passwordIsMasked ? "password" : "text"}
                  validators={["required"]}
                  errorMessages={[lang.get("fieldRequired")]}
                  value={this.state.model.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RemoveRedEye
                          className={classes.eye}
                          onClick={this.togglePasswordMask}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ margin: "10px 5px", textAlign: "right" }}>
                  <a
                    href="/forgotPassword"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {lang.get("forgotPassword")}
                  </a>
                </div>
                <div style={{ padding: "10px 0px" }}>
                  <ReCAPTCHA
                    sitekey="6LdlkB8UAAAAADQuUPeyRlUkSf1byrZq5k0FnAb3"
                    onChange={this.onChange}
                  />
                </div>
                <Typography color={"error"}>{this.state.errors}</Typography>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  className={classes.grid}
                >
                  <Grid className={classes.grid}>
                    <Button
                      style={{
                        background: theme.palette.header?.main,
                        color: theme.palette.textColorSecondary?.main,
                      }}
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      disabled={submitted}
                    >
                      {lang.get("login")}
                    </Button>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Link
                      to="/register"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        // variant="contained"
                        // style={{background:theme.palette.header?.main,color:theme.palette.textColorSecondary?.main}}
                        className={classes.button}
                      >
                        {lang.get("register")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 *
 * Page style
 */
const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    // marginTop:"30px",
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
  },
  paper: theme.mixins.gutters({
    paddingTop: 26,
    paddingBottom: 26,
    marginTop: "10%",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  }),
  grid: theme.mixins.gutters({
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
    },
  }),
  eye: {
    cursor: "pointer",
  },
  field: {
    marginTop: 1,
    paddingTop: 16,
    paddingBottom: 16,
    logo: {
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
        justifyContent: "center",
      },
    },
  },
  button: theme.mixins.gutters({
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  }),
});

export default withStyles(styles, { withTheme: true })(LoginPage);
