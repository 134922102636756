import { ResponsiveBar } from "@nivo/bar";
import Lang from "../lang";

export default function BudgetEstimateReports(props: any) {
  const lang = Lang.getInstance();

  const transformData = (estimatedData: any[], actualData: any[]): any[] => {
    return estimatedData?.map((estimate: any) => {
      const actual = actualData?.find((item) => item.month === estimate.month);
      const monthData: any = { month: `${props.months[estimate.month]}` };
      // Calculate the estimated total cost (sum of all expenses)
      const estimatedTotal = estimate?.expenses
        ?.reduce(
          (total: number, expense: any) =>
            total + (expense.value ? parseFloat(expense.value) : 0),
          0
        )
        ?.toFixed(2);

      // Add the estimated total cost to the data
      monthData[lang.get("estimatedCosts")] = estimatedTotal;

      // Add each expense to the graph data
      estimate.expenses.forEach((expense: any) => {
        monthData[expense.expense] = expense.value;
      });

      // Add the actual cost
      if (actual) {
        monthData[lang.get("actualCosts")] = actual.total_cost
          ? parseFloat(actual.total_cost).toFixed(2)
          : 0;
      } else {
        monthData.actualCost = 0;
      }

      return monthData;
    });
  };

  return props?.estimateData?.length > 0 || props?.actualData?.length > 0 ? (
    <div
      style={{
        height: "300px",
      }}
    >
      <ResponsiveBar
        data={transformData(props.estimateData, props.actualData)}
        keys={[lang.get("estimatedCosts"), lang.get("actualCosts")]}
        indexBy="month"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: lang.get("months"),
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: lang.get("costs"),
          legendPosition: "middle",
          legendOffset: -50,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        groupMode="grouped"
        role="application"
      />
    </div>
  ) : null;
}
